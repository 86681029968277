import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Application_cum } from '../user1'
import { Education } from '../user1'
import { Observable } from 'rxjs'
import { MatSelectModule } from '@angular/material/select'
import { GlobalVariable } from 'src/app/Globals'
import { ElementRef ,ViewChild} from '@angular/core';  
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';  

import {
  HttpClient,
  HttpHeaders,
  HttpRequest,
  HttpEventType,
} from '@angular/common/http'
import { DatePipe } from '@angular/common';
import {formatDate } from '@angular/common';

@Component({
  selector: 'app-payment-receipt',
  templateUrl: './payment-receipt.component.html',
  styleUrls: ['./payment-receipt.component.css']
})
export class PaymentReceiptComponent implements OnInit {
  application_cum: Application_cum;
  Payments = ["Cash", "DD", "Cheque"];
  amountInWords: string;
  CourseFees: string;
  getName: any;
  getCName: any; 
  getFees: any; 
  getMobile: any;
  getEMail: any;
  getCYear: any;
  getTransId: any;
  myDate = new Date();
  today= new Date();
  jstoday = '';
  constructor(
    private http: HttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.application_cum = new Application_cum();
    this.jstoday = formatDate(this.today, 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0530');
    // this.router.params.subscribe((params) => {
    //   // this.application_cum.Id = params.id;
    //   // alert(params.id);
    //   //   this.service.GetReceiptById(this.application_cum.Id).subscribe((result) => {
    //   //     this.application_cum = result;
    //   //     this.convertNumberToWords(this.application_cum.Total);
    //   //   });
    // });
    
  }

  ngOnInit(){

    this.activatedRoute.queryParams.subscribe(params => {
      this.getName = params['FullName'];
      this.getCName = params['Course_Name'];
      this.getFees = params['Fees'];
      this.getMobile = params['Mobile']
      this.getEMail = params['EMail'];
      this.getCYear = params['Class'];
      this.getTransId = params['TransId'];
      // this.getYear = params['Year'];
      // this.getMinbudget = params['MiniBudget'];
      // this.getMaxbudget = params['MaxiBudget'];
      console.log(this.getName,this.getCName,this.getFees,this.getMobile,this.getEMail,this.getCYear,this.getTransId);
     // alert(this.getCName+''+this.getFees)
     
      // CourseFees = this.getFees;

    });



  }
  convertNumberToWords(getFees) {
    var words = new Array();
    words[0] = "";
    words[1] = "One";
    words[2] = "Two";
    words[3] = "Three";
    words[4] = "Four";
    words[5] = "Five";
    words[6] = "Six";
    words[7] = "Seven";
    words[8] = "Eight";
    words[9] = "Nine";
    words[10] = "Ten";
    words[11] = "Eleven";
    words[12] = "Twelve";
    words[13] = "Thirteen";
    words[14] = "Fourteen";
    words[15] = "Fifteen";
    words[16] = "Sixteen";
    words[17] = "Seventeen";
    words[18] = "Eighteen";
    words[19] = "Nineteen";
    words[20] = "Twenty";
    words[30] = "Thirty";
    words[40] = "Forty";
    words[50] = "Fifty";
    words[60] = "Sixty";
    words[70] = "Seventy";
    words[80] = "Eighty";
    words[90] = "Ninety";
    this.getFees = this.getFees.toString();
    var atemp = this.getFees.split(".");
    var number = atemp[0].split(",").join("");
    var n_length = number.length;
    var words_string = "";
    if (n_length <= 9) {
      var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
      var received_n_array = new Array();
      for (var i = 0; i < n_length; i++) {
        received_n_array[i] = number.substr(i, 1);
      }
      for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
        n_array[i] = received_n_array[j];
      }
      for (var i = 0, j = 1; i < 9; i++, j++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          if (n_array[i] == 1) {
            n_array[j] = 10 + parseInt(n_array[j].toString());
            n_array[i] = 0;
          }
        }
      }
      let value: number;
      for (var i = 0; i < 9; i++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          value = n_array[i] * 10;
        } else {
          value = n_array[i];
        }
        if (value != 0) {
          words_string += words[value] + " ";
        }
        if (
          (i == 1 && value != 0) ||
          (i == 0 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Crores ";
        }
        if (
          (i == 3 && value != 0) ||
          (i == 2 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Lakhs ";
        }
        if (
          (i == 5 && value != 0) ||
          (i == 4 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Thousand ";
        }
        if (
          i == 6 &&
          value != 0 &&
          n_array[i + 1] != 0 &&
          n_array[i + 2] != 0
        ) {
          words_string += "Hundred and ";
        } else if (i == 6 && value != 0) {
          words_string += "Hundred ";
        }
      }
      words_string = words_string.split("  ").join(" ");
    }
    this.amountInWords = words_string;
    return words_string;
  }
  
  pdfpage(){
    
    var data = document.getElementById('contentToConvert');  
    html2canvas(data).then(canvas => {  
      // Few necessary setting options  
      // var imgWidth = 208;   
      // var pageHeight = 9900;  
      // var imgHeight = canvas.height * imgWidth / canvas.width;  
      var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;  
      const contentDataURL = canvas.toDataURL('image/png')  
      let pdf = new jspdf.jsPDF('p', 'mm', 'a4'); // A4 size page of PDF  
      var position = 0;  
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)  
      pdf.save('PaymentReceipt.pdf'); // Generated PDF   
    });  
  }  
  
   printPage() {

  //   var printButton = document.getElementById("btnprint");
  //   var data = document.getElementById("footer");
  //   //Set the print button visibility to 'hidden' 
  //   printButton.style.visibility = 'hidden';
  //   //Print the page content
  //   window.print()
  //   //Set the print button to 'visible' again 
  //   //[Delete this line if you want it to stay hidden after printing]
  //   printButton.style.visibility = 'visible';
  //   data.style.visibility = 'visible';

  //   // window.print();

  var printContents = document.getElementById("print").innerHTML;
  var originalContents = document.body.innerHTML;

  document.body.innerHTML = printContents;

  window.print();

  document.body.innerHTML = originalContents;


   }
  }
