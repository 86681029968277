import { Component, OnInit } from '@angular/core';

export interface Element {
  duration: string;
  eligibility: string;
  name: string;
  no: number;
}

const ELEMENT_DATA: Element[] = [
  {no : 1, name : 'Certificate Course in Yoga Teacher(CCTY)', eligibility  : '10th Pass', duration : '6 Months' },
  {no : 2, name : 'Diploma in Naturopathy and Yogic Science(DNYS)', eligibility  : '12th Pass', duration : '2 Years' },
  // {no : 3, name : 'Bachelor in Naturopathy and Yogic Science(BNYS)', eligibility  : '12th Pass', duration : '5 Years' },
];

@Component({
  selector: 'app-naturopathy-info',
  templateUrl: './naturopathy-info.component.html',
  styleUrls: ['./naturopathy-info.component.css']
})
export class NaturopathyInfoComponent {

  displayedColumns: string[] = ['no', 'name', 'eligibility', 'duration'];
  dataSource = ELEMENT_DATA;

}
