<!-- <section class="top-section">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <h4 style="font-size: 18px;">Single Course Page</h4>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <h6 style="float: right;">Home&nbsp;/Courses&nbsp;/Single Course Page</h6>
            </div>
        </div>
    </div>
</section> -->

<section class="middle-section">
    <div class="container">
        <div class="row row-bottom">
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12"  style="margin-bottom: 30px;">
                <div class="course-image">
                    <img src="./assets/images/yoga.jpg" width="294" height="206" style="width:100%">
                </div>
                <div class="course-data">
                    <p class="category">Name : Department of Naturopathy and Yogic Science</p>
                    <hr>
                    <p>Duration : 3 Year Program</p>
                    <hr>
                    <p>Eligibility : 12th Pass</p>
                </div>
                <div class="course-button">
                    <a href="#" class="btn btn-primary btn-block" style="font-size: 14px;">Take This Course</a>
                </div>
                <br>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                <div class="container-fluid">
                    <h4 style="font-size: 20px;">Department of Naturopathy and Yogic Science</h4>
                    <hr class="title-hr">
                    <h5 style="text-align: justify;">Presently, all modern health care facilities have recognized the importance of Yoga and Naturopathy. The Department of naturopathy and yogic science start with the mission to propagate a healthy and active lifestyle of human personality.
                        The department has been developed in accordance with the kv board. The department involves modern infrastructure, comprising of a well-equipped naturopathy health centre with the whole range of Ayurveda, Panchakarma, Massage, Steam-bath,mud-
                        bath, hip-bath, tub-bath, spinal-bath, enema along with the facilities of natural therapy, diet therapy, physiotherapy, yogic therapy, beauty treatment, and lifestyle counseling. The department offers certificate, diploma, programs.
                        These programs are specially designed based on scientifically proven methods.

                    </h5>
                    <hr>
                    <h4 style="font-size: 20px;">Objective</h4>
                    <h5>To teach asanas, or body positions associated with yoga, and pranayama.</h5>
                    <h5>To use natural elements like air, water, light, heat and magnetic force for preventing and combating various disorders, basically aimed at detoxifying the body and enhancing its vitality.</h5>
                    <h5>To train and nurture students in order to groom them as professional therapists with a proper scientific background.</h5>
                    <hr>

                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="box-shadow: 2px 3px 8px #bebdbd;">

                        <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->

                        <!-- No Column -->
                        <ng-container matColumnDef="no">
                            <th mat-header-cell *matHeaderCellDef> No. </th>
                            <td mat-cell *matCellDef="let element"> {{element.no}} </td>
                        </ng-container>

                        <!-- Name Column -->
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef>&nbsp; Name </th>
                            <td mat-cell *matCellDef="let element"> {{element.name}} &nbsp;</td>
                        </ng-container>

                        <ng-container matColumnDef="eligibility">
                            <th mat-header-cell *matHeaderCellDef>&nbsp; Eligibility </th>
                            <td mat-cell *matCellDef="let element"> {{element.eligibility}} &nbsp;</td>
                        </ng-container>

                        <ng-container matColumnDef="duration">
                            <th mat-header-cell *matHeaderCellDef>&nbsp; Duration </th>
                            <td mat-cell *matCellDef="let element"> {{element.duration}} &nbsp;</td>
                        </ng-container>

                        <!-- <ng-container matColumnDef="fees">
                            <th mat-header-cell *matHeaderCellDef>&nbsp; Fees </th>
                            <td mat-cell *matCellDef="let element"> {{element.fees}} </td>
                        </ng-container> -->

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>
