import { Component, OnInit } from '@angular/core';

export interface Element {
  duration: string;
  eligibility: string;
  name: string;
  no: number;
}
const ELEMENT_DATA: Element[] = [
  {no : 1, name : 'Diploma In General Nursing Midwifery Assistant', eligibility  : '10th Pass', duration : '1 Year' },
  {no : 2, name : 'Diploma In Medical Lab Technician', eligibility  : '12th Pass', duration : '1 & 2 Years' },
  {no : 3, name : 'Diploma In Operation Theatre Technician', eligibility  : '12th Pass', duration : '1 Year' },
  {no : 4, name : 'B Voc in Patient care Management', eligibility  : '12th Pass', duration : '3 Years' },
  {no : 5, name : 'B Voc in Medical Lab Technology', eligibility  : '12th Pass', duration : '3 Years' },
  {no : 6, name : 'Diploma In Sanitary Inspector', eligibility  : '12th Pass(Science)', duration : '2 Years' },
  {no : 7, name : 'Diploma In Hospital Management', eligibility  : '12th Pass', duration : '2 Years' },
];

@Component({
  selector: 'app-health-science',
  templateUrl: './health-science.component.html',
  styleUrls: ['./health-science.component.css']
})
export class HealthScienceComponent implements OnInit {


  displayedColumns: string[] = ['no', 'name', 'eligibility', 'duration'];
  dataSource = ELEMENT_DATA;

  constructor() { }

  ngOnInit() {
  }

}
