<div class="container">
    <div class="header">
        <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-4"></div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12" style="text-align: left;">
                <h2 style="color: #336699;">Important Instruction : </h2>
                <hr class="title-hr">
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4"></div>
        </div>
    </div>
    <!-- <div class="panel">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <ul>
                    <li>Best Infrastructure</li>
                    <li>Well Maintained Library</li>
                    <li>Computer Lab with 24/7 Wifi</li>
                    <li>Full Equipped Workshop & Lab</li>
                </ul>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <ul>
                    <li>High Qualified Teachers & Trainers</li>
                    <li>Military Base Training </li>
                    <li>CCTV Surveillance</li>
                    <li>In Campus Hostel with Mess Facility</li>
                </ul>
            </div>
        </div>
    </div>
    <br> -->
    <div class="panel">
        <div class="row text-center">
            <div class="panel">
               
                <ul>
                    <li>Candidate  should be ready in front of their desktop / laptop 15 minutes before the exam time.</li>
                    <li>The test is of 1 hours duration and Test booklet  contains 50 questions. Each question carries 1 marks. For each correct response, the candidate will get 1 marks. For each incorrect response, one mark will be deducted from the total scores. The maximum marks are 50.</li>
                    <li>Candidate should be seated in front of the desktop or Laptop throughout the entire duration of the test.</li>
                    <li>Candidate is not allowed to use a calculator during the test. A plain paper and pencil are allowed for rough work.</li>
                    <li>No bio breaks are permitted during the test.</li>
                    <li>The candidate may not use his or her textbook, course notes, or receive help from a proctor or any other outside source.</li>
                    <li>Students must not stop the session and then return to it. This is especially important in the online environment where the system will "time-out" and not allow the student or you to reenter the exam site.</li>
                     <li>A mobile phone with the primary number that was fed at the time of registration should be lying next to the candidate. It will be used for communicating with the candidate if needed. No other electronic device will be permitted.</li>
                </ul>
       <div class="col-12 form-group mg-t-8">
                        <!-- <button type="submit" class="btn-fill-lg btn-gradient-yellow btn-hover-bluedark" [class.spinner]="loading" [disabled]="loading" (click)="save()">Submit</button> -->
                        <!-- <button type="submit" class="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">Submit</button> -->
                        <a routerLink="/Online-Exam">
                        <button class="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"><i class="fa"></i>Registration for Exam</button>
                    </a>
                    </div>
            </div>
        </div>
    </div>
</div>