import { Component, OnInit } from '@angular/core';
import {MatTabsModule} from '@angular/material/tabs';

declare let $: any;

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css'],

})
export class AboutUsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    var owl = $('.owl-banner');
    owl.owlCarousel({
        loop:true,
        nav:false,
        autoplay:true,
        autoplayTimeout:5000,
        autoplayHoverPause:true,

        video:true,
        // margin:10,
        // stagePadding: 50,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:1
            },
            960:{
                items:1
            },
            1200:{
                items:1
            }
        }
    });
    owl.on('mousewheel', '.owl-stage', function (e) {
      if (e.deltaY<0) {
          owl.trigger('next.owl');
      } else {
          owl.trigger('prev.owl');
      }
      // owl.scrollLeft -= (deltaX * 30);
      e.preventDefault();
  });
  }
}
