<!-- Sidebar Area End Here -->
<div class="dashboard-content-one">
    <!-- Breadcubs Area Start Here -->
    <div class="breadcrumbs-area">
        <h2 style="text-align: center;">Online Exam</h2>
    </div>
    <div class="card height-auto">
        <div class="card-body">
            <!-- <div class="well col-md-12"> -->

            <div class="container">
                <div class="col-md-12" *ngIf="start">
                    <h5>{{ currentQuestionSet.question }}</h5>
                    <div *ngFor="let o of currentQuestionSet.option">
                        <input type="radio" id="s-option" name="{{ currentQuestionSet.id }}" value="{{ o.optionid }}" [(ngModel)]="currentQuestionSet.selected" />{{ o.name }}
                    </div>
                </div>

                <!-- <div class="col-md-12" *ngIf="start">
                    <h5>{{currentIndex-1}}/{{questions.length}}</h5>
                    <input *ngIf="(currentIndex-1) != questions.length" type="button" class="btn" value="Prev" (click)="prev()" />
                </div> -->
                <br />
                <div class="col-md-12" *ngIf="start" style="margin-top: 22px; text-align:center;">
                    <h5 class="number">{{ currentIndex + 1 }}/{{ questions.length }}</h5>
                    <!-- <input *ngIf="(currentIndex+1) != questions.length" type="button" class="btn" value="Next" (click)="next()" /> -->
                    <button type="button" *ngIf="currentIndex - 1 != questions.length" class="btn-fill-lg bg-blue-dark btn-hover-yellow btn1 buttons" value="Prev" (click)="prev()">
            Prev
          </button>
                    <button type="button" *ngIf="currentIndex + 1 != questions.length" class="btn-fill-lg bg-blue-dark btn-hover-yellow btn1 buttons" value="Next" (click)="next()">
            Next
          </button>
                </div>
                <div *ngIf="gameover" style="text-align: center;">
                    <h3>Result: {{ answers }}/{{ questions.length }}</h3>
                    <h2 class="thxtext">Thank you for participating in online Exam!</h2>
                </div>
                <br />
                <div class="col-md-12" style="text-align: center;">
                    <!-- <input *ngIf="!start" type="button" class="btn" value="{{buttonname}}" (click)="startQuiz()" /> -->
                    <button *ngIf="flag" class="btn-fill-lg btn-gradient-yellow btn-hover-bluedark" (click)="startQuiz()">
            <i class="fa"></i>Start Exam
          </button>
                </div>
                <!-- <button class="btn-fill-lg btn-gradient-yellow btn-hover-bluedark" [disabled]="isLoading" (click)="onSubmit()"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, '': !isLoading}"></i> {{button}}</button>
                <button type="reset" class="btn-fill-lg bg-blue-dark btn-hover-yellow">Reset</button> -->

                <div class="col-md-12" *ngIf="start" style="text-align:center;">
                    <!-- <input type="button" class="btn" value="Submit" (click)="submit()" /> -->
                    <button *ngIf="currentIndex + 1 == questions.length" class="btn-fill-lg btn-gradient-yellow btn-hover-bluedark btnsubmit" (click)="submit()">
            <i class="fa"></i>Submit
          </button>

                </div>

            </div>
        </div>
    </div>
</div>