import { Component, OnInit } from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import { Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  subscription: Subscription;
  statusText: any;
  nameFormControl = new FormControl('', [
    Validators.required
  ]);

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  myservice: any;
  constructor() { }

  ngOnInit() {
    interval(1000)
    .pipe(takeWhile(() => !stop))
    .subscribe(() => {
      // place you code here
      alert(21);
    });




    
    // this.subscription = timer(0, 10000).pipe(
    //   switchMap(() => this.myservice.checkdata())
    // ).subscribe(result => this.statusText = result);
    
  }
//   ngOnDestroy() {
//     this.subscription.unsubscribe();

// }

}
