<!-- Sidebar Area End Here -->
<div class="dashboard-content-one">
  <!-- Breadcubs Area Start Here -->
  <div class="breadcrumbs-area">
      <h3>Admission form</h3>
      <!-- <ul>
          <li>
              <a href="index.html">Home</a>
          </li>
          <li>Student Admit Form</li>
      </ul> -->
  </div>
  <!-- <div class="loader" id="loader"></div> -->
  
  <!-- Breadcubs Area End Here -->
  <!-- Admit Form Area Start Here -->
  <div class="card height-auto">
      <div class="card-body">
          <form class="new-added-form" #userForm="ngForm" name="form" (ngSubmit)="userForm.form.valid && onSubmit()" novalidate>
              <!-- {{userForm.value | json}} -->
              <div class="row">
                  <div class="col-xl-4 col-lg-6 col-12 form-group">
                      <label> Aadhar Number *</label>
                      <input type="number" placeholder="" class="form-control" name="Aadhar" [(ngModel)]=application_cum.Aadhar #Aadhar="ngModel" [ngClass]="{ 'is-invalid': userForm.submitted && Aadhar.invalid }" required minlength="12" onKeyPress="if(this.value.length==12) return false;">
                      <div *ngIf="userForm.submitted && Aadhar.invalid" class="invalid-feedback">
                          <div *ngIf="Aadhar.errors.required " class="alert alert-danger"> Aadhar Number is required</div>
                          <div *ngIf="Aadhar.errors.minlength " class="alert alert-danger"> Aadhar Number must be valid</div>
                      </div>
                  </div>

                  <div class="col-xl-4 col-lg-6 col-12 form-group">
                      <label>Type of Course Name *</label>
                      <select class=" form-control" (change)="selectChangeHandler($event)" name="CourseName" [(ngModel)]=application_cum.CourseName #CourseName="ngModel" [ngClass]="{'invalid-data': CourseName.invalid && userForm.submitted, 'valid-data': CourseName.valid && userForm.submitted}" required>
                           <!-- <option value="">Please Select Course Name *</option> -->
              <option *ngFor="let CourseName of CourseList"> {{CourseName.Name}} </option>
              </select>
                      <div *ngIf="CourseName.invalid && userForm.submitted">
                          <div class="alert alert-danger">
                              Please Select Your Course Name.
                          </div>
                      </div>
                  </div>
                  <div class="col-xl-4 col-lg-6 col-12 form-group">
                      <label> Fees *</label>
                      <input type="number" placeholder="" class="form-control" name="Fees" [(ngModel)]=application_cum.Fees #Fees="ngModel" [ngClass]="{ 'is-invalid': userForm.submitted && Fees.invalid }" required minlength="3">
                      <div *ngIf="userForm.submitted && Fees.invalid" class="invalid-feedback">
                          <div *ngIf="Fees.errors.required " class="alert alert-danger"> Fees is required</div>
                          <div *ngIf="Fees.errors.minlength " class="alert alert-danger"> Fees must be at least 3 characters long</div>
                      </div>
                  </div>

                    <div class="col-xl-4 col-lg-6 col-12 form-group">
                                    <label>Class</label>
                              <select class=" form-control" name="Class" [(ngModel)]=application_cum.Class #Class="ngModel" [ngClass]="{'invalid-data': Class.invalid && userForm.submitted, 'valid-data': Class.valid && userForm.submitted}" required>
                            <!-- <option>- Select -</option> -->
                            <option value="">Select</option>
                            <option *ngFor="let Class of classes"> {{Class}} </option>
                            </select>
                            <div *ngIf="Class.invalid && userForm.submitted">
                                <div class="alert alert-danger">
                                    Please Select Your Class.
                                </div>
                            </div>
                                </div>

                  <div class="col-xl-4 col-lg-6 col-12 form-group">
                      <label> Full Name *</label>
                      <input type="text" placeholder="" class="form-control" name="Name" [(ngModel)]=application_cum.Name #Name="ngModel" [ngClass]="{ 'is-invalid': userForm.submitted && Name.invalid }" required minlength="1" onkeypress="return (event.charCode > 64 && 
                      event.charCode < 91 ) || (event.charCode > 96 && event.charCode < 123) || (event.charCode == 32)">
                      <div *ngIf="userForm.submitted && Name.invalid" class="invalid-feedback">
                          <div *ngIf="Name.errors.required " class="alert alert-danger"> Name is required</div>
                          <div *ngIf="Name.errors.minlength " class="alert alert-danger"> Name must be at least 3 characters long</div>
                      </div>
                  </div>

                  <div class="col-xl-4 col-lg-6 col-12 form-group">
                      <label>Father Name *</label>
                      <input type="text" placeholder="" class="form-control" name="FName" [(ngModel)]=application_cum.FatherName #FName="ngModel" [ngClass]="{ 'is-invalid': userForm.submitted && FName.invalid }" required minlength="1" onkeypress="return (event.charCode > 64 && 
                      event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)">
                      <div *ngIf="userForm.submitted && FName.invalid" class="invalid-feedback">
                          <div *ngIf="FName.errors.required " class="alert alert-danger">Father Name is required</div>
                          <div *ngIf="FName.errors.minlength " class="alert alert-danger">Father Name must be at least 3 characters long</div>
                      </div>
                  </div>

                  <div class="col-xl-4 col-lg-6 col-12 form-group">
                      <label>Mother Name *</label>
                      <input type="text" placeholder="" class="form-control" name="MName" [(ngModel)]=application_cum.MotherName #MName="ngModel" [ngClass]="{ 'is-invalid': userForm.submitted && MName.invalid }" required minlength="1" onkeypress="return (event.charCode > 64 && 
                      event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)">
                      <div *ngIf="userForm.submitted && MName.invalid" class="invalid-feedback">
                          <div *ngIf="MName.errors.required " class="alert alert-danger">Mother Name is required</div>
                          <div *ngIf="MName.errors.minlength " class="alert alert-danger">Mother Name must be at least 3 characters long</div>
                      </div>
                  </div>

                  <div class="col-xl-4 col-lg-6 col-12 form-group">
                      <label>Full Address *</label>
                      <input type="text" placeholder="" class="form-control" name="Address" [(ngModel)]=application_cum.Address #Address="ngModel" [ngClass]="{ 'is-invalid': userForm.submitted && Address.invalid }" required minlength="1">
                      <div *ngIf="userForm.submitted && Address.invalid" class="invalid-feedback">
                          <div *ngIf="Address.errors.required " class="alert alert-danger">Address is required</div>
                          <div *ngIf="Address.errors.minlength " class="alert alert-danger">Address must be at least 3 characters long</div>
                      </div>
                  </div>

                  <div class="col-xl-4 col-lg-6 col-12 form-group">
                    <label>State *</label>
                    <select class=" form-control" name="state"  [(ngModel)]=application_cum.State #State="ngModel" [ngClass]="{'invalid-data': State.invalid && userForm.submitted, 'valid-data': State.valid && userForm.submitted}" required>
                       <option value="">Please Select State *</option>
                      <option *ngFor="let state of StateList"> {{state}} </option>
                      </select>
                    <div *ngIf="State.invalid && userForm.submitted">
                        <div class="alert alert-danger">
                            Please Select Your State.
                        </div>
                    </div>
                </div>

                <!-- <div class="col-xl-4 col-lg-6 col-12 form-group">
                    <label>District *</label>
                    <select class=" form-control" name="District"  [(ngModel)]=application_cum.District #District="ngModel" [ngClass]="{'invalid-data': District.invalid && userForm.submitted, 'valid-data': District.valid && userForm.submitted}" required>
                     <option value="">Please Select District *</option>
                    <option *ngFor="let district of DistrictList"> {{district}} </option>
                    </select>
                    <div *ngIf="District.invalid && userForm.submitted">
                        <div class="alert alert-danger">
                            Please Select Your District.
                        </div>
                    </div>
                </div> -->

                <div class="col-xl-4 col-lg-6 col-12 form-group">
                    <label>District *</label>
                    <select class=" form-control" name="District"  [(ngModel)]=application_cum.District #District="ngModel" [ngClass]="{'invalid-data': District.invalid && userForm.submitted, 'valid-data': District.valid && userForm.submitted}" required>
                     <option value="">Please Select District *</option>
                    <option *ngFor="let city of CityList"> {{city}} </option>
                    </select>
                    <div *ngIf="District.invalid && userForm.submitted">
                        <div class="alert alert-danger">
                            Please Select Your District.
                        </div>
                    </div>
                </div>

                <div class="col-xl-4 col-lg-6 col-12 form-group">
                    <label>City *</label>
                    <select class=" form-control" name="City"  [(ngModel)]=application_cum.City #City="ngModel" [ngClass]="{'invalid-data': City.invalid && userForm.submitted, 'valid-data': City.valid && userForm.submitted}" required>
                   <option value="">Please Select City *</option>
                  <option *ngFor="let city of CityList"> {{city}} </option>
                  </select>
                    <div *ngIf="City.invalid && userForm.submitted">
                        <div class="alert alert-danger">
                            Please Select Your City.
                        </div>
                    </div>
                </div>

                  <div class="col-xl-4 col-lg-6 col-12 form-group">
                      <label>Pincode *</label>
                      <input type="number" name="PinCode" class="form-control" placeholder="" [(ngModel)]=application_cum.PinCode #pincode="ngModel" [ngClass]="{ 'is-invalid': userForm.submitted && pincode.invalid }" maxlength=6 required onKeyPress="if(this.value.length==6) return false;">
                      <div *ngIf="pincode.invalid && userForm.submitted">
                          <div class="alert alert-danger">
                              Please Select Your Pincode.
                          </div>
                      </div>
                  </div>
                  <div class="col-xl-4 col-lg-6 col-12 form-group">
                      <label>STD Code</label>
                      <!-- <input type="text" placeholder="" class="form-control"> -->
                      <input type="number" name="STDCode" class="form-control" placeholder="" [(ngModel)]=application_cum.STDCode #STDCode="ngModel" maxlength=5 required>
                      <!--<div *ngIf="STDCode.errors && userForm.submitted && !isValidFormSubmitted" [ngClass]="'error'">
                      <div *ngIf="STDCode.errors.pattern" class="alert alert-danger">
                        STD Code not valid.
                      </div>
                  </div>-->
                  </div>
                  <div class="col-xl-4 col-lg-6 col-12 form-group">
                      <label>Mobile *</label>
                      <!-- <input type="text" placeholder="" class="form-control"> -->
                      <input type="number" name="MobileNumber" class="form-control" placeholder="" [(ngModel)]=application_cum.Mobile [pattern]="MobileNumberPattern" #MobileNumber="ngModel" [ngClass]="{ 'is-invalid': userForm.submitted && MobileNumber.invalid }"
                          maxlength="10" onKeyPress="if(this.value.length==10) return false;" required>
                      <div *ngIf="MobileNumber.errors && userForm.submitted && !isValidFormSubmitted" [ngClass]="'error'">
                          <div *ngIf="MobileNumber.errors.pattern" class="alert alert-danger">
                              Mobile number not valid.
                          </div>
                      </div>
                  </div>

                <!--   <div class="col-xl-4 col-lg-6 col-12 form-group">
                      <label>Telephone Number</label>
                      <input type="text" placeholder="" class="form-control">
                      <input type="number" name="Telephone" class="form-control" placeholder="" [(ngModel)]=application_cum.Telephone #Telephone="ngModel" maxlength=10 required>
                      <div *ngIf="Telephone.errors && userForm.submitted && !isValidFormSubmitted" [ngClass]="'error'">
                          <div *ngIf="Telephone.errors.pattern" class="alert alert-danger">
                              Telephone number not valid.
                          </div>
                      </div>
                  </div>-->

                  <div class="col-xl-4 col-lg-6 col-12 form-group">
                      <label>E-Mail</label>
                      <!-- <input type="email" placeholder="" class="form-control"> -->
                      <input type="email" name="Email" class="form-control" [(ngModel)]=application_cum.Email #Email="ngModel" [ngClass]="{ 'is-invalid': userForm.submitted && Email.invalid }" required email/>
                      <div *ngIf="userForm.submitted && Email.invalid" class="invalid-feedback">
                          <div *ngIf="Email.errors.required" class="alert alert-danger">Email is required</div>
                          <div *ngIf="Email.errors.Email" class="alert alert-danger">Email is required and format should be <i>john@doe.com</i>.</div>
                      </div>

                  </div>

                  <div class="col-xl-4 col-lg-6 col-12 form-group">
                      <label>Date Of Birth *</label>
                      <!-- air-datepicker -->
                      <input type="text" placeholder="dd/mm/yyyy" name="DOB" [(ngModel)]=application_cum.DOB #DOB="ngModel" class="form-control ">
                      <!-- <i class="far fa-calendar-alt"></i> -->
                  </div>

                  <div class="col-xl-4 col-lg-6 col-12 form-group">
                      <label>Category *</label>
                      <select class=" form-control " name="Category" [(ngModel)]=application_cum.Category #Category="ngModel" [ngClass]="{'invalid-data': Category.invalid && userForm.submitted, 'valid-data': Category.valid && userForm.submitted}" required>
                           <!-- <option value="">Please Select Category *</option> -->
                          <option *ngFor="let Category of Categories"> {{Category}} </option>
                          </select>
                      <div *ngIf="Category.invalid && userForm.submitted">
                          <div class="alert alert-danger">
                              Please Select Your Category.
                          </div>
                      </div>
                  </div>

                  <div class="col-xl-4 col-lg-6 col-12 form-group">
                      <label>Nationality *</label>
                      <select class=" form-control " name="nationality" [(ngModel)]=application_cum.Nationality #nationality="ngModel" [ngClass]="{'invalid-data': nationality.invalid && userForm.submitted, 'valid-data': nationality.valid && userForm.submitted}" required>
                           <!-- <option value="">Please Select Nationality *</option> -->
                          <option *ngFor="let nationality of nationality1"> {{nationality}} </option>
                          </select>
                      <div *ngIf="nationality.invalid && userForm.submitted">
                          <div class="alert alert-danger">
                              Please Select Your Nationality.
                          </div>
                      </div>
                  </div>

                  <div class="col-xl-4 col-lg-6 col-12 form-group">
                      <label>Gender *</label>
                      <select class=" form-control " name="Gender" [(ngModel)]=application_cum.Sex #Gender="ngModel" [ngClass]="{'invalid-data': Gender.invalid && userForm.submitted, 'valid-data': Gender.valid && userForm.submitted}" required>
                           <!-- <option value="">Please Select Gender *</option> -->
                          <option *ngFor="let Gender of Gender1"> {{Gender}} </option>
                          </select>

                      <div *ngIf="Gender.invalid && userForm.submitted">
                          <div class="alert alert-danger">
                              Please Select Your Gender.
                          </div>
                      </div>
                  </div>
                  <div class="col-xl-4 col-lg-6 col-12 form-group">
                  </div>
                  <div class="col-xl-4 col-lg-6 col-12 form-group">
                </div>
                <div class="col-xl-2 col-lg-2 form-group">
                </div>
                <div class="col-xl-8 col-lg-8 col-xs-12 form-group note" style="text-align: center; text-decoration: none; margin-bottom: 0px;">
<h4 style="font-weight: 500; font-size: 18px;">NOTE: <span style="font-size: 16px !important;">File size should be less than 300kb for each attachment.</span></h4>
</div>
<div class="col-xl-2 col-lg-2 form-group">
</div>
                  <div class="col-lg-4 col-12 form-group mg-t-30">
                      <label class="text-dark-medium">Upload Aadhar Card</label>
                      <!--<input type="file" class="form-control-file" [(ngModel)]=application_cum.AadharFile #AadharFile="ngModel">-->
                      <input #fileUpload type="file" (change)="fileChangeEvent($event)" placeholder="Upload Adhar Card" multiple accept=".jpg,.jpeg,.png,.pdf" />
                  </div>
                  <div class="col-lg-4 col-12 form-group mg-t-30">
                      <label class="text-dark-medium">Upload 10th Marksheet</label>
                      <input #fileUpload type="file" (change)="fileChangeEvent($event)" placeholder="Upload SSC Mark Sheet" multiple accept=".jpg,.jpeg,.png,.pdf" />
                  </div>
                  <div class="col-lg-4 col-12 form-group mg-t-30">
                      <label class="text-dark-medium">Upload 12th Marksheet</label>
                      <input #fileUpload type="file" (change)="fileChangeEvent($event)" placeholder="Upload HSC Mark Sheet" multiple accept=".jpg,.jpeg,.png,.pdf" />
                  </div>
                  <div class="col-lg-4 col-12 form-group mg-t-30">
                      <label class="text-dark-medium">Upload Degree Certificate</label>
                      <input #fileUpload type="file" (change)="fileChangeEvent($event)" placeholder="Upload Degree Certificate" multiple accept=".jpg,.jpeg,.png,.pdf" />
                  </div>
                  <div class="col-lg-4 col-12 form-group mg-t-30">
                      <label class="text-dark-medium">Upload Student Photo (150px X 150px)</label>
                      <input #fileUpload type="file" (change)="fileChangeEvent($event)" placeholder="Upload Photo" multiple accept=".jpg,.jpeg,.png,.pdf" />
                  </div>

                  <form class="new-added-form" #userForm1="ngForm" name="form" (ngSubmit)="userForm1.form.valid && onSubmit1()" novalidate>
                      <div class="row" style="border-top: 1px solid gray;">
                          <div class="col-xl-4 col-lg-6 col-12 form-group">
                              <label>Type of Examination *</label>
                              <select class=" form-control " name="Examination" [(ngModel)]=education.Examination #Examination="ngModel" [ngClass]="{'invalid-data': Examination.invalid && userForm1.submitted, 'valid-data': Examination.valid && userForm1.submitted}" required>
                           <!-- <option value="">Please Select Course Name *</option> -->
                          <option *ngFor="let Exam of Examinations"> {{Exam}} </option>
                          </select>
                              <div *ngIf="Examination.invalid && userForm1.submitted">
                                  <div class="alert alert-danger">
                                      Please Select Your Examination.
                                  </div>
                              </div>
                          </div>

                          <div class="col-xl-4 col-lg-6 col-12 form-group">
                              <label> Board *</label>
                              <input type="text" placeholder="" class="form-control" name="Board" [(ngModel)]=education.Board #Board="ngModel" [ngClass]="{ 'is-invalid': userForm1.submitted && Board.invalid }" required minlength="3">
                              <div *ngIf="userForm1.submitted && Board.invalid" class="invalid-feedback">
                                  <div *ngIf="Board.errors.required " class="alert alert-danger"> Name is required</div>
                                  <div *ngIf="Board.errors.minlength " class="alert alert-danger"> Name must be at least 3 characters long</div>
                              </div>
                          </div>

                          <div class="col-xl-4 col-lg-6 col-12 form-group">
                              <label> Year *</label>
                              <input type="number" placeholder="" class="form-control" name="Year" [(ngModel)]=education.Year #Year="ngModel" [ngClass]="{ 'is-invalid': userForm1.submitted && Year.invalid }"  maxlength="4" onKeyPress="if(this.value.length==4) return false;">
                              <div *ngIf="userForm1.submitted && Year.invalid" class="invalid-feedback">
                                  <div *ngIf="Year.errors.required " class="alert alert-danger"> Year is required</div>
                                  <div *ngIf="Year.errors.minlength " class="alert alert-danger"> Year must be at least 4 characters long</div>
                              </div>
                          </div>

                          <div class="col-xl-4 col-lg-6 col-12 form-group">
                              <label> Marks *</label>
                              <input type="number" placeholder="" class="form-control" name="Marks" [(ngModel)]=education.Marks #Marks="ngModel" [ngClass]="{ 'is-invalid': userForm1.submitted && Marks.invalid }" required minlength="2" maxlength="2">
                              <div *ngIf="userForm1.submitted && Marks.invalid" class="invalid-feedback">
                                  <div *ngIf="Marks.errors.required " class="alert alert-danger"> Marks is required</div>
                                  <div *ngIf="Marks.errors.minlength " class="alert alert-danger"> Marks must be at least 2 characters long</div>
                              </div>
                          </div>

                          <div class="col-xl-4 col-lg-6 col-12 form-group">
                              <label> Subject *</label>
                              <input type="text" placeholder="" class="form-control" name="Subject" [(ngModel)]=education.Subject #Subject="ngModel" [ngClass]="{ 'is-invalid': userForm1.submitted && Subject.invalid }" required minlength="3">
                              <div *ngIf="userForm1.submitted && Subject.invalid" class="invalid-feedback">
                                  <div *ngIf="Subject.errors.required " class="alert alert-danger"> Subject is required</div>
                                  <div *ngIf="Subject.errors.minlength " class="alert alert-danger"> Subject must be at least 3 characters long</div>
                              </div>
                          </div>

                          <div class="col-xl-4 col-lg-6 col-12 form-group mg-t-8" style=" padding: 30px;">
                              <button type="submit" class="btn-fill-lg btn-gradient-yellow btn-hover-bluedark" style="padding: 10px 35px;" (click)="AddDetails()">Add</button>
                              <button type="reset" class="btn-fill-lg bg-blue-dark btn-hover-yellow" style="padding: 10px 35px;">Reset</button>
                          </div>
                      </div>


                      <div class="col-lg-12 col-md-12 col-xs-8">
                          <table id=Table>
                              <thead>
                                  <th>Examination</th>
                                  <th>Board/University</th>
                                  <th style="cursor: pointer;">Year Of Passing</th>
                                  <th>Marks in %</th>
                                  <th>Main Subject</th>
                                  <th>Delete</th>
                              </thead>
                              <tbody *ngFor="let details of application_cum.EducationDetails">
                                  <td>{{details.Examination}}</td>
                                  <td>{{details.Board}}</td>
                                  <td>{{details.Year}}</td>
                                  <td>{{details.Marks}}</td>
                                  <td>{{details.Subject}}</td>
                                  <td (click)="Delete(details.Examination)" style="cursor: pointer;"><i class="fa fa-trash" aria-hidden="true" style="margin-right: 7px;"></i>Delete</td>
                              </tbody>
                          </table>
                      </div>


                  </form>


<!--                   <form name="myform" id="myForm">
                      Select Country: <select name="state" id="countySel" size="1">
<option value="" selected="selected">Select Country</option>
</select>
                      <br>
                      <br> Select State: <select name="country" id="stateSel" size="1">
<option value="" selected="selected">Please select Country first</option>
</select>
                      <br>
                      <br> Select District: <select name="district" id="districtSel" size="1">
<option value="" selected="selected">Please select State first</option>
</select><br>
                      <input type="submit">
                  </form> -->


                  <!-- <form name="myform1" id="myForm1">
                      <div class="col-xl-4 col-lg-6 col-12 form-group">
                          <label>State *</label>
                          <select name="state" id="countySel" size="1">
<option value="" selected="selected">Select Country</option>
</select>

                      </div>

                      <div class="col-xl-4 col-lg-6 col-12 form-group">
                          <label>District *</label>
                          <select name="countrya" id="stateSel" size="1">
<option value="" selected="selected">Please select Country first</option>
</select>
                      </div>

                      <div class="col-xl-4 col-lg-6 col-12 form-group">
                          <label>City *</label>
                          <select name="district" id="districtSel" size="1">
<option value="" selected="selected">Please select State first</option>
</select>

                      </div>

                  </form> -->
                  <!-- <form class="new-added-form" #userForm1="ngForm" name="form" (ngSubmit)="userForm1.form.valid && onSubmit1()" novalidate>
                      <div class="row">
                          <div class="col-xl-4 col-lg-6 col-12 form-group">
                              <label>Type of Examination *</label>
                              <select class=" form-control" name="Examination" [(ngModel)]=education.Examination #Examination="ngModel" [ngClass]="{'invalid-data': Examination.invalid && userForm1.submitted, 'valid-data': Examination.valid && userForm1.submitted}" required>
                           <option value="">Please Select Course Name *</option>
                          <option *ngFor="let Examination of Examinations"> {{Examination}} </option>
                          </select>
                              <div *ngIf="Examination.invalid && userForm1.submitted">
                                  <div class="alert alert-danger">
                                      Please Select Your Examination.
                                  </div>
                              </div>
                          </div>

                          <div class="col-xl-4 col-lg-6 col-12 form-group">
                              <label> Board *</label>
                              <input type="text" placeholder="Enter Your Board" class="form-control" name="Board" [(ngModel)]=education.Board #Board="ngModel" [ngClass]="{ 'is-invalid': userForm1.submitted && Board.invalid }" required minlength="3">
                              <div *ngIf="userForm1.submitted && Board.invalid" class="invalid-feedback">
                                  <div *ngIf="Board.errors.required " class="alert alert-danger"> Name is required</div>
                                  <div *ngIf="Board.errors.minlength " class="alert alert-danger"> Name must be at least 3 characters long</div>
                              </div>
                          </div>

                          <div class="col-xl-4 col-lg-6 col-12 form-group">
                              <label> Year *</label>
                              <input type="number" placeholder="Enter Your Year" class="form-control" name="Year" [(ngModel)]=education.Year #Year="ngModel" [ngClass]="{ 'is-invalid': userForm1.submitted && Year.invalid }" required minlength="4">
                              <div *ngIf="userForm1.submitted && Year.invalid" class="invalid-feedback">
                                  <div *ngIf="Year.errors.required " class="alert alert-danger"> Year is required</div>
                                  <div *ngIf="Year.errors.minlength " class="alert alert-danger"> Year must be at least 4 characters long</div>
                              </div>
                          </div>

                          <div class="col-xl-4 col-lg-6 col-12 form-group">
                              <label> Marks *</label>
                              <input type="number" placeholder="Enter Your Marks" class="form-control" name="Marks" [(ngModel)]=education.Marks #Marks="ngModel" [ngClass]="{ 'is-invalid': userForm1.submitted && Marks.invalid }" required minlength="2">
                              <div *ngIf="userForm1.submitted && Marks.invalid" class="invalid-feedback">
                                  <div *ngIf="Marks.errors.required " class="alert alert-danger"> Marks is required</div>
                                  <div *ngIf="Marks.errors.minlength " class="alert alert-danger"> Marks must be at least 2 characters long</div>
                              </div>
                          </div>

                          <div class="col-xl-4 col-lg-6 col-12 form-group">
                              <label> Subject *</label>
                              <input type="text" placeholder="Enter Your Subject" class="form-control" name="Subject" [(ngModel)]=education.Subject #Subject="ngModel" [ngClass]="{ 'is-invalid': userForm1.submitted && Subject.invalid }" required minlength="3">
                              <div *ngIf="userForm1.submitted && Subject.invalid" class="invalid-feedback">
                                  <div *ngIf="Subject.errors.required " class="alert alert-danger"> Subject is required</div>
                                  <div *ngIf="Subject.errors.minlength " class="alert alert-danger"> Subject must be at least 3 characters long</div>
                              </div>
                          </div>

                          <div class="col-12 form-group mg-t-8">
                              <button type="submit" class="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">Add Data</button>
                              <button type="reset" class="btn-fill-lg bg-blue-dark btn-hover-yellow">Reset</button>
                          </div>
                      </div>
                  </form> -->
                  <div class="col-12 form-group mg-t-8">
                      <!-- <button type="submit" class="btn-fill-lg btn-gradient-yellow btn-hover-bluedark" [class.spinner]="loading" [disabled]="loading" (click)="save()">Submit</button> -->
                      <!-- <button type="submit" class="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">Submit</button> -->
                      <button class="btn-fill-lg btn-gradient-yellow btn-hover-bluedark" [disabled]="isLoading" (click)="onSubmit()"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, '': !isLoading}"></i> {{button}}</button>
                      <button type="reset" class="btn-fill-lg bg-blue-dark btn-hover-yellow">Reset</button>
                  </div>
              </div>
          </form>
      </div>
  </div>
  <!-- Admit Form Area End Here -->
  <!-- <footer class="footer-wrap-layout1">
      <div class="copyright">© Copyrights <a href="#">KVBOARD</a> 2019. All rights reserved. Designed by <a href="#">PsdBosS</a></div>
  </footer> -->
</div>
