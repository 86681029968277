import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { PreloadAllModules } from '@angular/router'
import { CoursesComponent } from './courses/courses.component'
import { FireAndSafetyInfoComponent } from './fire-and-safety-info/fire-and-safety-info.component'
import { CivilInfoComponent } from './civil-info/civil-info.component'
import { HotelManagementInfoComponent } from './hotel-management-info/hotel-management-info.component'
import { NaturopathyInfoComponent } from './naturopathy-info/naturopathy-info.component'
import { HomeComponent } from './home/home.component'
import { RegisterComponent } from './register/register.component'
import { AddressComponent } from './address/address.component'
import { ApplicationCumFormComponent } from './application-cum-form/application-cum-form.component'
import { HealthScienceComponent } from './health-science/health-science.component'
import { OnlineExamComponent } from './online-exam/online-exam.component'
import { QuizComponent } from './quiz/quiz.component'
import { ApplyQuizComponent } from './apply-quiz/apply-quiz.component'
import { ExamResultListComponent } from './exam-result-list/exam-result-list.component'
import { ApplicationListComponent } from './application-list/application-list.component';
import { PaymentReceiptComponent } from './payment-receipt/payment-receipt.component'
import { AffiliationFormComponent } from './affiliation-form/affiliation-form.component'

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  { path: 'home', component: HomeComponent },
  { path: 'courses', component: CoursesComponent },
  { path: 'fire-and-safety-info', component: FireAndSafetyInfoComponent },
  { path: 'civil-info', component: CivilInfoComponent },
  { path: 'hotel-management-info', component: HotelManagementInfoComponent },
  { path: 'naturopathy-info', component: NaturopathyInfoComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'address', component: AddressComponent },
  {
    path: 'application-cum-admission',
    component: ApplicationCumFormComponent,
  },
  { path: 'health-science', component: HealthScienceComponent },
  {
    path: 'Online-Exam',
    component: OnlineExamComponent,
  },
  {
    path: 'quiz',
    component: QuizComponent,
  },
         {
        path: 'apply-quiz',
        component: ApplyQuizComponent,
      },
      {
        path: 'Exam-Result-List',
        component: ExamResultListComponent,
      },
      {
        path: 'Application-List',
        component: ApplicationListComponent,
      },
      {
        path: 'Payment-Receipt',
        component: PaymentReceiptComponent,
      },
      {
        path: 'affiliation-form',
        component: AffiliationFormComponent,
      },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
