<div class="row" style="margin:0;">
    <section class="main" style="z-index: 2;width: -webkit-fill-available;">
        <div class='container-fluid' style="width: -webkit-fill-available;padding:0">
            <div class="carousel-wrap">
                <div class="owl-carousel owl-banner owl-theme" style="height: 20%; margin-top: 22px;">
                    <!-- <div class="item">
                        <img src="../assets/images/logo/slider-1.jpg" />
                    </div> -->
                    <div class="item">
                        <img src="../../assets/images/1.jpg" />
                    </div>
                    <div class="item">
                        <img src="../../assets/images/2.jpg" />
                    </div>
                    <div class="item">
                        <img src="../../assets/images/3.jpg" />
                    </div>
                    <div class="item">
                        <img src="../../assets/images/4.jpg" />
                    </div>
                    <div class="item">
                        <img src="../../assets/images/banner-sivt6.jpg" />
                    </div>
                    <!-- <div class="item" >
            <video  width="100%" height="100%"  autoplay loop style="position: relative;">
              <source src="http://www.artcopycode.com/videos/acc.ogg" type="video/ogg">
                <source src="https://www.w3schools.com/howto/rain.mp4" type="video/mp4">
                  <div class="home-block__button" style="position: absolute;"><a routerLink = "/home" class="btn btn-outline-secondary" style="border:1px solid white ;">«Comprehensive Automation»</a></div>
              Your browser does not support the video tag.
            </video>
          </div>
         -->

                </div>
            </div> 

        </div>
    </section>
</div>

<!-- <div [(ngModel)]="timer" mbsc-timer [mbsc-options]="timerSettings"></div>
<table id="laps"></table> -->
<div class="row row-discription" style="margin:0; background-color: #f2f2f2 !important;" >
    <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
        <p class="p"><b>Vocational Education and Training (VET)</b> is an important element of the nation's education initiative. In order for Vocational Education to play its part effectively in the changing national context and for India to enjoy the fruits of the
            demographic dividend, there is an urgent need to redefine the critical elements of imparting vocational education and training to make them flexible, contemporary, relevant, inclusive and creative. The Government is well aware of the important
            role of Vocational education and has already taken a number of important initiatives in this area. School-based vocational education in India is currently covered by a centrally sponsored scheme which was mooted in 1988 and was aimed at providing
            an alternative to the pursuit of higher academic education. One of the objectives of the Vocational Education Programme of SIVT is to meet the need for skilled and middle-level manpower for the growing sectors of economy, both organized and
            unorganized. The range of Vocational Education courses has been expanding over the years depending upon needs of learners and market demands. The present Vocational Education courses of SIVT are meant for both urban and rural sectors.</p>
        <p class="p"><b>SAHYADRI INSTITUTE OF VOCATIONAL TRAINING (SIVT)</b> promotes job oriented, work related, skill-based and life coping education so as to develop new standards in the field of education especially the implementation of job oriented programs
            all over the country and to facilitate the reach of education to unreachable.</p>
        <p class="p">SIVT is an academically independent self-financed autonomous certification body offering various industry integrated professional, vocational & job oriented diplomas & certification courses like Business Management, Hotel Management, Fire-Safety,
            Teaching, IT, Technical & Vocational and Other Job oriented courses through regular, online and distance learning mode.</p>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12" style="background-color: #d25b21;">
        <div class="contact-form">
            <h3 style="color: white;">Enquiry Form</h3>
            <hr>
            <form #enquiryform="ngForm" (ngSubmit)="onSubmit(enquiryform)">
                <div class="form-group">
                    <label style="font-weight: 500;color: white;font-size: 13px;">Name*</label>
                    <br>
                    <input class="form-control input" name="Name" placeholder="Your Name" style="font-size: 13px;" [(ngModel)]="Name" required>
                </div>
                <div class="form-group">
                    <label style="font-weight: 500;color: white;font-size: 13px;">Email*</label>
                    <br>
                    <input class="form-control input" placeholder="Your Email" name="Email" style="font-size: 13px;" [(ngModel)]="Email" [pattern]="emailPattern" #offEmail="ngModel" required>
                    <div *ngIf="offEmail.errors && enquiryform.submitted && !isValidFormSubmitted" [ngClass]="'error'">
                        <div *ngIf="offEmail.errors.required" style="color: #fff !important;">
                            Email required.
                        </div>
                        <div *ngIf="offEmail.errors.pattern" style="color: #fff !important;">
                           Email not valid.
                        </div>
                    </div>
                </div>

                <div class=" form-group">
                    <label style="font-weight: 500;color: white;font-size: 13px;">Mobile*</label>
                    <!-- <input type="text" placeholder="" class="form-control"> -->
                    <input type="number" name="Mobile" class="form-control input" placeholder="Your Mobile Number" style="font-size: 13px;" [(ngModel)]=Mobile [pattern]="MobileNumberPattern" #offMobile="ngModel" [ngClass]="{ 'is-invalid': enquiryform.submitted && offMobile.invalid }" maxlength="10"
                        onKeyPress="if(this.value.length==10) return false;" required>
                    <div *ngIf="offMobile.errors && enquiryform.submitted && !isValidFormSubmitted" [ngClass]="'error'">
                        <div *ngIf="offMobile.errors.required" style="color: #fff !important;">
                             Mobile Number required.
                        </div>
                        <div *ngIf="offMobile.errors.pattern" class="alert alert-danger">
                            Mobile number not valid.
                        </div>
                    </div>
                </div>


                <div class="form-group" ng-app="myApp" ng-controller="myCtrl">
                    <label style="font-weight: 500;color: white;font-size: 13px;">Course*</label>
                    <br>
                    <select id="courses" name="Course" class="form-control input" style="font-size: 13px;" [(ngModel)]="Course" placeholder="Select Course" required>
                        <option [value]=" " >Diploma in Fire Technology & Safety Management</option>
                      <option [value]=" ">Advanced Diploma in Industrial Safety Management</option>
                      <option [value]=" ">Certificate Program in Construction Supervisor</option>
                      <option [value]=" ">B.VOC. In Hotel Management and Catering Technology</option>
                      <option [value]=" ">Diploma in Hotel Management and Catering Technology</option>
                      <option [value]=" ">Certificate Course in Yoga Teacher(CCTY)</option>
                      <option [value]=" ">Diploma in Naturopathy and Yogic Science(DNYS)</option>
                      <!-- <option [value]=" ">Other</option> -->
                    </select>
                    <!-- <div>
                        <input id="hidden_div" class="form-control input" placeholder="Course" style="font-size: 13px;visibility: hidden;" required>
                    </div> -->
                </div>
                <div class="form-group">
                    <textarea class="form-control input" placeholder="Leave a comment" rows="6" style="font-size: 13px;" name="Comment" [(ngModel)]="Comment" required></textarea>
                </div>
                <button href="#" class="btn btn-default" type="submit">Send Message</button>
            </form>
        </div>
    </div>
</div>

<div class="container-fluid con-row">
    <div class="row popular-row" style="margin:0;">
        <h2><b>Popular Courses</b></h2>
        <br>
        <h4>Listed Below Our Most Popular Courses</h4>
    </div>
    <div class="container">
        <div class="container">

            <div class="carousel-wrap">
                <div class="owl-carousel owl-courses owl-theme">
                    <div class="item">
                        <img style="height: 190px;" src="../assets/images/fireAndsafety.jpg" />
                        <h3 class="title">Department of Fire and Safety</h3>
                        <p class="para">It is offering an outstanding military discipline in its Fire & Safety courses. </p>
                        <div class="button" routerLink="/fire-and-safety-info">View Detail</div>
                    </div>
                    <div class="item">
                        <img style="height: 190px;" src="../assets/images/civil.jpg" />
                        <h3 class="title">Department of Civil</h3>
                        <p class="para">It is the offering outstanding skill development in planning, organizing and controlling projects.</p>
                        <div class="button" routerLink="/civil-info">View Detail</div>
                    </div>
                    <div class="item">
                        <img style="height: 190px;" src="../assets/images/hotel.jpg" />
                        <h3 class="title">Department of Hotel Management and Catering Technology</h3>
                        <p class="para">It was established under the banner of the Sahyadri Institute of Vocational Training.</p>
                        <div class="button" routerLink="/hotel-management-info">View Detail</div>
                    </div>
                    <div class="item">
                        <img style="height: 190px;" src="../assets/images/yoga.jpg" />
                        <h3 class="title">Department of Naturopathy and Yogic Science</h3>
                        <p class="para">Presently, all modern health care facilities have recognized the importance of Yoga and Naturopathy.</p>
                        <div class="button" routerLink="/naturopathy-info">View Detail</div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="tab-discription">
    <div class="container tab-container">
        <div class="row popular-row " style="margin:0;">
            <h2 style="margin-top:10px"><b>Why Choose Us?</b></h2>
        </div>
        <br>
        <!-- Nav tabs -->
        <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" data-toggle="tab" href="#mission" style="font-size: 15px;"> Our Mission</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#vision" style="font-size: 15px;">Our Vision</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#goal" style="font-size: 15px;">Our Goal</a>
            </li>
        </ul>
        <!-- Tab panes -->
        <div class="tab-content">
            <div id="mission" class="container tab-pane active"><br>
                <p style="text-align:justify;font-size: 14px;">To Promote global competitiveness by providing multiple opportunities for excellent education, skills development, applied research, academic innovation and service to humanity. To establish value creating networks and linkages with corporate,
                    Industries, educational institutes and universities of National and International Importance.</p>
                <p style="text-align:justify;font-size: 14px;">To Leverage the Intellectual capital through research activates and creating knowledge integration platforms in India and abroad. To synergize activities, and Institutes through infrastructure sharing, industry, Interface, faculty and
                    student exchange Program.
                </p>
            </div>
            <div id="vision" class="container tab-pane fade"><br>
                <p style="text-align:justify;font-size: 14px;">Create opportunities for all to acquire skills throughout life and especially for youth, women and disadvantaged groups. Promote commitment by all stakeholders to own skill development initiatives. Develop a high quality skilled workforce/entrepreneur
                    relevant to current and emerging employment Market needs.</p>
                <p style="text-align:justify;font-size: 14px;display: list-item;">To Democratize the Education</p>
                <p style="text-align:justify;font-size: 14px;display: list-item;">To Provide Accessibility Towards Higher Education.</p>
                <p style="text-align:justify;font-size: 14px;display: list-item;">To Impart Quality Education.</p>
            </div>
            <div id="goal" class="container tab-pane fade"><br>
                <p style="text-align:justify;font-size: 14px;">To Provide best quality vocational Training to the people who are not educated in the regular pattern and develop their skills so that they obtain the necessary employable skills and become self reliant.
                </p>
            </div>
        </div>
    </div>
</div>

<section class="student-section">
    <div class="container">
        <div class="row popular-row" style="margin:0;">
            <div class="section-title text-center">
                <h2>Happy Students</h2>
                <h4>What Our Students Say About SIVT</h4>
            </div>
        </div>
        <section class="main" style="z-index: 2;width: -webkit-fill-available;">
            <div class='container-fluid' style="width: -webkit-fill-available;padding:0">
                <div class="carousel-wrap">
                    <div class="owl-carousel owl-student owl-theme" style="height: 20%;">

                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="testimonial">
                                <img class="alignleft img-circle" src="upload/xstudent_01.png.pagespeed.ic.MNnlL3av5t.webp" alt="" data-pagespeed-url-hash="1412222349" onload="pagespeed.CriticalImages.checkImageForCriticality(this);">
                                <p style="font-size: 14px;">It was my immense luck and fortune to be the part of SIVT where I can grow. The entire faculty and department leaves no stone unturned to shape one's future.. </p>
                                <div class="testimonial-meta">
                                    <h5>Rutik Mali <small><a href="#" style="color: #000;font-size: 11px;">&nbsp;</a></small></h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="testimonial">
                                <img class="alignleft img-circle" src="upload/xstudent_02.png.pagespeed.ic.ZpsnCFhAZN.webp" alt="" data-pagespeed-url-hash="1706722270" onload="pagespeed.CriticalImages.checkImageForCriticality(this);">
                                <p style="font-size: 14px;">"SIVT has been a great contributor to the development of my personality. I have established my leadership, time management and team skills and have also been able to advance these skills to the whole new augment level."</p>
                                <div class="testimonial-meta">
                                    <h5>Ajinkya Paraghe <small><a href="#" style="color: #000;font-size: 11px;">&nbsp;</a></small></h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="testimonial">
                                <img class="alignleft img-circle" src="upload/xstudent_03.png.pagespeed.ic.mWzN_tnr5A.webp" alt="" data-pagespeed-url-hash="2001222191" onload="pagespeed.CriticalImages.checkImageForCriticality(this);">
                                <p style="font-size: 14px;">My four years at SIVT were great and a memory to cherish for lifetime. It was full of learning and grooming oneself. It gave me an opportunity to meet different kind of people and learned many things.</p>
                                <div class="testimonial-meta">
                                    <h5>Shubham Hajare<small><a href="#" style="color: #000;font-size: 11px;">&nbsp;</a></small></h5>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    </div>
</section>

<!-- <div class="container container-contact" style="padding: 20px;" id="contact">
    <div class="row popular-row ">
        <h2 style="margin-top:10px"><b>Contact Details</b></h2>
    </div>
    <div class="container">
        <p style="width: 100%;font-size: 14px;">
            <img class="contact-img" style="height: 13px;margin-top: -5px;" src="../assets/images/institute-add.svg">&nbsp;<b> Institute Address :</b> Gat no: 207, Kondhanpur Road, Near Sinhagad Sabhagrugh A/P Ranje, Tal. Bhor, Dist. Pune, Maharashtra
            -412205 .
        </p>
        <p style="width: 100%;font-size: 14px;">
            <img class="contact-img" style="height: 13px;margin-top: -5px;" src="../assets/images/city-add.svg">&nbsp;<b> City Office Address :</b> Taniksha Apartment, Flat no 201, Renuka Nagar, Lane 2, Warje, Pune-411058
        </p>
        <p style="width: 100%;font-size: 14px;">
            <img class="contact-img" style="height: 13px;margin-top: -5px;" src="../assets/images/mobile.svg">&nbsp;<b> Mobile :</b> 9158035367 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <img class="contact-img" style="height: 13px;margin-top: -5px;" src="../assets/images/landline.svg">&nbsp;<b> Landline :</b>            0202979559
        </p>
        <p style="width: 100%;font-size: 14px;">
            <img class="contact-img" style="height: 13px;margin-top: -5px;" src="../assets/images/email.svg">&nbsp;<b> Email :</b> admissions@sivtpune.com
        </p>
        <p style="width: 100%;font-size: 14px;">
            <img class="contact-img" style="height: 13px;margin-top: -5px;" src="../assets/images/website.svg">&nbsp;<b> Website :</b> www.sivtpune.com
        </p>
    </div>
</div> -->
<!-- <ng-softnotify (onDismiss)="onDismissNotification($event)" [direction]="'left-top'"></ng-softnotify> -->


<div class="payment-status-wrapper" *ngIf="displayModal">
    <div class="payment-wrapper">
        <img src="../../assets/images/cross.png" alt="" class="cross" (click)="closeModal()">
        <img src="../../assets/images/Payment.jpg" alt="" class="paymentimg">
        <h2 class="paymentStatus" style="color: green;" *ngIf="paymentType == 'Success'">Payment Completed Successfully</h2>
        <h2 class="paymentStatus" style="color: red;" *ngIf="paymentType != 'Success'">Payment Failed <br> Please Try After Some Time</h2>
    </div>
</div>