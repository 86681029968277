import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { GlobalVariable } from './Globals'
import { OnlineExam,ExamResult, Receipt } from './user1'

@Injectable({
  providedIn: 'root',
})
export class CommonserviceService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json, text/plain',
        "Access-Control-Allow-Headers": "Content-Type",
      "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
    }),
  }

  constructor(private http: HttpClient) {}

  // showToast(data: string) {
  //   const toast = this.toastController.create({
  //     message: data,
  //     duration: 1000
  //  }).then((toastdata) => {
  //   toastdata.present();
  //  });
  //  }
  AddOnlineExamForm(onlineExam): Observable<any> {
    // alert(GlobalVariable.SERVICE_API_URL);
    // alert(121);
    return this.http.post<OnlineExam>(
      GlobalVariable.SERVICE_API_URL + 'OnlineExamForm/AddOnlineExamForm',
      onlineExam,
      this.httpOptions,
    )
 
  }
  AddExamResult(examResult): Observable<any> {
    // alert(GlobalVariable.SERVICE_API_URL);
    // alert(121);
    return this.http.post<ExamResult>(
      GlobalVariable.SERVICE_API_URL + 'ExamResult/AddExamResult',
      examResult,
      this.httpOptions,
    )
 
  }

  GetUserScoreList() {
    return this.http.get<any>(
      GlobalVariable.SERVICE_API_URL + "UserScore/GetUserScore",
      this.httpOptions
    );
  }

  GetApplicationFormList() {
    return this.http.get<any>(
      GlobalVariable.SERVICE_API_URL + "ApplicationForm/GetAllApplicationForm",
      this.httpOptions
    );
  }

  SendFeesReceipt(receipt): Observable<any> {
    // alert(GlobalVariable.SERVICE_API_URL);
    // alert(121);
    return this.http.post<Receipt>(
      GlobalVariable.SERVICE_API_URL + 'StudentInfo/SendFeeEmail',
      receipt,
      this.httpOptions,
    )
 
  }

}
