import { Component, OnInit } from "@angular/core";
import { Application_cum, Receipt } from "../user1";
import { Education } from "../user1";
import { CommonserviceService } from "../commonservice.service";
import { Observable } from "rxjs";
import { MatSelectModule } from "@angular/material/select";
import { GlobalVariable } from "src/app/Globals";
import {
  HttpClient,
  HttpHeaders,
  HttpRequest,
  HttpEventType,
} from "@angular/common/http";
import { Data, Router } from "@angular/router";
@Component({
  selector: "app-application-cum-form",
  templateUrl: "./application-cum-form.component.html",
  styleUrls: ["./application-cum-form.component.css"],
})
export class ApplicationCumFormComponent implements OnInit {
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json, text/plain",
    }),
  };
  Name: string;
  Fees: string;
  FatherName: string;
  MotherName: string;
  CourseName: string;
  Aadhar: string;
  Address: string;
  City: string;
  DOB: string;
  District: string;
  State: string;
  PinCode: number;
  Email: string;
  STDCode: string;
  Mobile: number;
  Telephone: number;
  Category: string;
  Sex: string;
  Nationality: string;
  AadharFile: string;
  Class: string;
  receiptNo: string;
  Date: string;
  ampuntWords: string;
  mode: string;
  checkNo: string;
  moneyOrder: string;
  receivedBy: string;

  model: any = {};
  application_cum: Application_cum;
  receipt : Receipt;
  education: Education;
  progress: any;
  loading = false;
  uploadResult: any;
  filesToUpload: Array<File>;
  selectedFileNames: any[] = [];
  // CourseList: Course[];
  CityList: any[];
  DistrictList: any[];
  StateList: any[];
  CountryList: any[];
  CourseList: any[];
  TransId: any;
  button = "Submit";
  isLoading = false;
  isTimeout = false;

  // userModel = new StudentForm('','','', '', '','','',);
  // religions = ["Hindu", "Islam", "Christian", "Buddish", "Others"];
  nationality1 = ["Indian", "Other"];
  Categories = ["Open", "OBC", "SC", "NT"];
  pincodes = ["411052", "411053", "411563", "411853", "531053"];
  Cities = [
    "Pune",
    "Nagpur",
    "Nashik",
    "Thane",
    "Aurangabad",
    "Solapur",
    "Satara",
    "Dhule",
    "Sangali",
    "Amravati",
    "Kolhapur",
    "Latur",
    "Jalgoan",
    "Akola",
  ];
  // years = ["1994", "1995", "1996", "1997", "1998", "1999"];
  states = [
    "Maharashtra",
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujrat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ];
  Districts = [
    "Pune",
    "Nagpur",
    "Nashik",
    "Thane",
    "Aurangabad",
    "Solapur",
    "Satara",
    "Dhule",
    "Sangali",
    "Amravati",
    "Kolhapur",
    "Latur",
    "Jalgoan",
    "Akola",
  ];
  Gender1 = ["Male", "Female", "Other"];
  CourseNames = [
    "Diploma In Fire And Safety",
    "Certificate Program of Construction Supervisor",
    "Diploma In Electrician",
    "Diploma In Hotel Management And Catering Technology",
    "B.VOC In Hotel Management And Catering Technology",
    "Diploma In Naturopathy And Yogic Science",
    "Bachelor In Naturopathy And Yogic Science",
    "Certificate Course In Yoga Teacher(CCYT)",
    "Diploma In Industrial Safety",
    "Diploma In General Nursing Midwifery Assistant",
    "Diploma In Medical Lab Technician",
    "Diploma In Operation Theatre Technician",
    "B Voc in Patient care Management",
    "B Voc in Medical Lab Technology",
    "Diploma In Sanitary Inspector",
    "Diploma In Hospital Management",
  ];
  classes = ["1st Year", "2nd Year", "3rd Year"];

  Examinations = ["10th", "12th", "Degree"];

  MobileNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";
  isValidFormSubmitted = false;

  numberPattern = "^((\\+91-?)|0)?[0-9]{10}$";
  isValidNoSubmitted = false;
  addhar: any;
  // isValidFormSubmitted = true;
  // emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  EmailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$";
  // MobileNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";

  onSubmit() {
    this.receipt.name = this.application_cum.Name;
    this.receipt.Course = this.application_cum.CourseName;
    this.receipt.PaidFees = this.application_cum.Fees;
    this.receipt.TotalFees = this.application_cum.Fees;
    this.receipt.Email = this.application_cum.Email;
    //this.service.SendFeesReceipt(this.receipt).subscribe((resp) => {
  //if(resp == "Success"){}});
    this.isLoading = true;
    this.button = "Processing";
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
      }),
    };
    // console.log(JSON.stringify(this.application_cum))
    this.http
      .post<Application_cum>(
        GlobalVariable.SERVICE_API_URL + "StudentInfo/SubmitForm",
        this.application_cum,
        httpOptions
      )
      .subscribe((res) => {
        // console.log(res)
        let formData = new FormData();
        let cnt = 0;
        // tslint:disable-next-line: align
        this.selectedFileNames.forEach((element) => {
          formData.append("uploadedFiles" + cnt, element[0], element[0].name);
          cnt++;
        });
        if (this.selectedFileNames.length > 0) {
         // alert(1);
          this.http
            .post(
              GlobalVariable.SERVICE_API_URL +
                "StudentInfo/UploadDocs?applicationID=" +
                this.application_cum.Aadhar +
                "&studentName=" +
                this.application_cum.Name +
                "&MobileNumber=" +
                this.application_cum.Mobile,
              formData,
              {
                // headers: new HttpHeaders({
                //   "Content-Type": "application/json",
                //   "Access-Control-Allow-Origin": "*",
                //   "Access-Control-Allow-Headers": "Content-Type",
                //   "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
                // }),
                reportProgress: true,
                observe: "events",
              }
            )
            .subscribe((event) => {
              if (event.type === HttpEventType.UploadProgress) {
                this.progress = Math.round((100 * event.loaded) / event.total);
              } else if (this.progress === 100 && this.isLoading == true) {
                this.isLoading = false;
                this.button = "Submit";
                alert(
                  "This Session is complete and your application have been received.We are redirecting you to payment page."
                );

                this.addhar = this.application_cum.Aadhar;
                this.OnlinePayment(this.application_cum.Aadhar, 12).subscribe(
                  (resp) => {
                    console.log(resp);
                    // alert(resp)
                    window.location.href= resp;
                  }
                );

                var status = "";
                let i = 0;
                let waitms = 0;
                while (i < 20) {
                  waitms = waitms + 10000;

                  setTimeout(
                    function () {
                      if ((this.isTimeout = true)) {
                        return;
                      }
                      //alert(this.addhar+'inte');
                      this.GetPaymentStatus(this.addhar).subscribe((resp) => {
                        //alert(resp.status);
                        this.TransId = resp.TransactionId;
                        // console.log(this.TransId);
                        if (resp.status != "Pending" && resp.status != null) {
                          //alert("!=Pending");
                          if (resp.status == "Success") {
                            //alert('if else');


                            this.receipt.Mobile = this.application_cum.Mobile.toString();

  this.service.SendFeesReceipt(this.receipt).subscribe((resp) => {
if(resp == "Success"){}});


                            this.router.navigate(["/Payment-Receipt"], {
                              queryParams: {
                                FullName: this.application_cum.Name,
                                Course_Name: this.application_cum.CourseName,
                                Fees: this.application_cum.Fees,
                                Mobile: this.application_cum.Mobile,
                                EMail: this.application_cum.Email,
                                Class: this.application_cum.Class,
                                TransId: this.TransId,
                              },
                            });
                            this.isTimeout = true;
                          } else {
                            alert("Your Payment is failed ");
                            this.isTimeout = true;
                          }
                        }
                      });
                    }.bind(this),
                    waitms
                  );
                  i++;
                }
              }
            });
        } else {
          //this.isLoading = false
          //this.button = 'Submit'
          // window.location.reload();
        }
      });

    //  setTimeout(() => {
    //     this.isLoading = false;
    //     this.button = "Submit";
    //     alert("Done loading");
    //   }, 10000);
  }
  private delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  selectChangeHandler(event: any) {
    if ("Diploma In Fire And Safety" == event.target.value) {
      this.application_cum.Fees = "95000";
    }
    if (
      "Certificate Program of Construction Supervisor" == event.target.value
    ) {
      this.application_cum.Fees = "40000";
    }
    if (
      "Diploma In Hotel Management And Catering Technology" ==
      event.target.value
    ) {
      this.application_cum.Fees = "60000";
    }
    if (
      "B.VOC In Hotel Management And Catering Technology" == event.target.value
    ) {
      this.application_cum.Fees = "60000 per year";
    }
    if ("Diploma In Naturopathy And Yogic Science" == event.target.value) {
      this.application_cum.Fees = "60000";
    }
    if ("Bachelor In Naturopathy And Yogic Science" == event.target.value) {
      this.application_cum.Fees = "95000";
    }
    if ("Certificate Course In Yoga Teacher(CCYT)" == event.target.value) {
      this.application_cum.Fees = "15000";
    }
    if ("Diploma In Industrial Safety" == event.target.value) {
      this.application_cum.Fees = "70000";
    }

    if (
      "Diploma In General Nursing Midwifery Assistant" == event.target.value
    ) {
      this.application_cum.Fees = "25000";
    }
    if ("Diploma In Medical Lab Technician" == event.target.value) {
      this.application_cum.Fees = "40000";
    }
    if ("Diploma In Operation Theatre Technician" == event.target.value) {
      this.application_cum.Fees = "25000";
    }
    if ("B Voc in Patient care Management" == event.target.value) {
      this.application_cum.Fees = "60000";
    }
    if ("B Voc in Medical Lab Technology" == event.target.value) {
      this.application_cum.Fees = "60000";
    }
    if ("Diploma In Sanitary Inspector" == event.target.value) {
      this.application_cum.Fees = "30000";
    }
    if ("Diploma In Hospital Management" == event.target.value) {
      this.application_cum.Fees = "40000";
    }
    if ("Diploma In Electrician" == event.target.value) {
      this.application_cum.Fees = "40000";
    }
  }
  UploadResume(formData) {
    //alert(2);
    this.http
      .post(
        GlobalVariable.SERVICE_API_URL +
          "Student/UploadDocs?applicationID=" +
          this.application_cum.Aadhar,
        formData,
        { reportProgress: true, observe: "events" }
      )
      .subscribe((event) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progress = Math.round((100 * event.loaded) / event.total);
          alert(this.progress);
        } else if (event.type === HttpEventType.Response) {
          alert("Upload success");
          // this.onUploadFinished.emit(event.body);
        }
      });
  }
  save(): void {
    this.loading = true;
  }

  onSubmit1() {}

  constructor(private http: HttpClient, private router: Router,
    private service: CommonserviceService,) {
    this.application_cum = new Application_cum();
    this.education = new Education();
    this.application_cum.EducationDetails = [];
    this.receipt = new Receipt();

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
      }),
    };

    this.http.get<any>(GlobalVariable.SERVICE_API_URL + "Course/GetAllCourse",httpOptions).subscribe((resp) => {
        this.CourseList = [];
        for (let Course of resp) {
          this.CourseList.push(Course);

          // console.log(this.CourseList);
        }
      });
      
    this.http.get<any>(GlobalVariable.SERVICE_API_URL + "City/GetAllCity", httpOptions).subscribe((resp) => {
        this.CityList = [];
        for (let city of resp) {
          this.CityList.push(city.CityName);
        }
      });

    this.http.get<any>(GlobalVariable.SERVICE_API_URL + "City/GetAllCity", httpOptions).subscribe((resp) => {
        this.DistrictList = [];
        for (let district of resp) {
          this.DistrictList.push(district.CityName);
        }
      });

    this.http
      .get<any>(
        GlobalVariable.SERVICE_API_URL + "State/GetAllState",httpOptions).subscribe((resp) => {
        this.StateList = [];
        for (let state of resp) {
          this.StateList.push(state.StateName);
        }
      });
  }

  AddDetails() {
    let educationDetails = this.application_cum.EducationDetails.filter(
      (item) => item.Examination == this.education.Examination
    );
    if (educationDetails.length > 0) {
      alert("You have already added this examination details.");
      return;
    }
    let education = new Education();
    education.Board = this.education.Board;
    education.Year = this.education.Year;
    education.Examination = this.education.Examination;
    education.Marks = this.education.Marks;
    education.Subject = this.education.Subject;
    this.application_cum.EducationDetails.push(education);
  }

  Delete(Examination) {
    // alert(Examination + " " + this.application_cum.EducationDetails.length);
    this.application_cum.EducationDetails =
      this.application_cum.EducationDetails.filter(
        (item) => item.Examination != Examination
      );
    // alert(Examination + " " + this.application_cum.EducationDetails.length);
    alert("Deleted Successfully");
  }

  ngOnInit() {
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    this.CityList = [];
    this.DistrictList = [];
    this.StateList = [];
    this.CourseList = [];
    
  }
   
  OnlinePayment(studentid, CourseId) {
    // alert(1)
    //this.application_cum.Fees = "5";
    var clientType = "Student";
    return this.http.get<any>(
      GlobalVariable.SERVICE_API_URL +
        "Transaction/GetTransferFundURLback?TransactionAmount=" +
        this.application_cum.Fees +
        "&centerid=" +
        studentid +
        "&clientType=" +
        clientType +
        "&Courseid=" +
        CourseId,
      this.httpOptions
    );
  }

  GetPaymentStatus(studentid) {
    return this.http.get<any>(
      GlobalVariable.SERVICE_API_URL +
        "Transaction/GetTransactionStatusById?Id=" +
        studentid,
      this.httpOptions
    );
  }

  fileChangeEvent(fileInput: any) {
    //Clear Uploaded Files result message

    this.uploadResult = "";
    this.filesToUpload = <Array<File>>fileInput.target.files;

    for (let i = 0; i < this.filesToUpload.length; i++) {
      this.selectedFileNames.push(this.filesToUpload);
    }
  }

  // onSubmit() {
  //   alert('Searching...');
  //   if (this.BrandList.BrandName === null || this.VehicleList.Name === null || this.CityList.CityName === null || this.SearchBikes.Year === null || this.SearchBikes.Minbudget === null ||this.SearchBikes.Maxbudget === null) {
  //     alert('Please fill all fields');
  //   }
  //   else{
  //     alert(this.selectedBrand+' '+this.selectedModel+' '+this.selectedLocation+' '+this.SearchBikes.Year+' '+this.SearchBikes.Minbudget+'-'+this.SearchBikes.Maxbudget);
  //     this.router.navigate(['search-bikes'],{queryParams : {Brand_Id: this.selectedBrand,Model_Name: this.selectedModel,City_Id: this.selectedLocation,Year: this.SearchBikes.Year,MiniBudget: this.SearchBikes.Minbudget,MaxiBudget: this.SearchBikes.Maxbudget}});
  //     // this.router.navigate( ['authenticate'], { queryParams: { jwt: '123'}});
  //   }
  // }
}
