import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-placement',
  templateUrl: './placement.component.html',
  styleUrls: ['./placement.component.css']
})
export class PlacementComponent implements OnInit {


  ngOnInit() {

  }

}
