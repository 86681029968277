<div class="container">
    <div class="header">
        <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-4"></div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12" style="text-align: left;">
                <h2 style="color: #336699; margin-top:38px;text-align: center;"> OUR AFFILIATIONS</h2>
                <!-- <hr class="title-hr"> -->
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4"></div>
        </div>
    </div>
    <!-- <div class="panel">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <ul>
                    <li>Best Infrastructure</li>
                    <li>Well Maintained Library</li>
                    <li>Computer Lab with 24/7 Wifi</li>
                    <li>Full Equipped Workshop & Lab</li>
                </ul>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <ul>
                    <li>High Qualified Teachers & Trainers</li>
                    <li>Military Base Training </li>
                    <li>CCTV Surveillance</li>
                    <li>In Campus Hostel with Mess Facility</li>
                </ul>
            </div>
        </div>
    </div>
    <br> -->
    <div class="panel">
        <div class="row text-center">
            <div class="panel">
               
                <ul type="disc">
                    <li>Maharashtra State Board of Skill Developement Examination.</li>
                    <li>National Skill Developement Corporation Government of India.</li>
                    <li>MSME-Technology Developement Center.</li>
                    <li>Kaushalya Vikas Board, Pune.</li>
                    <li>SKP-Ravindranath Tagore University, Madhya Pradesh.</li>
                </ul>
      
            </div>

</div>

<div class="container">

    <div class="carousel-wrap">
        <div class="owl-carousel owl-courses owl-theme" >
              
            <div class="item" >
                <img style="height: 190px; width: 56%;"  src="../../assets/images/msbvee.jpg" />
                <h3 class="title">Maharashtra State Board of Skill Developement Examination.</h3>
       
            </div>
            <div class="item" >
                <img style="height: 190px; width: 56%;"  src="../../assets/images/nsdc.jpg" />
                <h3 class="title">National Skill Developement Corporation Government of India.</h3>
       
            </div>
            <div class="item">
                <img style="height: 190px; width: 56%;"   src="../../assets/images/msme.jpg" />
                <h3 class="title">MSME-Technology Developement Center.</h3>
               
            </div>
            <div class="item">
                <img style="height: 190px; width: 56%;"  src="../../assets/images/skil-dev.jpg" />
                <h3 class="title">Kaushalya Vikas Board, Pune.</h3>
               
            </div>
            <div class="item">
                <img style="height: 190px; width: 56%;"   src="../../assets/images/Rtagoreu (1).jpg" />
                <h3 class="title">SKP-Ravindranath Tagore University, Madhya Pradesh.</h3>
               
            </div>
        </div>
    </div>

</div>

</div>


<!-- <div class="panel">
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
            Maharashtra State Board of Skill Developement Examination.
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
            National Skill Developement Corporation Government of India.   
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
            MSME-Technology Developement Center.
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
            Kaushalya Vikas Board, Pune.
        </div>
</div>
<div class="row" style="margin-left: 350px;">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        SKP-Ravindranath Tagore University, Madhya Pradesh.
    </div>
    </div>
</div> -->