<!-- <section class="top-section">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <h4 style="font-size: 18px;">Single Course Page</h4>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <h6 style="float: right;">Home&nbsp;/Courses&nbsp;/Single Course Page</h6>
            </div>
        </div>
    </div>
</section> -->

<section class="middle-section">
    <div class="container">
        <div class="row row-bottom">
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12"  style="margin-bottom: 30px;">
                <div class="course-image">
                    <img src="./assets/images/hotel.jpg" width="294" height="206" style="width:100%">
                </div>
                <div class="course-data">
                    <p class="category">Name : Department of Hotel Management and Catering Technology</p>
                    <hr>
                    <p>Duration : 3 Year Program</p>
                    <hr>
                    <p>Eligibility : 12th Pass</p>
                </div>
                <div class="course-button">
                    <a href="#" class="btn btn-primary btn-block" style="font-size: 14px;">Take This Course</a>
                </div>
                <br>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                <div class="container-fluid">
                    <h4 style="font-size: 19px;">Department of Hotel Management and Catering Technology</h4>
                    <hr class="title-hr">
                    <h5 style="text-align: justify;">Department of Hotel Management and Catering Technology was established under the banner of the Sahyadri Institute of Vocational Training. The dept. offers a one-year Diploma and three year Bachelor programme (B.VOC) in Hotel management
                        & Catering Technology approved by the KV Board and UGC recognized University. The Department is determined to create a world-class center in hospitality education, with the technical assistance of experts and reputed personalities
                        from the industry and academics. Within a short span of time from its inception, the dept has achieved position of being one of the most sought-after Institute of Hotel Management in Maharashtra. Department is equipped with training
                        kitchens, housekeeping laboratory, front office laboratory, computer laboratory and Internet center; and conference center, and a library which ensure practical training in a professional hotel environment. Students are placed
                        at various hotels of international repute like Taj, Oberoi, Leela, ITC, Marriot, Hyatt to name a few.

                    </h5>
                    <hr>
                    <div class="container-fluid">
                        <h4 style="font-size: 20px;">Bachelor of Vocational Education.(B.VOC) Programmes</h4>
                      
                        <div id="accordion">
                            <div class="card">
                                <div class="card-header">
                                    <a class="card-link" data-toggle="collapse" href="#collapseOne" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
                                        <h4 style="font-size: 18px;">B.VOC. In Hotel Management and Catering Technology</h4>
                                    </a>
                                </div>
                                <!-- <div id="collapseOne" class="collapse" data-parent="#accordion" id="collapseExample" [ngbCollapse]="isCollapsed"> -->
                                <div id="collapseExample" [ngbCollapse]="isCollapsed">
                                    <div class="card-body">
                                        <h5 style="text-align: justify;">The Department of Hotel Management & Catering Technology presently offers a three-year (6 Semester) Bachelor's Degree in Hotel Management & Catering Technology (B.Voc). This programme is approved by recognized University.
                                            The program lays emphasis on practical application of theoretical lessons through training, laboratory work, hands on workshops, theme parties, projects and study tours. To have a real life experience of Industry
                                            students go on "Industrial Exposure Training" in various Star Hotels.</h5>
                                        <h5>Duration : 3 year program</h5>
                                        <h5>Eligibility : 12th Pass</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
                        <h4 style="font-size: 20px;">Diploma Programmes</h4>
                       
                        <div id="accordion">
                            <div class="card">
                                <div class="card-header">
                                    <a class="collapsed card-link" data-toggle="collapse" href="#collapseTwo" (click)="isCollapsed1 = !isCollapsed1" [attr.aria-expanded]="!isCollapsed1" aria-controls="collapseExample1">
                                        <h4 style="font-size: 18px;">Certificate Course in Hotel Management & Catering Technology</h4>
                                    </a>
                                </div>
                                <!-- <div id="collapseTwo" class="collapse" data-parent="#accordion"> -->
                                <div id="collapseExample1" [ngbCollapse]="isCollapsed1">
                                    <div class="card-body">
                                        <h5 style="text-align: justify;">The Department of Hotel Management & Catering Technology presently offers a one-year certificate course in Hotel Management & Catering Technology. This programme is approved by KV Board. The program lays emphasis
                                            on practical application of theoretical lessons through training, laboratory work, hands on workshops, theme parties, projects and study tours. To have a real life experience of Industry students go on "Industrial
                                            Exposure Training" in various Star Hotels.</h5>
                                        <h5>Approved by: NSDC</h5>
                                        <h5>Duration : 1 year program</h5>
                                        <h5>Eligibility : 10th Pass</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>