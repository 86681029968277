
<section class="middle-section">
  <div class="container">
      <div class="row row-bottom">
          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12"  style="margin-bottom: 30px;">
              <div class="course-image">
                  <img src="./assets/images/Allied-Science4.jpg" width="294" height="206" style="width:100%">
              </div>
              <!-- <div class="course-data">
                  <p class="category">Name : Department of Health and Allied Science</p>
                  <hr>
                  <p>Duration : 3 Year Program</p>
                  <hr>
                  <p>Eligibility : 12th Pass</p>
              </div>
              <div class="course-button">
                  <a href="#" class="btn btn-primary btn-block" style="font-size: 14px;">Take This Course</a>
              </div> -->
              <br>
          </div>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
              <div class="container-fluid">
                  <h4 style="font-size: 20px;">Department of Health and Allied Science</h4>
                  <hr class="title-hr">
                  <h5 style="text-align: justify;">Healthcare industry is the largest growing industry. There is a large gap between need of competent and supply of good quality healthcare professionals. Necessiates the initialization of more and more educational institutions to meet local
                    national and global demand for health care providers. Considering this Sahyadri Institute of Vocational Training (SIVT) has established department of Health and Allied Sciences with the objective of productive skilled manpower in different areas of allied health sciences for better
                    healthcare delivery in the country.
                  </h5>
                  <h5>
                   The ultimate aim of the department is to prepare and admitted students into a highly skilled human resource in the area of paramedical teaching, research and health technology that not only get good employment but could in turn contribute in creation and enhancement of such a work Force in the future.
                  </h5>
                  <hr>
                  <h4 style="font-size: 20px;">Objective</h4>
                  <h5>The major contribution of the allied health professionals is in strengthening the medical and health services by being the major assisting support system to the mainline medical and health personnel in treating the patient in disease and promoting health in society. The course structure and curricula of different academic programs and courses offered by the department have framed and designed by eminent academicians and professionals keeping in mind the present requirements and future need of the current medical services and health industry. This would enable the diploma and graduates candidates so produced to well meet the expectations and ever increasing technology oriented healthcare demands.</h5>
                  <h5>The students admitted from a range of calibre and social background receive personal attention, exposed to advance teaching and learning environment to shape into a career oriented professionals and entrepreneurs with bright future and job opportunities.  Curriculum innovation is given priority by SIVT to make the courses meet the expectations and ever increasing demands of the research oriented hospital services and health industry.</h5>
                  <hr>

                  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="box-shadow: 2px 3px 8px #bebdbd;">

                      <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->

                      <!-- No Column -->
                      <ng-container matColumnDef="no">
                          <th mat-header-cell *matHeaderCellDef> No. </th>
                          <td mat-cell *matCellDef="let element"> {{element.no}} </td>
                      </ng-container>

                      <!-- Name Column -->
                      <ng-container matColumnDef="name">
                          <th mat-header-cell *matHeaderCellDef>&nbsp; Name </th>
                          <td mat-cell *matCellDef="let element"> {{element.name}} &nbsp;</td>
                      </ng-container>

                      <ng-container matColumnDef="eligibility">
                          <th mat-header-cell *matHeaderCellDef>&nbsp; Eligibility </th>
                          <td mat-cell *matCellDef="let element"> {{element.eligibility}} &nbsp;</td>
                      </ng-container>

                      <ng-container matColumnDef="duration">
                          <th mat-header-cell *matHeaderCellDef>&nbsp; Duration </th>
                          <td mat-cell *matCellDef="let element"> {{element.duration}} &nbsp;</td>
                      </ng-container>

                      <!-- <ng-container matColumnDef="fees">
                          <th mat-header-cell *matHeaderCellDef>&nbsp; Fees </th>
                          <td mat-cell *matCellDef="let element"> {{element.fees}} </td>
                      </ng-container> -->

                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  </table>
              </div>
          </div>
      </div>
  </div>
</section>
