<!-- <section class="top-section">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <h4 style="font-size: 18px;">Single Course Page</h4>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <h6 style="float: right;">Home&nbsp;/Courses&nbsp;/Single Course Page</h6>
            </div>
        </div>
    </div>
</section> -->

<section class="middle-section">
    <div class="container">
        <div class="row row-bottom">
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                <div class="course-image">
                    <img src="./assets/images/civil.jpg" width="294" height="206" style="width:100%">
                </div>
                <div class="course-data">
                    <p class="category">Name : Department of Civil</p>
                    <hr>
                    <p class="certificate">Certificate Program in Construction Supervisor</p>
                    <hr>
                    <p>Duration : 1 Year Program</p>
                    <hr>
                    <p>Eligibility : 10th Pass</p>
                </div>
                <div class="course-button">
                    <a href="#" class="btn btn-primary btn-block" style="font-size: 14px;">Take This Course</a>
                </div>
                <br>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                <div class="container-fluid">
                    <h3 style="font-size: 20px;">Department of Civil</h3>
                    <hr class="title-hr">
                    <h5 style="text-align: justify;margin-bottom: 20px;">Department of Civil is the offering outstanding skill development in planning, organizing and controlling projects. With our rich experience of making placements in the field of Construction, the institute is providing courses that
                        are purposeful, lucrative and futuristic for aiding our students & clients to secure great future for them.
                    </h5>

                    <h3 style="font-size: 20px;">Courses Offered</h3>
                    <hr class="title-hr">
                    <div class="card">
                        <div class="card-header">
                            <a class="collapsed card-link" data-toggle="collapse" href="#collapseTwo" (click)="isCollapsed1 = !isCollapsed1" [attr.aria-expanded]="!isCollapsed1" aria-controls="collapseExample1">
                                <h4 style="font-size: 18px;">Certificate Program in Construction Supervisor</h4>
                            </a>
                        </div>
                        <!-- <div id="collapseTwo" class="collapse" data-parent="#accordion"> -->
                        <div id="collapseExample1" [ngbCollapse]="isCollapsed1">
                            <div class="card-body">
                                <h5>Duration : 1 year program</h5>
                                <h5>Eligibility : 10th Pass</h5>
                            </div>
                        </div>
                    </div>
                    <h5></h5>
                    <hr>
                    <!-- <div class="section">
                        <h4 style="font-size: 18px;">Key Features</h4>
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <h5>Best in Industry Internship Training with Stipend</h5>
                                <hr>
                                <h5>100% on placement assistance</h5>
                                <hr>
                                <h5>Government approved syllabus</h5>
                                <hr>
                                <h5>Highly qualified and experienced faculty</h5>
                                <hr>
                                <h5>Seamless integration between institution and industry</h5>
                                <hr>
                                <h5>Subject Knowledge along with on field Practical Knowledge</h5>
                                <hr>
                                <h5>On job Training with reputed company</h5>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <h5>70% Practical’s with best in class infrastructure</h5>
                                <hr>
                                <h5>All modern equipment</h5>
                                <hr>
                                <h5>In campus Hostel with Mess Facility </h5>
                                <hr>
                                <h5>Library, Computer Lab & Workshop for practical’s are available</h5>
                                <hr>
                                <h5>Full WIFI enabled Campus</h5>
                                <hr>
                                <h5>CCTV Surveillance</h5>
                                <hr>
                                <h5>Add on Lectures for English and basic computer knowledge</h5>
                            </div>
                        </div>
                    </div> -->
                    <br>
                    <fieldset class="p-2" style="border: 0.5px solid #d25b21;">

                        <legend class="scheduler-border" style="font-size: 18px;">Key Features</legend>
                        <div class="row" style="padding: 0 10px;">
                            <div class="col-lg-6 co.-md-6 col-sm-6 col-xs-12">
                                <ul class="list">
                                    <li>Best in Industry Internship Training with Stipend</li>
                                    <li>100% on placement assistance</li>
                                    <li>Government approved syllabus</li>
                                    <li>Highly qualified and experienced faculty</li>
                                    <li>Seamless integration between institution and industry</li>
                                    <li>Subject Knowledge along with on field Practical Knowledge</li>
                                    <li>On job Training with reputed company</li>
                                </ul>
                            </div>
                            <div class="col-lg-6 co.-md-6 col-sm-6 col-xs-12">
                                <ul class="list">
                                    <li>70% Practical’s with best in class infrastructure</li>
                                    <li>All modern equipment</li>
                                    <li>In campus Hostel with Mess Facility </li>
                                    <li>Library, Computer Lab & Workshop for practical’s are available</li>
                                    <li>Full WIFI enabled Campus</li>
                                    <li>CCTV Surveillance</li>
                                    <li>Add on Lectures for English and basic computer knowledge</li>
                                </ul>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
        </div>
    </div>
</section>