import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hotel-management-info',
  templateUrl: './hotel-management-info.component.html',
  styleUrls: ['./hotel-management-info.component.css']
})
export class HotelManagementInfoComponent {

  public isCollapsed = false;

  public isCollapsed1 = false;

}
