<section class="middle-section">
    <div class="container container-contact" style="padding: 20px;" id="contact">

        <div class="container">
            <div class="popular-row ">
                <h2 style="margin-top:10px"><b>Contact Details</b></h2>
            </div>
            <br>
            <p style="width: 100%;font-size: 14px;">
                <img class="contact-img" style="height: 13px;margin-top: -5px;" src="../assets/images/institute-add.svg">&nbsp;Gat no: 207, Kondhanpur Road, Near Sinhagad Sabhagrugh A/P Ranje, Tal. Bhor, Dist. Pune, Maharashtra -412205 .
            </p>
            <p style="width: 100%;font-size: 14px;">
                <img class="contact-img" style="height: 13px;margin-top: -5px;" src="../assets/images/mobile.svg">&nbsp;&nbsp;9158035367, 8275943549, 8275943581
            </p>
            <p style="width: 100%;font-size: 14px;">
                <img class="contact-img" style="height: 13px;margin-top: -5px;" src="../assets/images/city-add.svg">&nbsp;Tanishka Apartment, Flat no 201, Renuka Nagar, Lane 2, Warje, Pune-411058
            </p>
            <p style="width: 100%;font-size: 14px;">
                <img class="contact-img" style="height: 13px;margin-top: -5px;" src="../assets/images/landline.svg">&nbsp;0202979559
            </p>
            <p style="width: 100%;font-size: 14px;">
                <img class="contact-img" style="height: 13px;margin-top: -5px;" src="../assets/images/email.svg">&nbsp;admissions@sivtpune.com
            </p>
            <p style="width: 100%;font-size: 14px;">
                <img class="contact-img" style="height: 13px;margin-top: -5px;" src="../assets/images/website.svg">&nbsp;www.sivtpune.com
            </p>
        </div>
    </div>
</section>