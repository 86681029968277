<section>
    <form #userForm="ngForm"  name="form" (ngSubmit)="userForm.form.valid && onSubmit()" novalidate>
{{userForm.value | json}}
<hr>
{{userModel | json}}

        <div class="container-fluid">
            <div class="row title-row">
                <label>Address</label>
            </div>
            <div class="row h2-row">
                <h2>Address Form</h2>
            </div>
            <div class="container">
                <div class="card">
                    <div class="row card-header">
                        <label>Basic Information</label>
                    </div>
                    <br>
                    
                    <div class="row">
                      <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12">
                      </div>
                        <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                            <label>Lane1</label>
                            <input type="text" name="lane1" placeholder="Enter Your Address" class="form-control" [(ngModel)]=userModel.lane1  #lane1="ngModel" [ngClass]="{ 'is-invalid': userForm.submitted && lane1.invalid }" required minlength="3" />
                            <div *ngIf="userForm.submitted && lane1.invalid" class="invalid-feedback">
                              <div *ngIf="lane1.errors.required " class="alert alert-danger">Lane 2 is required</div>
                              <div *ngIf="lane1.errors.minlength " class="alert alert-danger">Lane 2 must be at least 3 characters long</div>
                          </div>
                        </div>
                        <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12">
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12">
                        </div>
                        <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                            <label>Lane2</label>
                            <input type="text" name="lane2" placeholder="Enter Your Address" class="form-control" [(ngModel)]=userModel.lane2  #lane2="ngModel" [ngClass]="{ 'is-invalid': userForm.submitted && lane2.invalid }" required minlength="2" />
                            <div *ngIf="userForm.submitted && lane2.invalid" class="invalid-feedback">
                              <div *ngIf="lane2.errors.required" class="alert alert-danger">Address is required</div>
                              <div *ngIf="lane2.errors.minlength " class="alert alert-danger">Address must be at least 2 characters long</div>
                            </div>
                        </div>
                        <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12">
                        </div>
                    </div>
                    <br>
                    <div class="row">
                      <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12">
                      </div>
                        <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                          <label>City</label>
                          <select type="text" name="city" placeholder="Enter City" class="form-control" [(ngModel)]=userModel.city  #city="ngModel" [ngClass]="{ 'is-invalid': userForm.submitted && city.invalid }" required>
                          <!-- <option>Select</option> -->
                          <option value="">Select</option>
                          <option *ngFor="let city of cities"> {{city}} </option>
                          </select>
                          <div *ngIf="city.invalid && userForm.submitted">
                            <div class="alert alert-danger">
                              Please Select Your City.
                          </div>
                          </div>

                        </div>
                        <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12">
                        </div>
                      </div>
                        <div class="row">
                          <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12">
                          </div>
                        <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                            <label>State</label>
                            <select type="text" name="state" placeholder="Enter State" class="form-control" [(ngModel)]=userModel.state  #state="ngModel" [ngClass]="{ 'is-invalid': userForm.submitted && state.invalid }" required minlength="2" >
                            <!-- <option>Select</option> -->
                            <option value="">Select</option>
                            <option *ngFor="let state of states"> {{state}} </option>
                            </select>
                            <div *ngIf="state.invalid && userForm.submitted">
                              <div class="alert alert-danger">
                                Please Select Your State.
                            </div>
                            </div>

                        </div>
                        <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12">
                        </div>
                    </div>
<br>
<div class="row">
  <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12">
  </div>
  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
      <label>Pincode</label>
      <input type="text" name="pincode" inputmode="numeric" pattern="[0-9]{6}" placeholder="Enter Pincode" class="form-control" [(ngModel)]=userModel.pincode  #pincode="ngModel" [ngClass]="{ 'is-invalid': userForm.submitted && pincode.invalid }" required maxlength="6" required minlength="6" onkeypress="return isNumber(event)"/>
      <div *ngIf="userForm.submitted && pincode.invalid" class="invalid-feedback">
        <div *ngIf="pincode.errors.required " class="alert alert-danger">Pincoder is required</div>
        <div *ngIf="pincode.errors.minlength " class="alert alert-danger">Pincode must be at least 6 characters long</div>
    </div>
  </div>
  <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12">
  </div>
</div>
                    <br>
                    <!-- <div class="row">
                        <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                            <label>Gender</label>
                            <br>
                            <input type="radio" name="gender" value="male" [(ngModel)]=userModel.gender   #gender="ngModel" required/>&nbsp;Male &nbsp;&nbsp;
                            <input type="radio" name="gender" value="female" [(ngModel)]=userModel.gender  #gender="ngModel"  required/>&nbsp;Female
                            <div *ngIf="gender.invalid" class="alert alert-danger">
                              Gender required.
                          </div>
                          </div>
                        <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                            <label>Date of Birth</label>
                            <br>
                            <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4">
                                <select class="form-control" name="month" [(ngModel)]=userModel.month #month="ngModel" [ngClass]="{'invalid-data': month.invalid && userForm.submitted, 'valid-data': month.valid && userForm.submitted}" required>
                                <option value="">Month</option>
                                <option *ngFor="let month of months"> {{ month }}</option>
                              </select>
                              <div *ngIf="month.invalid && userForm.submitted">
                                <div class="alert alert-danger">
                                  Please Select Month.
                              </div>
                              </div>
                            </div>
                            <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4">
                                <select class="form-control" name="day" [(ngModel)]=userModel.day #day="ngModel" [ngClass]="{'invalid-data': day.invalid && userForm.submitted, 'valid-data': day.valid && userForm.submitted}" required>
                              <option value="">Day</option>
                              <option *ngFor="let day of days"> {{ day }} </option>
                            </select>
                            <div *ngIf="day.invalid && userForm.submitted">
                              <div class="alert alert-danger">
                                Please Select Day.
                            </div>
                            </div>
                            </div>
                            <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4">
                                <select class="form-control" name="year" [(ngModel)]=userModel.year #year="ngModel" [ngClass]="{'invalid-data': year.invalid && userForm.submitted, 'valid-data': year.valid && userForm.submitted}" required>
                            <option value="">Year</option>
                            <option *ngFor="let year of years"> {{ year }} </option>
                          </select>
                          <div *ngIf="year.invalid && userForm.submitted">
                            <div class="alert alert-danger">
                              Please Select Year.
                          </div>
                          </div>
                            </div>
                        </div>
                    </div> -->
                    <!-- <br>
                    <div class="row" style="margin: 0;">
                        <label>Marital Status</label>
                        <br>
                        <input type="radio" name="maritalstatus" value="unmarried"  [(ngModel)]=userModel.maritalstatus/>&nbsp;Unmarried &nbsp;&nbsp;
                        <input type="radio" name="maritalstatus" value="widow"  [(ngModel)]=userModel.maritalstatus/>&nbsp;Widow/Widower &nbsp;&nbsp;
                        <input type="radio" name="maritalstatus" value="divorcee"  [(ngModel)]=userModel.maritalstatus/>&nbsp;Divorcee &nbsp;&nbsp;
                        <input type="radio" name="maritalstatus" value="separated"  [(ngModel)]=userModel.maritalstatus/>&nbsp;Separated
                    </div> -->
                    <br/>
                    <!-- <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <label>Religion</label>
                            <br>
                            <select class="form-control" name="religion" [(ngModel)]=userModel.religion #religion="ngModel" [ngClass]="{'invalid-data': religion.invalid && userForm.submitted, 'valid-data': religion.valid && userForm.submitted}" required>
                            <option value="">Select</option>
                            <option *ngFor="let religion of religions"> {{religion}} </option>
                            </select>

                            <div *ngIf="religion.invalid && userForm.submitted">
                              <div class="alert alert-danger">
                                Please Select Your Religion.
                            </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <label>Cast</label>
                            <br>
                            <select class="form-control" name="cast" [(ngModel)]=userModel.cast #cast="ngModel" [ngClass]="{'invalid-data': cast.invalid && userForm.submitted, 'valid-data': cast.valid && userForm.submitted}" required>
                          
                            <option value="">Select</option>
                            <option *ngFor="let cast of casts"> {{cast}} </option>
                            </select>
                            <div *ngIf="cast.invalid && userForm.submitted">
                              <div class="alert alert-danger">
                                Please Select Your Cast.
                            </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <label>Sub-Cast</label>
                            <br>
                            <select class="form-control" name="subcast" [(ngModel)]=userModel.subcast #subcast="ngModel" [ngClass]="{'invalid-data': subcast.invalid && userForm.submitted, 'valid-data': subcast.valid && userForm.submitted}" required>
                         
                            <option value="">select</option>

                            <option *ngFor="let subcast of subcasts"> {{subcast}} </option>
                            </select>
                            <div *ngIf="subcast.invalid && userForm.submitted">
                              <div class="alert alert-danger">
                                Please Select Your Sub-Cast.
                            </div>
                            </div>
                        </div>
                    </div> -->
                    <br>
                    <div class="row btn-row">
                      <button type="submit" name="submit" class="button button-secondary btn">Submit</button>
                  </div>
                </div>

                <div class="card">
                    <div class="row card-header">
                        <label>New Account Information</label>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-4 col-lg-4 col-sm-4 col-xs-6">
                            <label>Profile Created By</label>
                            <select class="form-control" name="createdby" [(ngModel)]=userModel.createdby #createdby="ngModel" [ngClass]="{'invalid-data': createdby.invalid && userForm.submitted, 'valid-data': createdby.valid && userForm.submitted}" required>
                              <option value="">Select</option>
                              <option >Self</option>
                              <option >Parents</option>
                              </select>
                              <div *ngIf="createdby.invalid && userForm.submitted">
                                <div class="alert alert-danger">
                                  Please Select Valid Option.
                              </div>
                              </div>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-4 col-lg-4 col-sm-4 col-xs-6">
                            <label>Email ID</label>
                            <br>
                            <input type="text" name="email" class="form-control" [(ngModel)]=userModel.email  #email="ngModel" [ngClass]="{ 'is-invalid': userForm.submitted && email.invalid }" required email/>
                            <div *ngIf="userForm.submitted && email.invalid" class="invalid-feedback">
                              <div *ngIf="email.errors.required" class="alert alert-danger">Email is required</div>
                              <div *ngIf="email.errors.email" class="alert alert-danger">Email is required and format should be <i>john@doe.com</i>.</div>
                            </div>
                          </div>
                    </div>
                    <br>
                    <div class="row">

                        <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                          <div class="form-group">
                          <label>Password</label>
                            <input type="text" name="password" class="form-control" [(ngModel)]=userModel.password  #password="ngModel" [ngClass]="{ 'is-invalid': userForm.submitted && password.invalid }" required minlength="6"/>
                            <div *ngIf="userForm.submitted && password.invalid" class="invalid-feedback">
                              <div *ngIf="password.errors.required" class="alert alert-danger">password is required</div>
                              <div *ngIf="password.errors.minlength" class="alert alert-danger">Password must be at least 6 characters</div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                          <div class="form-group">
                            <label>Confirm Password</label>
                            <input type="text" class="form-control" name="confirmpass" [(ngModel)]="userModel.confirmpass" #confirmpass="ngModel" [ngClass]="{ 'is-invalid': userForm.submitted && confirmpass.invalid }" required minlength="6"/>
                        <div *ngIf="userForm.submitted && confirmpass.invalid" class="invalid-feedback">
                            <div *ngIf="confirmpass.errors.required" class="alert alert-danger">Confirm Password is required</div>
                            <div *ngIf="confirmpass.errors.mustMatch" class="alert alert-danger">Passwords must match</div>
                            <div *ngIf="confirmpass.errors.minlength" class="alert alert-danger">Password must be at least 6 characters</div>
                        </div>
                        </div>
                      </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                            <label>Mobile Number</label>
                            <input type="number" name="mobNumber" class="form-control" placeholder="Enter Your Mobile No." [(ngModel)]=userModel.mobile [pattern]="mobNumberPattern" #mobNumber="ngModel" [ngClass]="{ 'is-invalid': userForm.submitted && mobNumber.invalid }" required>
                            <div *ngIf="mobNumber.errors && userForm.submitted && !isValidFormSubmitted" [ngClass] = "'error'">
                              <div *ngIf="mobNumber.errors.pattern" class="alert alert-danger">
                                Mobile number not valid.
                              </div>
                            </div>
                          </div>
                        <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                            <label>Alternate Contact Number</label>
                            <input type="number" name="altmobile" class="form-control" [(ngModel)]=userModel.altmobile [pattern]="numberPattern" #altmobile="ngModel" required/>
                            <div *ngIf="altmobile.errors && userForm.submitted && !isValidNoSubmitted" [ngClass] = "'error'">
                              <div *ngIf="altmobile.errors.pattern" class="alert alert-danger">
                                Mobile number not valid.
                              </div>
                            </div>
                          </div>
                    </div>
                    <br>
                </div>

                <div class="card">
                    <div class="row card-header">
                        <label>Socio Religious Attributes</label>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                            <label>Gothra</label>
                            <input type="text" name="gothra" class="form-control" placeholder="Please Enter Gothra" [(ngModel)]=userModel.gothra #gothra="ngModel" [ngClass]="{ 'is-invalid': userForm.submitted && gothra.invalid }" required minlength="3" />
                            <div *ngIf="userForm.submitted && gothra.invalid" class="invalid-feedback">
                              <div *ngIf="gothra.errors.required " class="alert alert-danger">Please Enter Gothra</div>
                              <div *ngIf="gothra.errors.minlength " class="alert alert-danger">Lane 2 must be at least 3 characters long</div>
                          </div>
                          </div>
                        <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                            <label>Moonsign</label>
                            <select class="form-control" name="moonsign" [(ngModel)]=userModel.moonsign #moonsign="ngModel" [ngClass]="{'invalid-data': moonsign.invalid && userForm.submitted, 'valid-data': moonsign.valid && userForm.submitted}" required>
                            <!-- <option>Select</option> -->
                            <option value="">Does not matter</option>
                            <option *ngFor="let moonsign of moonsigns"> {{moonsign}} </option>
                            </select>
                            <div *ngIf="moonsign.invalid && userForm.submitted">
                              <div class="alert alert-danger">
                                Please Select Your Moonsign.
                            </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="row" style="margin: 0;">
                        <label>Horos Match :</label>
                        <br>
                        <input type="radio" name="horos" value="No" [(ngModel)]=userModel.horos/>&nbsp;No &nbsp;&nbsp;
                        <input type="radio" name="horos" value="Yes" [(ngModel)]=userModel.horos/>&nbsp;Yes &nbsp;&nbsp;
                        <input type="radio" name="horos" value="Not Applicable" [(ngModel)]=userModel.horos/>&nbsp;Not Applicable
                    </div>
                    <br>
                    <div class="row" style="margin: 0;">
                        <label>Manglik</label>
                        <br>
                        <input type="radio" name="manglik" value="No" [(ngModel)]=userModel.manglik/>&nbsp;No &nbsp;&nbsp;
                        <input type="radio" name="manglik" value="Yes" [(ngModel)]=userModel.manglik/>&nbsp;Yes &nbsp;&nbsp;
                        <input type="radio" name="manglik" value="Not Applicable" [(ngModel)]=userModel.manglik/>&nbsp;Not Applicable
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                            <label>Place Of Birth</label>
                            <input type="text" name="birthplace" class="form-control" [(ngModel)]=userModel.birthplace/>
                        </div>
                        <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                            <label>Time of Birth</label>
                            <input type="time" class="form-control" name="birthtime" min="09:00" max="18:00" required [(ngModel)]=userModel.birthtime>
                            <!-- <input type="text" class="form-control" /> -->
                        </div>
                    </div>
                    <br>
                </div>

                <div class="card">
                    <div class="row card-header">
                        <label>Physical Attributes</label>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                            <label>Height</label>
                            <select class="form-control" name="height" [(ngModel)]=userModel.height>
                          <option value=""> - Select -</option>
                          <option value="Below 4 ft">Below 4 ft</option>
                          <option value="4ft 6in">4ft 6in</option>
                          </select>
                        </div>
                        <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                            <label>Weight</label>
                            <select class="form-control" name="weight" [(ngModel)]=userModel.weight>
                        <option>Select</option>
                        <option value=""> - Select -</option>
                        <option value="Below 41 Kg">Below 41 Kg</option>
                        <option value="41 Kg">41 Kg</option>
                        </select>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                            <label>Blood Group</label>
                            <select class="form-control" name="blood" [(ngModel)]=userModel.blood >
                      <option value=""> - Select -</option>
                      <option *ngFor="let blood of bloods"> {{blood}} </option>

                      </select>
                        </div>
                        <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                            <label>Complexion</label>
                            <select class="form-control" name="complaxion" [(ngModel)]=userModel.complaxion >
                    <option value=""> - Select -</option>
                    <option value="Medium">Medium</option>
                    <option value="fair">Fair</option>
                    <option value="vfair">Very Fair</option>
                    </select>
                        </div>
                    </div>
                    <br>
                    <div class="row" style="margin: 0;">
                        <label>Physical Status *</label>
                        <br>
                        <input type="radio" name="physicalstatus" value="normal" [(ngModel)]=userModel.physicalstatus/>&nbsp;Normal &nbsp;&nbsp;
                        <input type="radio" name="physicalstatus" value="challenged" [(ngModel)]=userModel.physicalstatus/>&nbsp;Physically challenged
                    </div>
                    <br>
                    <div class="row" style="margin: 0;">
                        <label>Body Type :</label>
                        <br>
                        <input type="radio" name="bodytype" value="Slim" [(ngModel)]=userModel.bodytype/>&nbsp;Slim &nbsp;&nbsp;
                        <input type="radio" name="bodytype" value="Average" [(ngModel)]=userModel.bodytype/>&nbsp;Average &nbsp;&nbsp;
                        <input type="radio" name="bodytype" value="Athletic" [(ngModel)]=userModel.bodytype/>&nbsp;Athletic &nbsp;&nbsp;
                        <input type="radio" name="bodytype" value="Heavy" [(ngModel)]=userModel.bodytype/>&nbsp;Heavy
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-4 col-lg-4 col-sm-4 col-xs-6">
                            <label>Diet</label>
                            <br>
                            <select class="form-control" name="diet" [(ngModel)]=userModel.diet>
                        <option>Select</option>
                        <option value="veg"> Veg</option>
                        <option value="eggetarian">Eggetarian</option>
                        <option value="non-veg">Non-veg</option>
                        </select>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4">
                            <label>Smoke</label>
                            <br>
                            <input type="radio" name="smoke" value="No" [(ngModel)]=userModel.smoke/>&nbsp;No &nbsp;&nbsp;
                            <input type="radio" name="smoke" value="Yes" [(ngModel)]=userModel.smoke/>&nbsp;Yes
                        </div>
                        <div class="col-md-4 col-lg-4 col-sm-4 col-xs-4">
                            <label>Drink</label>
                            <br>
                            <input type="radio" name="drink" value="No" [(ngModel)]=userModel.drink/>&nbsp;No &nbsp;&nbsp;
                            <input type="radio" name="drink" value="Yes" [(ngModel)]=userModel.drink/>&nbsp;Yes
                        </div>
                    </div>
                    <br>
                    <div class="row border" style="margin:0;color: #0061b4;">
                        <input type="checkbox" name="subscribe" [(ngModel)]=userModel.subscribe [ngClass]="{'invalid-data': subscribe.invalid && userForm.submitted, 'valid-data': subscribe.valid && userForm.submitted}"
                        required name="subscribe" #subscribe="ngModel" class="form-check-input"  /> I Accept the terms and Conditions
                    </div>
                    <div class="form-group">
                      <div class="alert alert-danger" *ngIf="subscribe.invalid && userForm.submitted">
                        Please Aggree with Terms & Conditions.
                      </div>
                    </div>

                    <br>
                    <div class="row btn-row">
                        <button type="submit" name="submit" class="button button-secondary btn">Submit</button>
                    </div>
                    <br>
                </div>


            </div>
        </div>
    </form>
</section>




<router-outlet></router-outlet>
