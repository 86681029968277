<!-- <section class="top-section">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <h4 style="font-size: 18px;">Single Course Page</h4>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <h6 style="float: right;">Home&nbsp;/Courses&nbsp;/Single Course Page</h6>
            </div>
        </div>
    </div>
</section> -->

<section class="middle-section">
    <div class="container">
        <div class="row row-bottom">
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12" style="margin-bottom: 30px;">
                <div class="course-image">
                    <img src="./assets/images/fireAndsafety.jpg" width="294" height="206" style="width:100%">
                </div>
                <div class="course-data">
                    <p class="category">Name : Diploma in Fire Technology</p>
                    <hr>
                    <p class="duration">Duration : 1 Year</p>
                </div>
                <div class="course-button">
                    <a href="#" class="btn btn-primary btn-block" style="font-size: 14px;">Take This Course</a>
                </div>
                <br>
                <br>
                <div class="course-image">
                    <img src="./assets/images/fireAndsafety.jpg" width="294" height="206" style="width:100%">
                </div>
                <div class="course-data">
                    <p class="category">Name : Advanced Diploma in Industrial Safety Management</p>
                    <hr>
                    <p class="duration">Duration : 1 Year</p>
                </div>
                <div class="course-button">
                    <a href="#" class="btn btn-primary btn-block" style="font-size: 14px;">Take This Course</a>
                </div>
            </div>
            
            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                <h3 style="font-size: 20px;padding-left:15px">Courses Offered</h3>
                <hr class="title-hr">
                <div class="container-fluid">
                    <div id="accordion">
                        <div class="card">
                            <div class="card-header">
                                <a class="card-link" data-toggle="collapse" href="#collapseOne" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
                                    <h4 style="font-size: 18px;">Doiploma In Fire and Industrial safety Engineering (DFISE)</h4>
                                </a>
                            </div>
                            <!-- <div id="collapseOne" class="collapse" data-parent="#accordion" id="collapseExample" [ngbCollapse]="isCollapsed"> -->
                            <div id="collapseExample" [ngbCollapse]="isCollapsed">
                                <div class="card-body">
                                    <h5>Approved by : MSBSVET (Maharashtra State Board of Skill Vocational Education and Training)</h5>
                                    <h5>Duration : 2 year program</h5>
                                    <h5>Eligibility : 12th Pass</h5>
                                    
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="card">
                            <div class="card-header">
                                <a class="card-link" data-toggle="collapse" href="#collapseOne" (click)="isCollapsed1 = !isCollapsed1" [attr.aria-expanded]="!isCollapsed1" aria-controls="collapseExample">
                                    <h4 style="font-size: 18px;">Advance Diploma In Industrial Safety (ADIS)</h4>
                                </a>
                            </div>
                            <!-- <div id="collapseOne" class="collapse" data-parent="#accordion" id="collapseExample" [ngbCollapse]="isCollapsed"> -->
                            <div id="collapseExample" [ngbCollapse]="isCollapsed1">
                                <div class="card-body">
                                    <h5>Approved by : MSBSVET (Maharashtra State Board of Skill Vocational Education and Training)</h5>
                                    <h5>Duration : 1 year program</h5>
                                    <h5>Eligibility : Diploma / B.Sc / B.E. / B.Tech</h5>
                                    
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="card">
                            <div class="card-header">
                                <a class="card-link" data-toggle="collapse" href="#collapseOne" (click)="isCollapsed2 = !isCollapsed2" [attr.aria-expanded]="!isCollapsed2" aria-controls="collapseExample">
                                    <h4 style="font-size: 18px;">Diploma In Fire and Safety</h4>
                                </a>
                            </div>
                            <!-- <div id="collapseOne" class="collapse" data-parent="#accordion" id="collapseExample" [ngbCollapse]="isCollapsed"> -->
                            <div id="collapseExample" [ngbCollapse]="isCollapsed2">
                                <div class="card-body">
                                    <h5>Approved by : MSME Technology Development Center</h5>
                                    <h5>Duration : 1 year program</h5>
                                    <h5>Eligibility : 10th Pass</h5>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <h4 style="font-size: 18px;padding: 8px;background-color: #336699;color: white;">Prospective Industries as Career Option in Fire & Safety</h4>
                    <div class="row" style="padding: 0 15px;">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 features-cols">
                            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="box-shadow: 2px 3px 8px #bebdbd;">

                                <!--- Note that these columns can be defined in any order.
                            The actual rendered columns are set as a property on the row definition" -->

                                <!-- No Column -->
                                <ng-container matColumnDef="no">
                                    <th mat-header-cell *matHeaderCellDef style="font-size: 13px;"> No. </th>
                                    <td mat-cell *matCellDef="let element" style="font-size: 13px;"> {{element.no}} </td>
                                </ng-container>

                                <!-- Name Column -->
                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef style="font-size: 13px;">&nbsp; Name </th>
                                    <td mat-cell *matCellDef="let element" style="font-size: 13px;"> {{element.name}} </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 features-cols">
                            <table mat-table [dataSource]="dataSource1" class="mat-elevation-z8" style="box-shadow: 2px 3px 8px #888888;">

                                <!--- Note that these columns can be defined in any order.
                          The actual rendered columns are set as a property on the row definition" -->

                                <!-- No Column -->
                                <ng-container matColumnDef="no">
                                    <th mat-header-cell *matHeaderCellDef style="font-size: 13px;"> No. </th>
                                    <td mat-cell *matCellDef="let element" style="font-size: 13px;"> {{element.no}} </td>
                                </ng-container>

                                <!-- Name Column -->
                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef style="font-size: 13px;">&nbsp; Name </th>
                                    <td mat-cell *matCellDef="let element" style="font-size: 13px;"> {{element.name}} </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>
                            </table>
                        </div>
                    </div>
                    <hr>
                    <!-- <div class="section">
                        <h4 style="font-size: 18px;">Key Features</h4>
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <h5>Best in Industry Internship Training with Stipend</h5>
                                <hr>
                                <h5>100% on placement assistance</h5>
                                <hr>
                                <h5>Government approved syllabus</h5>
                                <hr>
                                <h5>Highly qualified and experienced faculty</h5>
                                <hr>
                                <h5>Ex-Indian defence officer for practical’s</h5>
                                <hr>
                                <h5>Seamless integration between institution and industry</h5>
                                <hr>
                                <h5>Subject Knowledge along with on field Practical Knowledge</h5>
                                <hr>
                                <h5>On job Training with reputed company</h5>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <h5>70% Practical’s with best in class infrastructure</h5>
                                <hr>
                                <h5>All modern equipment</h5>
                                <hr>
                                <h5>In campus Hostel with Mess Facility </h5>
                                <hr>
                                <h5>Library, Computer Lab & Workshop for practical’s are available</h5>
                                <hr>
                                <h5>Full WIFI enabled Campus</h5>
                                <hr>
                                <h5>CCTV Surveillance</h5>
                                <hr>
                                <h5>Add on Lectures for English and basic computer knowledge</h5>
                            </div>
                        </div>
                    </div> -->
                    <fieldset class="p-2" style="border: 0.5px solid #d25b21;">

                        <legend class="scheduler-border" style="font-size: 18px;">Key Features</legend>
                        <div class="row" style="padding: 0 10px;">
                            <div class="col-lg-6 co.-md-6 col-sm-6 col-xs-12">
                                <ul class="list">
                                    <li>Best in Industry Internship Training with Stipend</li>
                                    <li>100% on placement assistance</li>
                                    <li>Government approved syllabus</li>
                                    <li>Highly qualified and experienced faculty</li>
                                    <li>Ex-Indian defence officer for practical’s</li>
                                    <li>Seamless integration between institution and industry</li>
                                    <li>Subject Knowledge along with on field Practical Knowledge</li>

                                </ul>
                            </div>
                            <div class="col-lg-6 co.-md-6 col-sm-6 col-xs-12">
                                <ul class="list">
                                    <li>On job Training with reputed company</li>
                                    <li>70% Practical’s with best in class infrastructure</li>
                                    <li>All modern equipment</li>
                                    <li>In campus Hostel with Mess Facility </li>
                                    <li>Library, Computer Lab & Workshop for practical’s are available</li>
                                    <li>Full WIFI enabled Campus</li>
                                    <li>CCTV Surveillance</li>
                                    <li>Add on Lectures for English and basic computer knowledge</li>
                                </ul>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
        </div>
    </div>
</section>
