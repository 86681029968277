<!-- Sidebar Area End Here -->
<div class="dashboard-content-one">
    <!-- Breadcubs Area Start Here -->
    <div class="breadcrumbs-area">
        <h3>Exam Result List </h3>
        <!-- <ul>
             <li>
                <a href="index.html">Home</a>
            </li> 
            <li>Expenses</li>
        </ul> -->
    </div>
    <!-- Breadcubs Area End Here -->
    <!-- All Subjects Area Start Here -->
    <div class="row">
        <!-- <div class="col-4-xxxl col-12">

        </div> -->
        <div class="col-12-xxxl col-12">
            <div class="card height-auto">
                <div class="card-body">
                    <div class="heading-layout1">
                        <div class="item-title">
                            <!-- <h3>All Subjects</h3> -->
                        </div>
                        <!-- <div class="dropdown">
                                        <a class="dropdown-toggle" href="#" role="button" data-toggle="dropdown"
                                            aria-expanded="false">...</a>

                                        <div class="dropdown-menu dropdown-menu-right">
                                            <a class="dropdown-item" href="#"><i
                                                    class="fas fa-times text-orange-red"></i>Close</a>
                                            <a class="dropdown-item" href="#"><i
                                                    class="fas fa-cogs text-dark-pastel-green"></i>Edit</a>
                                            <a class="dropdown-item" href="#"><i
                                                    class="fas fa-redo-alt text-orange-peel"></i>Refresh</a>
                                        </div>
                                    </div> -->

                    </div>
                    <form class="mg-b-20">
                      <div class="row gutters-8">
                          
                        <div class="col-3-xxxl col-xl-3 col-lg-3 col-12 form-group">
                            <input name="searchbyName" type="text" placeholder="Search by First Name ..." [(ngModel)]="SearchText" class="form-control">
                        </div>
                        <div class="col-1-xxxl col-xl-2 col-lg-3 col-12 form-group">
                            <button type="submit" class="fw-btn-fill btn-gradient-yellow" (click)="Serach()">SEARCH</button>
                        </div>
                        <div class="col-1-xxxl col-xl-2 col-lg-3 col-12 form-group">
                          <button type="submit" class="fw-btn-fill btn-gradient-yellow" (click)="Reset()">RESET</button>
                      </div>
                    </div>
                    </form>
                    <div class="table-responsive">
                        <table class="table display data-table text-nowrap">
                            <thead>
                                <tr>
                                    <!-- <th>
                                                    <div class="form-check">
                                                        <input type="checkbox" class="form-check-input checkAll">
                                                        <label class="form-check-label">id</label>
                                                    </div>
                                                </th> -->
                                    <!-- <th>ID</th> -->
                                    <!-- <th>Date</th> -->
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Email</th>
                                    <th>Mobile</th>
                                    <th>Marks</th>
                                    <!-- <th>Status</th>
                                    <th>Edit</th>
                                    <th>Delete</th> -->
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let obj of List">
                                <!-- <td>{{obj.Id}}</td>
                                <td>{{obj.Date}}</td> -->
                                <td>{{obj.FName}}</td>
                                <td>{{obj.LName}}</td>
                                <td>{{obj.Email}}</td>
                                <td>{{obj.Mobile}}</td>
                                <td>{{obj.correct}}</td>
                                <!-- <td>{{obj.Status}}</td> -->
                                <!-- <td (click)="Edit(obj.Id)" style="cursor: pointer;"><i class="fa fa-pencil-square-o" aria-hidden="true"></i>Edit</td>
                                <td (click)="DeleteExpense(obj.Id)" style="cursor: pointer;"><i class="fa fa-trash" aria-hidden="true" style="margin-right: 7px;"></i>Delete</td> -->
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- All Subjects Area End Here -->
    <!-- <footer class="footer-wrap-layout1">
        <div class="copyright">© Copyrights <a href="#">KVBOARD</a> 2019. All rights reserved. Designed by <a href="#">PsdBosS</a></div>
    </footer> -->
</div>
