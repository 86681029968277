import {
  Component,
  OnInit,
  ViewEncapsulation,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core'
import { CountdownComponent } from 'ngx-countdown'
import { CommonserviceService } from '../commonservice.service'
import { GlobalVariable } from 'src/app/Globals'
import { ExamResult } from '../user1'
import { Observable } from 'rxjs'
import { MatSelectModule } from '@angular/material/select'
import {
  HttpClient,
  HttpHeaders,
  HttpRequest,
  HttpEventType,
} from '@angular/common/http'
import { Data, Router } from '@angular/router';

@Component({
  selector: 'app-application-list',
  templateUrl: './application-list.component.html',
  styleUrls: ['./application-list.component.css']
})
export class ApplicationListComponent implements OnInit {
  List: any[];
  SearchText: string;
    constructor(private service: CommonserviceService, private http: HttpClient, private router: Router) {
      this.List = [];
    }
    Serach() {
      this.List = [];
      this.service.GetApplicationFormList().subscribe((resp) => {
        for (let result of resp) {
          this.List.push(result);
        }
        this.List = this.List.filter(x => x.FName.toLowerCase().includes(this.SearchText.toLowerCase()));
      });
  
    }
  
     Reset(){
      this.List = [];
      this.service.GetApplicationFormList().subscribe((resp) => {
        //console.log(resp);
        for (let result of resp) {
          this.List.push(result);
        } 
      });
    }
  
  
    ngOnInit() {
      this.service.GetApplicationFormList().subscribe((resp) => {
        console.log(resp);
        for (let result of resp) {
          this.List.push(result);
        } 
      });
  
    }
  
  }
  