<html style="height: auto;">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta http-equiv="X-UA-Compatible" content="ie=edge">
    <!-- <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css">
    <script type="text/javascript" async="" src="https://www.gstatic.com/recaptcha/releases/mhgGrlTs_PbFQOW4ejlxlxZn/recaptcha__en.js"></script>
    <script type="text/javascript" async="" src="//cdn.mouseflow.com/projects/b44341da-ab3a-4407-bc7e-95f1282969bd.js"></script>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">

    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">

    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>

    <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css">
    <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js"></script>
    <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js"></script>
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.16.0/umd/popper.min.js"></script>
    <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js"></script> -->
</head>

</html>
<div class="container-fluid p-0 navbar-brand fixed-top">
    <div class="row" style="margin:0; background-color: white; padding: 10px;">
        <div class="col-lg-7 col-md-6 col-sm-6 col-xs-12 logo">

            <!-- <img class="logo-img " src="../assets/images/3new13-10-20.png" routerLink="/home" /> -->
            <img class="logo-img " src="../assets/images/1.png" routerLink="/home" />
        </div>
        <!-- <div class="col-lg-2 col-md-2 col-sm-6 col-xs-12 text-center">
       </div> -->

       <div class="col-lg-5 col-md-4 col-sm-6 col-xs-12 top-header-right text-right">
        <h6 class="iso" style="font-weight: 500;font-size: 11px;height: 0;">ISO 9001 : 2005</h6>
        <img class="logo-img " src="../assets/images/icons2.png" routerLink="/home" />
    </div>


        <!-- <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 top-header-right text-right">
            <h6 style="font-weight: 500;font-size: 11px;height: 0;">ISO 9001 : 2005</h6>
            <img class="contact-img" style="height: 13px;margin-top: -5px;" src="../assets/images/email.svg">&nbsp;<span class="fontsize">admissions@sivtpune.com </span>&nbsp;&nbsp;
            
            <img class="contact-img" style="height: 13px;margin-top: -5px;" src="../assets/images/mobile.svg">&nbsp;<span class="fontsize">+91 9158035367</span>
        </div> -->
    </div>

    <nav class="navbar navbar-expand-lg navbar-dark bg-blue " id="navbar ">
        <!-- <strong class="navbar-brand " href="# "><strong>SIVT</strong></a> -->
        <!-- <button class="navbar-toggler " type="button " data-toggle="collapse " data-target="#navbarSupportedContent " aria-controls="navbarSupportedContent " aria-expanded="false " aria-label="Toggle navigation ">
              <span class="navbar-toggler-icon "></span>
            </button> -->
        <div style="padding: 10px; ">
            <button class="navbar-toggler " type="button " (click)="toggleNavbar() ">
                <span class="navbar-toggler-icon "></span>
              </button>
        </div>

        <div class="collapse navbar-collapse" id="navbarSupportedContent" [ngClass]="{ 'show': navbarOpen } ">
            <ul class="navbar-nav mr-auto ">
                <li class="nav-item">
                    <a class="nav-link" (click)="toggleNavbar()" routerLink="/home">Home <span class="sr-only ">(current)</span></a>
                </li>
                <li class="nav-item ">
                    <a class="nav-link " (click)="toggleNavbar()" routerLink="/about-us">About Us</a>
                </li>
                <li class="nav-item ">
                    <a class="nav-link " (click)="toggleNavbar()" routerLink="/courses">Courses</a>
                </li>
                <li class="nav-item ">
                    <a class="nav-link " (click)="toggleNavbar()" routerLink="/facilities">Facilities</a>
                </li>
                <li class="nav-item ">
                    <a class="nav-link " (click)="toggleNavbar()" routerLink="/placement">Placements</a>
                </li>
                <!-- <li class="nav-item ">
                    <a class="nav-link " (click)="toggleNavbar()" routerLink="/apply-quiz">Online Exam</a>
                </li> -->
                <!-- <li class="nav-item ">
                    <a class="nav-link " (click)="toggleNavbar()" routerLink="/Exam-Result-List">Result List</a>
                </li> -->
                <!-- <li class="nav-item ">
                    <a class="nav-link " (click)="toggleNavbar()" routerLink="/quiz">Quiz</a>
                </li> -->
                <li class="nav-item ">
                    <div class="dropdown">
                        <button class="dropbtn" style="font-size: 14px !important;">DOWNLOADS
                        <i class="fa fa-caret-down"></i>
                      </button>
                        <div class="dropdown-content">
                            <ul style="padding-left: 2px;">
                                <li><a href="../assets/PDF/SIVT Admission Form.docx" download="" (click)="toggleNavbar()">Admission Form</a></li>
                                <hr class=" hr1">
                                <li><a href="../assets/PDF/SIVT Brochure.pdf" download="" (click)="toggleNavbar()">Brochure</a></li>
                            </ul>
                        </div>
                    </div>
                </li>
                <li class="nav-item " (click)="toggleNavbar()">
                    <a class="nav-link " routerLink="/application-cum-admission">Apply Online</a>
                </li>
                <li class="nav-item " (click)="toggleNavbar()">
                    <a class="nav-link " routerLink="/affiliation-form">Affiliations</a>
                </li>
                <li class="nav-item " (click)="toggleNavbar()">
                    <a class="nav-link " routerLink="/contact-us">Contact Us</a>
                </li>
                <!-- <li class="nav-item " (click)="toggleNavbar()">
                    <a class="nav-link " routerLink="#" (click)="scroll()">Contact Us</a>
                </li> -->
                <!-- <li class="nav-item ">
              <a class="nav-link " href="# ">Opinions</a>
            </li> -->
            </ul>
            <ul class="navbar-nav nav-flex-icons ">
                <li class="nav-item ">
                    <a href="https://www.facebook.com/sivtpune" target="_blank" class="nav-link image"><i class="fa fa-facebook "></i></a>
                </li>
                <li class="nav-item ">
                    <a href="https://twitter.com/sivtpune" target="_blank" class="nav-link image"><i class="fa fa-twitter "></i></a>
                </li>
                <li class="nav-item ">
                    <a href="https://www.instagram.com/sivtpune" target="_blank" class="nav-link image"><i class="fa fa-instagram "></i></a>
                </li>
            </ul>
        </div>
    </nav>

</div>
<div class="content ">
    <router-outlet (activate)="onActivate($event)"></router-outlet>
</div>

<footer class="footer" id="footer">
    <div class="container-fluid footer-container">
        <div class="row">
            <div class="col-lg-5 col-md-5 col-sm-7 col-xs-12" style="margin-top: 10px;">
                <h2>Contact Details</h2>
                <hr class="footer-hr">
                <div class="container">
                    <p style="width: 100%;font-size: 14px;">
                        <img class="contact-img" style="height: 13px;margin-top: -5px;" src="../assets/images/institute-add.svg">&nbsp; Gat no: 207, Kondhanpur Road, Near Sinhagad Sabhagrugh A/P Ranje, Tal. Bhor, Dist. Pune, Maharashtra -412205 .
                    </p>
                    <p style="width: 100%;font-size: 14px;">
                        <img class="contact-img" style="height: 13px;margin-top: -5px;" src="../assets/images/mobile.svg">&nbsp; 9158035367, 8275943549, 8275943581
                    </p>
                    <p style="width: 100%;font-size: 14px;">
                        <img class="contact-img" style="height: 13px;margin-top: -5px;" src="../assets/images/city-add.svg">&nbsp;<label style="font-weight: 400;">City Branch Office :</label>&nbsp; Tanishka Apartment, Flat no 201, Renuka Nagar, Lane 2, Warje, Pune-411058
                    </p>
                    <p style="width: 100%;font-size: 14px;">
                        <img class="contact-img" style="height: 13px;margin-top: -5px;" src="../assets/images/landline.svg">&nbsp; 02029709559
                    </p>
                    <p style="width: 100%;font-size: 14px;">
                        <img class="contact-img" style="height: 13px;margin-top: -5px;" src="../assets/images/email.svg">&nbsp; admissions@sivtpune.com
                    </p>
                    <p style="width: 100%;font-size: 14px;">
                        <img class="contact-img" style="height: 13px;margin-top: -5px;" src="../assets/images/email.svg">&nbsp; sivtpune@gmail.com
                    </p>
                    <p style="width: 100%;font-size: 14px;">
                        <img class="contact-img" style="height: 13px;margin-top: -5px;" src="../assets/images/website.svg">&nbsp; www.sivtpune.com
                    </p>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-5 col-xs-12" style="margin-top: 10px;">
                <h2>Quick Links</h2>
                <hr class="footer-hr">
                <div class="container">
                    <p class="footer-link-p"><a class="footer-links-a" routerLink="/home">Home</a></p>
                    <p class="footer-link-p"><a class="footer-links-a" routerLink="/courses">Courses</a></p>
                    <p class="footer-link-p"><a class="footer-links-a" routerLink="/placement">Placement</a></p>
                    <p class="footer-link-p"><a class="footer-links-a" href="https://account.vedmarg.com">ERP</a></p>
                </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12" style="margin-top: 10px;">
                <h2>Quick Links</h2>
                <hr class="footer-hr">
                <p>
                    <a class="footer-pdf" href="../assets/PDF/INFORMATION BROCHURE.pdf" download=""><i class="fa fa-download"></i>  Download Brochure</a>
                </p>
                <p>
                    <a class="footer-pdf" href="../assets/PDF/SIVT Admission Form.docx" download=""><i class="fa fa-download"></i>  Download Admission Form</a>
                </p>
            </div>

        </div>
        <div class="footer-row">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12" >
            <h5 class="mini-footer" style="margin-top: 21px; color: #fff !important ; text-align: left;">© 2020 <span style="color:#d25b21">Sahyadri Institute of Vocational Training.</span> All rights reserved.</h5>
           </div>
           <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12" >
               <!-- <a href="http://cyberscriptit.com/" target="_blank"> -->
            <h5 class="mini-footer" style="color: #fff !important ; margin-top: 21px; text-align: right;">Designed & Developed by <a href="http://cyberscriptit.com/" target="_blank"><span style="color:#d25b21">CyberscriptIT solutions Pvt.Ltd.</span></a></h5>
        <!-- </a> -->
        </div>
            </div>
        </div>
    </div>
</footer>

<!-- <app-alerts></app-alerts> -->