<div class="container-fluid" style="background-color: white !important;">
    <div class="header">
        <div class="row">
            <div class="col-lg-5 col-md-5 col-sm-4"></div>
            <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12" style="text-align: left;">
                <h2 style="color: #336699;">Placements</h2>
                <hr class="title-hr">
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4"></div>
        </div>
    </div>
    <div class="panel">
        <p>
            The department has a cell looking after placement activities and management of placement schedules. The placement cell maintains a record of potential employers and contacts them on a regular basis. Past students of the department also provide referrals.
            Every year campus interviews are arranged by the placement cell.
        </p>
        <p>
            SIVT gives a platform to every student and provides them job assistance. Not only this we prepare the students from the root level and provide them:</p>
        <ul>
            <li>Interview Preparation Classes</li>
            <li>Arrange Mock Sessions</li>
            <li>Interview Questions and Answers</li>
            <li>Personality Development Classes</li>
            <li>Guest Lecture</li>
            <li>English Speaking Classes</li>
            <li>Other Miscellaneous</li>
        </ul>
        <p> With all above given guidelines students set their goals and become more confident for their interviews and get a good opportunity everywhere above their expectations. SIVT first and last goal is to set the career of students and for this our
            placement cell strives a lot and gives the best opportunity to every student.
        </p>
        <p>
            Students of SIVT have to undergo full-time regular classes, compulsory different industrial exposure and mandatory part of regular practical training program in our training ground, and on job training with all fire & safety equipments, fire engine vehicle,
            advance techniques and equipments, trained by experienced industrial employees where students of every centre get trained for each and every Fire & Safety measures of different Industries , Constructions sites, Airports, Hospitals, Malls etc.
            which develop the each students skills and knowledge about the particular fields.
        </p>
        <p>After completing the courses, one can work as a plant safety manager, safety coordinator, fire protection manager in countries like UAE, Oman, Afghanistan, UK, Russia with companies like TFI international, British Petroleum, Shell Ormco Corporation,
            Petrofac, Technip, Omni Oil etc both National and International.</p>
    </div>
</div>