import { Component, OnInit } from '@angular/core'
import { OnlineExam } from '../user1'
import { Observable } from 'rxjs'
import { MatSelectModule } from '@angular/material/select'
import { GlobalVariable } from 'src/app/Globals'
import {
  HttpClient,
  HttpHeaders,
  HttpRequest,
  HttpEventType,
} from '@angular/common/http'
import { CommonserviceService } from '../commonservice.service'
import { Router } from '@angular/router';
//import sweetAlert from 'sweetalert';


@Component({
  selector: 'app-online-exam',
  templateUrl: './online-exam.component.html',
  styleUrls: ['./online-exam.component.css'],
})
export class OnlineExamComponent implements OnInit {
  model: any = {}
  onlineExam: OnlineExam
  progress: any
  loading = false
  uploadResult: any
  filesToUpload: Array<File>
  selectedFileNames: any[] = []
  // CourseList: Course[];
  CityList: any[]
  DistrictList: any[]
  StateList: any[]
  CountryList: any[]

  button = 'Submit'
  isLoading = false

  // userModel = new StudentForm('','','', '', '','','',);
  // religions = ["Hindu", "Islam", "Christian", "Buddish", "Others"];
  nationality1 = ['Indian', 'Other']
  Categories = ['Open', 'OBC', 'SC', 'NT']
  pincodes = ['411052', '411053', '411563', '411853', '531053']
  Cities = [
    'Pune',
    'Nagpur',
    'Nashik',
    'Thane',
    'Aurangabad',
    'Solapur',
    'Satara',
    'Dhule',
    'Sangali',
    'Amravati',
    'Kolhapur',
    'Latur',
    'Jalgoan',
    'Akola',
  ]
  // years = ["1994", "1995", "1996", "1997", "1998", "1999"];
  states = [
    'Maharashtra',
    'Andhra Pradesh',
    'Arunachal Pradesh',
    'Assam',
    'Bihar',
    'Chhattisgarh',
    'Goa',
    'Gujrat',
    'Haryana',
    'Himachal Pradesh',
    'Jharkhand',
    'Karnataka',
    'Kerala',
    'Madhya Pradesh',
    'Manipur',
    'Meghalaya',
    'Mizoram',
    'Nagaland',
    'Odisha',
    'Punjab',
    'Rajasthan',
    'Sikkim',
    'Tamil Nadu',
    'Telangana',
    'Tripura',
    'Uttar Pradesh',
    'Uttarakhand',
    'West Bengal',
  ]
  Districts = [
    'Pune',
    'Nagpur',
    'Nashik',
    'Thane',
    'Aurangabad',
    'Solapur',
    'Satara',
    'Dhule',
    'Sangali',
    'Amravati',
    'Kolhapur',
    'Latur',
    'Jalgoan',
    'Akola',
  ]
  Gender1 = ['Male', 'Female', 'Other']
  CourseNames = [
    'Diploma In Fire And Safety',
    'Diploma In Civil',
    'Diploma In Hotel Management And Catering Technology',
    'B.VOC In Hotel Management And Catering Technology',
    'Diploma In Naturopathy And Yogic Science',
    'Bachelor In Naturopathy And Yogic Science',
    'Diploma In Yoga Teacher',
    'Diploma In Industrial Safety',
  ]
  classes = ['1st Year', '2nd Year', '3rd Year']

  Examinations = ['10th', '12th', 'Degree']

  MobileNumberPattern = '^((\\+91-?)|0)?[0-9]{10}$'
  isValidFormSubmitted = false

  numberPattern = '^((\\+91-?)|0)?[0-9]{10}$'
  isValidNoSubmitted = false

  // isValidFormSubmitted = true;
  // emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  EmailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'
  // MobileNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";

  save(): void {
    this.loading = true
  }

  onSubmit1() {}

  constructor(private service: CommonserviceService, private http: HttpClient, private router: Router) {
    this.onlineExam = new OnlineExam()
  }

  onSubmit() {
    this.isLoading = true
    this.button = 'Processing'
      //console.log(this.onlineExam);
    this.service.AddOnlineExamForm(this.onlineExam).subscribe((resp) => {
      if (resp == 'Failed') {
        alert('Something went wrong! Please try again.')
       
      } else {
         GlobalVariable.UserExamId = resp;
         GlobalVariable.FirstName = this.onlineExam.FName;
         GlobalVariable.Adhaar = this.onlineExam.Aadhar;
         GlobalVariable.MobileNumber = this.onlineExam.Mobile;
        // alert(this.onlineExam.FName);

          // alert(resp);
          this.isLoading = false
          this.button = 'Submit'
        //  alert("Saved Successfully");
        //  sweetAlert("Hello world!");
         

         this.router.navigate(['/quiz']);
      }
    })
  }

  ngOnInit() {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    this.CityList = []
    this.DistrictList = []
    this.http
      .get<any>(GlobalVariable.SERVICE_API_URL + 'City/GetAllCity', httpOptions)
      .subscribe((resp) => {
        for (let city of resp) {
          this.CityList.push(city.CityName)
        }
        console.log(this.CityList);
      })
    this.http
      .get<any>(GlobalVariable.SERVICE_API_URL + 'City/GetAllCity', httpOptions)
      .subscribe((resp) => {
        for (let district of resp) {
          this.DistrictList.push(district.CityName)
        }
        console.log(this.DistrictList);
      })

    this.http
      .get<any>(
        GlobalVariable.SERVICE_API_URL + 'State/GetAllState',
        httpOptions,
      )
      .subscribe((resp) => {
        this.StateList = []
        for (let state of resp) {
          this.StateList.push(state.StateName)
        }
        console.log(this.StateList);
      })
  }
}
