import {
  Component,
  OnInit,
  ViewEncapsulation,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core'
import { CountdownComponent } from 'ngx-countdown'
import { CommonserviceService } from '../commonservice.service'
import { GlobalVariable } from 'src/app/Globals'
import { ExamResult } from '../user1'
import { Observable } from 'rxjs'
import { MatSelectModule } from '@angular/material/select'
import {
  HttpClient,
  HttpHeaders,
  HttpRequest,
  HttpEventType,
} from '@angular/common/http'
import { Data, Router } from '@angular/router';
@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.css'],
})
export class QuizComponent implements OnInit {
  status = 'start'
  @ViewChild('countdown', { static: false }) counter: CountdownComponent
  examResult: ExamResult
  list: any[];
  data: any[];
  finishTest() {
    console.log('count down', this.counter)
    this.status = 'finished'
    this.counter.restart()
  }

  resetTimer() {
    this.counter.restart()
  }
  flag: boolean
  questions: any
  currentIndex: number
  notAttempted: any
  answers: any
  currentQuestionSet: any
  start = false
  gameover = false
  buttonname = 'Start Exam'
  constructor(private service: CommonserviceService, private http: HttpClient, private router: Router) {
    this.examResult = new ExamResult() 
    //Creating summy questions Json dta
    this.flag = true
    this.questions = [
      {
        id: 1,
        question:
          ' When a metal is burnt, its weight is increased by 24 percent. The Equivalent weight of metal will be',
        option: [
          { optionid: 1, name: '2' },
          { optionid: 2, name: '24' },
          { optionid: 3, name: '33.3' },
          { optionid: 4, name: '76' },
        ],
        answer: 3,
        selected: 0,
      },
      {
        id: 2,
        question: 'The addition of a catalyst to the reaction system',
        option: [
          {
            optionid: 1,
            name: 'Increases the rate of forward reaction only',
          },
          { optionid: 2, name: 'Increases the rate of reverse reaction only' },
          {
            optionid: 3,
            name:
              'Increases the rate of forward but decreases the rate of backward reaction',
          },
          {
            optionid: 4,
            name:
              'Increases the rate of forward as well as backward reaction equally',
          },
        ],
        answer: 4,
        selected: 0,
      },
      {
        id: 3,
        question: 'The number of isomers of the compound C2BrFClI is ?',
        option: [
          { optionid: 1, name: '3' },
          { optionid: 2, name: '4' },
          { optionid: 3, name: '5' },
          { optionid: 4, name: '6' },
        ],
        answer: 4,
        selected: 0,
      },
      {
        id: 4,
        question:
          'Which of the following elements has highest electro positivity',
        option: [
          { optionid: 1, name: 'copper' },
          { optionid: 2, name: 'caesium' },
          { optionid: 3, name: 'barium' },
          { optionid: 4, name: 'chromium' },
        ],
        answer: 2,
        selected: 0,
      },
      {
        id: 5,
        question: 'In a periodic table the basic character of oxides',
        option: [
          {
            optionid: 1,
            name:
              'increases from left to right and decreases from top to bottom',
          },
          {
            optionid: 2,
            name:
              'decreases from right to left and increases from top to bottom',
          },
          {
            optionid: 3,
            name:
              'decreases from left to right and increases from top to bottom',
          },
          {
            optionid: 4,
            name:
              'decreases from left to right and increases from bottom to top',
          },
        ],
        answer: 3,
        selected: 0,
      },
      {
        id: 6,
        question:
          'Under identical conditions of pressure and temperature, 2 L of gaseous mixture ( H2 and CH4 ) effuses through a hole in 5 minutes whereas 2 L of gas X of molecular mass 36 takes 10 minutes to effuse through the same hole. The mole ratio of H2 : CH4 in the mixture is',
        option: [
          { optionid: 1, name: '1:2' },
          { optionid: 2, name: '2:1' },
          { optionid: 3, name: '2:3' },
          { optionid: 4, name: '1:1' },
        ],
        answer: 4,
        selected: 0,
      },
      {
        id: 7,
        question: 'In the electrolytic refining of zinc:',
        option: [
          { optionid: 1, name: 'graphite is at the anode' },
          { optionid: 2, name: 'the impure metal is at the cathode' },
          { optionid: 3, name: 'the metal ion gets reduced at the anode' },
          { optionid: 4, name: 'acidified zinc sulphate is the electrolyte' },
        ],
        answer: 4,
        selected: 0,
      },
      {
        id: 8,
        question: 'Which gives least basic oxide ?',
        option: [
          { optionid: 1, name: 'Mg' },
          { optionid: 2, name: 'Ba' },
          { optionid: 3, name: 'Be' },
          { optionid: 4, name: 'Ra' },
        ],
        answer: 3,
        selected: 0,
      },
      {
        id: 9,
        question:
          'Which of the following oxidation states are the most characteristic for lead and silicon respectively ?',
        option: [
          { optionid: 1, name: '+2, +4' },
          { optionid: 2, name: '+4, +4' },
          { optionid: 3, name: '+2, +4' },
          { optionid: 4, name: '+4, +2' },
        ],
        answer: 1,
        selected: 0,
      },
      {
        id: 10,
        question:
          'Which of the following is not considered as an organometallic compound ?',
        option: [
          { optionid: 1, name: 'cis-Platin' },
          { optionid: 2, name: 'Ferrocene' },
          { optionid: 3, name: 'Zeise’s salt' },
          { optionid: 4, name: 'Grignard reagent' },
        ],
        answer: 1,
        selected: 0,
      },
      {
        id: 11,
        question:
          'A metal “M” forms M2O3 type oxide and MN type nitride. The atomic number of the metal could be',
        option: [
          { optionid: 1, name: '11' },
          { optionid: 2, name: '12' },
          { optionid: 3, name: '13' },
          { optionid: 4, name: '19' },
        ],
        answer: 3,
        selected: 0,
      },
      {
        id: 12,
        question:
          'The retarding potential for photoelectrons emitted when potassium having work function 0.3 eV is illuminated by light of wavelength 3300A is',
        option: [
          { optionid: 1, name: '0.68V' },
          { optionid: 2, name: '6.8V' },
          { optionid: 3, name: '0.34V' },
          { optionid: 4, name: '3.4V' },
        ],
        answer: 4,
        selected: 0,
      },
      {
        id: 13,
        question:
          'Difference in working of an amplifier and step up transformer is',
        option: [
          {
            optionid: 1,
            name:
              'amplifier increase power which is not possible with transformer',
          },
          {
            optionid: 2,
            name:
              'amplifier decreases power whereas transformer increases the power',
          },
          {
            optionid: 3,
            name:
              'amplifier keeps power constant whereas transformer decreases power',
          },
          {
            optionid: 4,
            name:
              'amplifier keeps the power constant whereas transformer increases power',
          },
        ],
        answer: 1,
        selected: 0,
      },
      {
        id: 14,
        question:
          'Which of the following is represents ‘action and reaction’ pair',
        option: [
          {
            optionid: 1,
            name:
              'gravitational force and buoyant force acting on a floating body',
          },
          {
            optionid: 2,
            name: 'gravitational force and thrust force acting on a rocket',
          },
          {
            optionid: 3,
            name:
              'gravitational force and friction force acting on a sliding body',
          },
          { optionid: 4, name: 'none of these' },
        ],
        answer: 4,
        selected: 0,
      },
      {
        id: 15,
        question:
          'To increase both the resolving power and magnifying power of a telescope',
        option: [
          {
            optionid: 1,
            name:
              'both the focal length and aperture of the objective has to be increased',
          },
          {
            optionid: 2,
            name: 'the focal length of the objective has to be increased',
          },
          {
            optionid: 3,
            name: 'the aperture of the objective has to be increased',
          },
          { optionid: 4, name: 'the wavelength of light has to be decreased' },
        ],
        answer: 1,
        selected: 0,
      },
      {
        id: 16,
        question:
          'Two plane mirrors are placed perpendicular to each other. A ray strikes one mirror and after reflection falls on the second mirror. The ray after reflection from the second mirror will be',
        option: [
          { optionid: 1, name: 'perpendicular to the original ray' },
          { optionid: 2, name: 'parallel to the original ray' },
          { optionid: 3, name: 'at  45 degree to the original ray' },
          { optionid: 4, name: 'can be at any angle to the original ray' },
        ],
        answer: 2,
        selected: 0,
      },
      {
        id: 17,
        question:
          'In a nuclear reactor 0.01 mg of a fissile material is totally converted into energy in one second. The power of the reactor in MW is',
        option: [
          { optionid: 1, name: '1000' },
          { optionid: 2, name: '900' },
          { optionid: 3, name: '0.01' },
          { optionid: 4, name: '100' },
        ],
        answer: 2,
        selected: 0,
      },
      {
        id: 18,
        question:
          'Water rises up to a height h in a capillary tube of certain diameter. When this is replaced by a tube of half the diameter, then water will rise to a height of',
        option: [
          { optionid: 1, name: '4h' },
          { optionid: 2, name: '2h' },
          { optionid: 3, name: '3h' },
          { optionid: 4, name: 'h' },
        ],
        answer: 2,
        selected: 0,
      },
      {
        id: 19,
        question: 'A small drop of oil spreads over water because',
        option: [
          { optionid: 1, name: 'oil has a higher surface tension' },
          { optionid: 2, name: 'water has a higher surface tension' },
          { optionid: 3, name: 'oil has a higher viscocity' },
          { optionid: 4, name: 'water has a higher viscocity' },
        ],
        answer: 2,
        selected: 0,
      },
      {
        id: 20,
        question:
          'The materials for the construction of electro magnets should have',
        option: [
          { optionid: 1, name: 'high initial permeability' },
          { optionid: 2, name: 'low initial permeability' },
          { optionid: 3, name: 'large area of hysteresis loop' },
          { optionid: 4, name: 'high coercivity' },
        ],
        answer: 1,
        selected: 0,
      },
      {
        id: 21,
        question: 'Long distance short-wave radio broad-casting uses.',
        option: [
          { optionid: 1, name: 'ground wave' },
          { optionid: 2, name: 'space wave' },
          { optionid: 3, name: 'direct wave' },
          { optionid: 4, name: 'sky wave' },
        ],
        answer: 4,
        selected: 0,
      },
      {
        id: 22,
        question:
          'In a compound microscope, the image between objective and eye piece may be',
        option: [
          { optionid: 1, name: 'virtual, erect and magnified' },
          { optionid: 2, name: 'real, erect and magnified' },
          { optionid: 3, name: 'real,  inverted and magnified' },
          { optionid: 4, name: 'virtual, erected and diminished' },
        ],
        answer: 3,
        selected: 0,
      },
      {
        id: 23,
        question:
          'Twenty seven identical drops of mercury are charged simultaneously to the same potential of 10 units. Assuming the drops are made to combine to form one large drop, then its potential is',
        option: [
          { optionid: 1, name: '45 units' },
          { optionid: 2, name: '135 units' },
          { optionid: 3, name: '270 units' },
          { optionid: 4, name: '90 units' },
        ],
        answer: 4,
        selected: 0,
      },
      {
        id: 24,
        question:
          'Assertion (A): When a guitar string is plucked, the frequency of oscillations of plucked string will not be same as the wave produced in air.Reason(B): The speed of wave depends on medium in which they are propagating.',
        option: [
          { optionid: 1, name: 'both Assertion A and Reason B are correct' },
          { optionid: 2, name: 'only Assertion A is correct' },
          { optionid: 3, name: 'only Reason B is correct' },
          {
            optionid: 4,
            name: 'neither Assertion A nor Reason B are correct',
          },
        ],
        answer: 3,
        selected: 0,
      },
      {
        id: 25,
        question:
          'A rigid disc rolls without slipping on a fixed rough horizontal surface with uniform angular velocity. If the acceleration of lowest point on the disc a  and the velocity  of the lowest point on the disc is v, then',
        option: [
          { optionid: 1, name: 'a = 0, v = 0' },
          { optionid: 2, name: 'a = 0, v = 0' },
          { optionid: 3, name: 'a != 0, v != 0' },
          { optionid: 4, name: 'a != 0, v != 0' },
        ],
        answer: 3,
        selected: 0,
      },
      {
        id: 26,
        question: 'Diffusion pressure is directly proportional to:',
        option: [
          { optionid: 1, name: 'concentration of molecules diffusing' },
          { optionid: 2, name: 'kinetic energy of diffusing molecules' },
          { optionid: 3, name: 'concentration gradient' },
          { optionid: 4, name: 'all of the above' },
        ],
        answer: 4,
        selected: 0,
      },
      {
        id: 27,
        question:
          'What happens when a formalin preserved filament of Spirogyra is placed in a hypertonic sugar solution?',
        option: [
          { optionid: 1, name: 'it losses turgidity' },
          { optionid: 2, name: 'it gains turgidity' },
          { optionid: 3, name: 'it is plasmolysed' },
          { optionid: 4, name: 'nothing happens' },
        ],
        answer: 4,
        selected: 0,
      },
      {
        id: 28,
        question: 'Nif genes occur in',
        option: [
          { optionid: 1, name: 'rhizobium' },
          { optionid: 2, name: 'Aspergillus' },
          { optionid: 3, name: 'Pencillium' },
          { optionid: 4, name: 'Steptococcus' },
        ],
        answer: 1,
        selected: 0,
      },
      {
        id: 29,
        question:
          'Which of the following statements about absorption spectrum is correct?',
        option: [
          {
            optionid: 1,
            name:
              'In blue region peak of Chl-b forms at lower wavelength than peak of Chl - 1',
          },
          {
            optionid: 2,
            name:
              'In red region height of peak of Chl – a is more than that of Chl - b',
          },
          {
            optionid: 3,
            name:
              'In blue region height of peak of Chl – a is more than that of Chl - b',
          },
          {
            optionid: 4,
            name:
              'In red region peak of Chl – a forms at lower wavelength than that Chl – b',
          },
        ],
        answer: 2,
        selected: 0,
      },
      {
        id: 30,
        question:
          'The enzyme that catalyses phosphorylation of the substrate without ATP molecule is',
        option: [
          { optionid: 1, name: 'Glyceraldehyde 3- phosphate dehydrogenase' },
          { optionid: 2, name: 'Glucose 6- phosphor-trans-ferase' },
          { optionid: 3, name: 'Phosphofructokinase' },
          { optionid: 4, name: 'Pyruvatedikinase' },
        ],
        answer: 1,
        selected: 0,
      },
      {
        id: 31,
        question: 'Gibberellin induces flowering in',
        option: [
          { optionid: 1, name: 'some plants only' },
          {
            optionid: 2,
            name: 'in long day plants under short day conditions',
          },
          {
            optionid: 3,
            name: 'in short day plants under long day conditions',
          },
          { optionid: 4, name: 'day neutral plants' },
        ],
        answer: 2,
        selected: 0,
      },
      {
        id: 32,
        question: 'Rotenone, a natural insecticide, is obtained from',
        option: [
          { optionid: 1, name: 'Azadirachta indica' },
          { optionid: 2, name: 'Derris sp' },
          { optionid: 3, name: 'Bacillus thuringiensis' },
          { optionid: 4, name: 'Phytophthora palmivora ' },
        ],
        answer: 2,
        selected: 0,
      },
      {
        id: 33,
        question: 'Major sources of Biofertilizers are',
        option: [
          { optionid: 1, name: 'selected symbiotic micro – organisms' },
          { optionid: 2, name: 'only nitrogen fixing bacteria' },
          { optionid: 3, name: 'only nitrogen fixing cyanobacteria' },
          { optionid: 4, name: 'bacteria, cyanobacteria and fungi ' },
        ],
        answer: 4,
        selected: 0,
      },
      {
        id: 34,
        question: 'Which enzyme is used in the polymerase chain reaction ?',
        option: [
          { optionid: 1, name: 'Restriction enzymes' },
          { optionid: 2, name: 'Reverse transcriptase' },
          { optionid: 3, name: 'Ligase' },
          { optionid: 4, name: 'DNA polymerase.' },
        ],
        answer: 4,
        selected: 0,
      },
      {
        id: 35,
        question: 'Bond between the following is an ester bond',
        option: [
          { optionid: 1, name: 'Sugar and Phosphate' },
          { optionid: 2, name: 'Sugar and N2 base' },
          { optionid: 3, name: 'Nucleotides of opposite strands' },
          { optionid: 4, name: 'N2 base and phosphate ' },
        ],
        answer: 2,
        selected: 0,
      },
      {
        id: 36,
        question: 'Nucleic acids can be fragmented by',
        option: [
          { optionid: 1, name: 'Polymerases' },
          { optionid: 2, name: 'Nucleases' },
          { optionid: 3, name: 'Proteases' },
          { optionid: 4, name: 'Ligases' },
        ],
        answer: 2,
        selected: 0,
      },
      {
        id: 37,
        question:
          'm – RNA chain has 66 nitrogen bases. The last three are UAG. What will be the number of functional codons and aminoacids in the polynucleotide chain',
        option: [
          { optionid: 1, name: '22 – 21' },
          { optionid: 2, name: '21 – 22' },
          { optionid: 3, name: '22 – 22' },
          { optionid: 4, name: '21 – 21' },
        ],
        answer: 4,
        selected: 0,
      },
      {
        id: 38,
        question: 'Nawaschin discovered triple fusion in',
        option: [
          { optionid: 1, name: 'Allium and scilla' },
          { optionid: 2, name: 'Allium and Lilium' },
          { optionid: 3, name: 'Lilium and Fritillaria' },
          { optionid: 4, name: 'Fritillaria and colchicum ' },
        ],
        answer: 3,
        selected: 0,
      },
      {
        id: 39,
        question: 'Wrong match among the following',
        option: [
          { optionid: 1, name: 'Arachis – Oil and fodder' },
          { optionid: 2, name: 'Crotalaria – fibre and fodder' },
          { optionid: 3, name: 'Trigonella – leaf vegetable and medicinal' },
          { optionid: 4, name: 'Pterocarpus – Fodder and green manure ' },
        ],
        answer: 4,
        selected: 0,
      },
      {
        id: 40,
        question:
          'It is justified that potato tuber is an underground stem because',
        option: [
          { optionid: 1, name: 'It stores reserve food' },
          { optionid: 2, name: 'It possesses axillary buds' },
          { optionid: 3, name: 'It does not bear roots' },
          { optionid: 4, name: 'It possesses chlorophyll ' },
        ],
        answer: 2,
        selected: 0,
      },
      {
        id: 41,
        question:
          'Identify the incorrect statement regarding vascular bundles of monocot stem',
        option: [
          {
            optionid: 1,
            name:
              'All vascular bundles in ground tissue are scattered irregularly',
          },
          {
            optionid: 2,
            name: 'In a vascular bundle xylem, phloem separated by cambium',
          },
          { optionid: 3, name: '‘Y’ shaped arrangement of xylem strands' },
          { optionid: 4, name: 'Vascular bundle covered by bundle sheath' },
        ],
        answer: 2,
        selected: 0,
      },
      {
        id: 42,
        question: 'Heart wood and sap wood are formed due to',
        option: [
          { optionid: 1, name: 'Climatic changes which occur periodically' },
          {
            optionid: 2,
            name:
              'Variation in water requirement of the tree with seasonal changes',
          },
          {
            optionid: 3,
            name:
              'Variation in water requirement of the tree in spring and Autum',
          },
          {
            optionid: 4,
            name:
              'Gradual non-functioning of the wood progressively due to production of functional wood from the cambium outwards',
          },
        ],
        answer: 4,
        selected: 0,
      },
      {
        id: 43,
        question: 'Vein ending, epithem cavity and water stoma are the part of',
        option: [
          { optionid: 1, name: 'Stomata' },
          { optionid: 2, name: 'Hydathode' },
          { optionid: 3, name: 'Osmophores' },
          { optionid: 4, name: 'Pneumathode ' },
        ],
        answer: 2,
        selected: 0,
      },
      {
        id: 44,
        question: 'Identify the incorrect statement regarding Tyloses',
        option: [
          {
            optionid: 1,
            name: 'obstruct conduction of water in old and injured vessels',
          },
          {
            optionid: 2,
            name: 'balloon shaped structure formed from xylem parenchyma',
          },
          { optionid: 3, name: 'initially thick walled, later disappear' },
          { optionid: 4, name: 'initially thick walled, later disappear' },
        ],
        answer: 4,
        selected: 0,
      },
      {
        id: 45,
        question: 'The two chromatids of a metaphase chromosome represent',
        option: [
          {
            optionid: 1,
            name: 'Replicated chromosomes to be separated at anaphase',
          },
          { optionid: 2, name: 'Homologous chromosomes of a diploid set' },
          {
            optionid: 3,
            name: 'Non – homologous chromosomes joined at the centromere',
          },
          {
            optionid: 4,
            name: 'Maternal and paternal chromosomes joined at the centromere ',
          },
        ],
        answer: 1,
        selected: 0,
      },
      {
        id: 46,
        question:
          'The part of chromatid / arm beyond secondary constriction is called',
        option: [
          { optionid: 1, name: 'Satellite' },
          { optionid: 2, name: 'Centromere or Kinetochore' },
          { optionid: 3, name: 'Nucleolar organizer' },
          { optionid: 4, name: 'Balbiani ring ' },
        ],
        answer: 1,
        selected: 0,
      },
      {
        id: 47,
        question: 'Dichogamy favours cross pollination because',
        option: [
          {
            optionid: 1,
            name: 'Anthers and stigmas are placed at different levels',
          },
          {
            optionid: 2,
            name: 'Stamens and stigma mature at different times',
          },
          { optionid: 3, name: 'Pollen is unable to germinate' },
          { optionid: 4, name: 'Structure of stigma acts as a barrier ' },
        ],
        answer: 2,
        selected: 0,
      },
      {
        id: 48,
        question: 'Some diseases caused by bacteria are:',
        option: [
          { optionid: 1, name: 'AIDS, hydrophobia, pneumonia, smallpox' },
          { optionid: 2, name: 'typhoid, tuberculosis, pneumonia, tetanus' },
          {
            optionid: 3,
            name: 'polio, hepatitis, scurvy, beri-beri, leprosy',
          },
          {
            optionid: 4,
            name: 'measles, mumps, malaria, sleeping sickness, syphilis',
          },
        ],
        answer: 2,
        selected: 0,
      },
      {
        id: 49,
        question:
          'Jacob and Monad studied lactose metabolism in E . Coli and proposed operon concept. Operon concept is applicable for',
        option: [
          { optionid: 1, name: 'all prokaryotes' },
          { optionid: 2, name: 'all prokaryotes and some eukaryotes' },
          { optionid: 3, name: 'all prokaryotes and all eukaryotes' },
          { optionid: 4, name: 'all prokaryotes and some protozoans ' },
        ],
        answer: 3,
        selected: 0,
      },
      {
        id: 50,
        question: 'Which is a correct match?',
        option: [
          { optionid: 1, name: 'Mycorrhiza - Saprophytism' },
          { optionid: 2, name: 'Algae and Fungi in lichens – Mutualism' },
          { optionid: 3, name: 'Orchids – Parasitism' },
          { optionid: 4, name: 'Cuscuta - Epiphytism ' },
        ],
        answer: 2,
        selected: 0,
      },
      // {
      //   id: 6,
      //   question: 'Select the smallest memory size',
      //   option: [
      //     { optionid: 1, name: 'kilobyte' },
      //     { optionid: 2, name: 'megabyte' },
      //     { optionid: 3, name: 'gigabyte' },
      //     { optionid: 4, name: 'terabyte' },
      //   ],
      //   answer: 1,
      //   selected: 0,
      // },
    ]

    this.currentIndex = 0
    this.currentQuestionSet = this.questions[this.currentIndex]
    this.list = [];
    this.examResult.UserId =  GlobalVariable.UserExamId;
    this.data = [];
    
 

  }

  next() {
    this.currentIndex = this.currentIndex + 1
    this.currentQuestionSet = this.questions[this.currentIndex]
    // alert(this.currentIndex)
  }
  prev() {
    this.currentIndex = this.currentIndex - 1
    this.currentQuestionSet = this.questions[this.currentIndex]
  }

  

  submit() {
    // this.buttonname = 'Replay'

    // this.start = true
    if (this.currentIndex + 1 == this.questions.length) {
      this.gameover = true
      this.start = false
      this.answers = 0
      this.notAttempted = 0
      this.questions.map((x) => {
        if (x.selected != 0) {
          if (x.selected == x.answer) this.answers = this.answers + 1
        } else {
          this.notAttempted = this.notAttempted + 1
        }
        x.selected = 0
      })
    }
   // alert(GlobalVariable.FirstName+''+GlobalVariable.Adhaar);
this.examResult.UserId =  GlobalVariable.UserExamId;
this.examResult.FirstName = GlobalVariable.FirstName;
this.examResult.correct = this.answers;
this.examResult.Adhaar = GlobalVariable.Adhaar;
this.examResult.MobileNumber = GlobalVariable.MobileNumber;

// this.examResult.correct = this.answers;
// this.examResult.correct = this.answers;
// this.examResult.correct = this.answers;
// this.examResult.correct = this.answers;

   //alert(this.examResult.correct);

    this.service.AddExamResult(this.examResult).subscribe((resp) => {
      if (resp == 'Failed') {
   
        alert('Something went wrong! Please try again.')
        
      } else {
        // alert('Saved Successfully')
      }
    })
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }
    this.http
      .post<ExamResult>(
        GlobalVariable.SERVICE_API_URL + 'StudentInfo/SendEmail',
        this.examResult,
        httpOptions,
      )
      .subscribe((resp) => {
          // alert('Successfully Send...');   
      })


}

    


  
  startQuiz() {
    this.gameover = false
    this.currentIndex = 0
    this.currentQuestionSet = this.questions[this.currentIndex]
    this.start = true
    // alert(this.currentIndex)
    this.flag = false
  }

  ngOnInit() {

    // this.service.GetUserScoreList().subscribe((resp) => {
    //   console.log(resp);
    //   for (let result of resp) {
    //     this.list.push(result);
    //   }
    
    // //   this.list = this.list.filter((x) => x.Id == this.examResult.UserId);
    // //   console.log(this.list);

    //  });

  }
}
