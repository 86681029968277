<!-- Sidebar Area End Here -->
<div class="dashboard-content-one">
    <!-- Breadcubs Area Start Here -->
    <div class="breadcrumbs-area">
        <h3>Apply For Online Exam</h3>
        <!-- <ul>
          <li>
              <a href="index.html">Home</a>
          </li>
          <li>Student Admit Form</li>
      </ul> -->
    </div>
    <!-- Breadcubs Area End Here -->
    <!-- Admit Form Area Start Here -->
    <div class="card height-auto">
        <div class="card-body">
            <form class="new-added-form" #userForm="ngForm" name="form" (ngSubmit)="userForm.form.valid && onSubmit()" novalidate>
                <!-- {{userForm.value | json}} -->
                <div class="row">
                    <div class="col-xl-4 col-lg-6 col-12 form-group">
                        <label> Aadhar Number *</label>
                        <input type="number" placeholder="" class="form-control" name="Aadhar" [(ngModel)]=onlineExam.Aadhar #Aadhar="ngModel" [ngClass]="{ 'is-invalid': userForm.submitted && Aadhar.invalid }" required minlength="12" onKeyPress="if(this.value.length==12) return false;">
                        <div *ngIf="userForm.submitted && Aadhar.invalid" class="invalid-feedback">
                            <div *ngIf="Aadhar.errors.required " class="alert alert-danger"> Aadhar Number is required</div>
                            <div *ngIf="Aadhar.errors.minlength " class="alert alert-danger"> Aadhar Number must be valid</div>
                        </div>
                    </div>

                    <div class="col-xl-4 col-lg-6 col-12 form-group">
                        <label> First Name *</label>
                        <input type="text" placeholder="" class="form-control" name="FName" [(ngModel)]=onlineExam.FName #FName="ngModel" [ngClass]="{ 'is-invalid': userForm.submitted && FName.invalid }" required minlength="1" onkeypress="return (event.charCode > 64 && 
                        event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)">
                        <div *ngIf="userForm.submitted && FName.invalid" class="invalid-feedback">
                            <div *ngIf="FName.errors.required " class="alert alert-danger"> Name is required</div>
                            <div *ngIf="FName.errors.minlength " class="alert alert-danger"> Name must be at least 3 characters long</div>
                        </div>
                    </div>

                    <div class="col-xl-4 col-lg-6 col-12 form-group">
                        <label>Middle Name *</label>
                        <input type="text" placeholder="" class="form-control" name="MName" [(ngModel)]=onlineExam.MName #MName="ngModel" [ngClass]="{ 'is-invalid': userForm.submitted && MName.invalid }" required minlength="1" onkeypress="return (event.charCode > 64 && 
                        event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)">
                        <div *ngIf="userForm.submitted && MName.invalid" class="invalid-feedback">
                            <div *ngIf="MName.errors.required " class="alert alert-danger">Middle Name is required</div>
                            <div *ngIf="MName.errors.minlength " class="alert alert-danger">Middle Name must be at least 3 characters long</div>
                        </div>
                    </div>

                    <div class="col-xl-4 col-lg-6 col-12 form-group">
                        <label>Last Name *</label>
                        <input type="text" placeholder="" class="form-control" name="LName" [(ngModel)]=onlineExam.LName #LName="ngModel" [ngClass]="{ 'is-invalid': userForm.submitted && LName.invalid }" required minlength="1" onkeypress="return (event.charCode > 64 && 
                        event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)">
                        <div *ngIf="userForm.submitted && LName.invalid" class="invalid-feedback">
                            <div *ngIf="LName.errors.required " class="alert alert-danger">Last Name is required</div>
                            <div *ngIf="LName.errors.minlength " class="alert alert-danger">Last Name must be at least 3 characters long</div>
                        </div>
                    </div>

                    <div class="col-xl-4 col-lg-6 col-12 form-group">
                        <label>Father Name *</label>
                        <input type="text" placeholder="" class="form-control" name="FName" [(ngModel)]=onlineExam.FatherName #FName="ngModel" [ngClass]="{ 'is-invalid': userForm.submitted && FName.invalid }" required minlength="1" onkeypress="return (event.charCode > 64 && 
                        event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)">
                        <div *ngIf="userForm.submitted && FName.invalid" class="invalid-feedback">
                            <div *ngIf="FName.errors.required " class="alert alert-danger">Father Name is required</div>
                            <div *ngIf="FName.errors.minlength " class="alert alert-danger">Father Name must be at least 3 characters long</div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-12 form-group">
                        <label>Full Address *</label>
                        <input type="text" placeholder="" class="form-control" name="Address" [(ngModel)]=onlineExam.Address #Address="ngModel" [ngClass]="{ 'is-invalid': userForm.submitted && Address.invalid }" required minlength="3">
                        <div *ngIf="userForm.submitted && Address.invalid" class="invalid-feedback">
                            <div *ngIf="Address.errors.required " class="alert alert-danger">Address is required</div>
                            <div *ngIf="Address.errors.minlength " class="alert alert-danger">Address must be at least 3 characters long</div>
                        </div>
                    </div>


                    <div class="col-xl-4 col-lg-6 col-12 form-group">
                        <label>State *</label>
                        <select class=" form-control" name="state"  [(ngModel)]=onlineExam.State #State="ngModel" [ngClass]="{'invalid-data': State.invalid && userForm.submitted, 'valid-data': State.valid && userForm.submitted}" required>
                       <option value="">Please Select state *</option>
                      <option *ngFor="let state of StateList"> {{state}} </option>
                      </select>
                        <div *ngIf="State.invalid && userForm.submitted">
                            <div class="alert alert-danger">
                                Please Select Your State.
                            </div>
                        </div>
                    </div>

                    <!-- <div class="col-xl-4 col-lg-6 col-12 form-group">
                        <label>District *</label>
                        <select class=" form-control" name="District"  [(ngModel)]=onlineExam.District #District="ngModel" [ngClass]="{'invalid-data': District.invalid && userForm.submitted, 'valid-data': District.valid && userForm.submitted}" required>
                     <option value="">Please Select District *</option>
                    <option *ngFor="let district of DistrictList"> {{district}} </option>
                    </select>
                        <div *ngIf="District.invalid && userForm.submitted">
                            <div class="alert alert-danger">
                                Please Select Your District.
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="col-xl-4 col-lg-6 col-12 form-group">
                        <label>District *</label>
                        <select class=" form-control" name="District"  [(ngModel)]=onlineExam.District #District="ngModel" [ngClass]="{'invalid-data': District.invalid && userForm.submitted, 'valid-data': District.valid && userForm.submitted}" required>
                     <option value="">Please Select District *</option>
                    <option *ngFor="let city of CityList"> {{city}} </option>
                    </select>
                        <div *ngIf="District.invalid && userForm.submitted">
                            <div class="alert alert-danger">
                                Please Select Your District.
                            </div>
                        </div>
                    </div> -->
                    

                    <div class="col-xl-4 col-lg-6 col-12 form-group">
                        <label>City *</label>
                        <select class=" form-control" name="City"  [(ngModel)]=onlineExam.City #City="ngModel" [ngClass]="{'invalid-data': City.invalid && userForm.submitted, 'valid-data': City.valid && userForm.submitted}" required>
                   <option value="">Please Select City *</option>
                  <option *ngFor="let city of CityList"> {{city}} </option>
                  </select>
                        <div *ngIf="City.invalid && userForm.submitted">
                            <div class="alert alert-danger">
                                Please Select Your City.
                            </div>
                        </div>
                    </div>

                    <!-- <div class="col-xl-4 col-lg-6 col-12 form-group">
                        <label>District *</label>
                        <select class=" form-control " name="state" id="stateSel" size="1" [(ngModel)]=onlineExam.District #District="ngModel" [ngClass]="{'invalid-data': District.invalid && userForm.submitted, 'valid-data': District.valid && userForm.submitted}" required>
<option value="" selected="selected">Select District</option>
</select>
                        <div *ngIf="District.invalid && userForm.submitted">
                            <div class="alert alert-danger">
                                Please Select Your District.
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-12 form-group">
                        <label>City *</label>
                        <select class=" form-control " name="state" id="districtSel" size="1" [(ngModel)]=onlineExam.City #City="ngModel" [ngClass]="{'invalid-data': City.invalid && userForm.submitted, 'valid-data': City.valid && userForm.submitted}" required>
<option value="" selected="selected">Select City</option>
</select>
                        <div *ngIf="City.invalid && userForm.submitted">
                            <div class="alert alert-danger">
                                Please Select Your City.
                            </div>
                        </div>
                    </div> -->

                    <div class="col-xl-4 col-lg-6 col-12 form-group">
                        <label>Pincode *</label>
                        <input type="number" name="PinCode" class="form-control" placeholder="" [(ngModel)]=onlineExam.PinCode #pincode="ngModel" [ngClass]="{ 'is-invalid': userForm.submitted && pincode.invalid }" maxlength=6 required onKeyPress="if(this.value.length==6) return false;">
                        <div *ngIf="pincode.invalid && userForm.submitted">
                            <div class="alert alert-danger">
                                Please Select Your Pincode.
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-4 col-lg-6 col-12 form-group">
                        <label>Mobile *</label>
                        <!-- <input type="text" placeholder="" class="form-control"> -->
                        <input type="number" name="MobileNumber" class="form-control" placeholder="" [(ngModel)]=onlineExam.Mobile [pattern]="MobileNumberPattern" #MobileNumber="ngModel" [ngClass]="{ 'is-invalid': userForm.submitted && MobileNumber.invalid }" maxlength="10"
                            onKeyPress="if(this.value.length==10) return false;" required>
                        <div *ngIf="MobileNumber.errors && userForm.submitted && !isValidFormSubmitted" [ngClass]="'error'">
                            <div *ngIf="MobileNumber.errors.pattern" class="alert alert-danger">
                                Mobile number not valid.
                            </div>
                        </div>
                    </div>

                    <!--   <div class="col-xl-4 col-lg-6 col-12 form-group">
                      <label>Telephone Number</label>
                      <input type="text" placeholder="" class="form-control">
                      <input type="number" name="Telephone" class="form-control" placeholder="" [(ngModel)]=onlineExam.Telephone #Telephone="ngModel" maxlength=10 required>
                      <div *ngIf="Telephone.errors && userForm.submitted && !isValidFormSubmitted" [ngClass]="'error'">
                          <div *ngIf="Telephone.errors.pattern" class="alert alert-danger">
                              Telephone number not valid.
                          </div>
                      </div>
                  </div>-->

                    <div class="col-xl-4 col-lg-6 col-12 form-group">
                        <label>E-Mail</label>
                        <!-- <input type="email" placeholder="" class="form-control"> -->
                        <input type="email" name="Email" class="form-control" [(ngModel)]=onlineExam.Email #Email="ngModel" [ngClass]="{ 'is-invalid': userForm.submitted && Email.invalid }" required email/>
                        <div *ngIf="userForm.submitted && Email.invalid" class="invalid-feedback">
                            <div *ngIf="Email.errors.required" class="alert alert-danger">Email is required</div>
                            <div *ngIf="Email.errors.Email" class="alert alert-danger">Email is required and format should be <i>john@doe.com</i>.</div>
                        </div>

                    </div>

                    <div class="col-xl-4 col-lg-6 col-12 form-group">
                        <label>Gender *</label>
                        <select class=" form-control " name="Gender" [(ngModel)]=onlineExam.Sex #Gender="ngModel" [ngClass]="{'invalid-data': Gender.invalid && userForm.submitted, 'valid-data': Gender.valid && userForm.submitted}" required>
                           <!-- <option value="">Please Select Gender *</option> -->
                          <option *ngFor="let Gender of Gender1"> {{Gender}} </option>
                          </select>

                        <div *ngIf="Gender.invalid && userForm.submitted">
                            <div class="alert alert-danger">
                                Please Select Your Gender.
                            </div>
                        </div>
                    </div>




                    

                    <div class="col-12 form-group mg-t-8">
                        <!-- <button type="submit" class="btn-fill-lg btn-gradient-yellow btn-hover-bluedark" [class.spinner]="loading" [disabled]="loading" (click)="save()">Submit</button> -->
                        <!-- <button type="submit" class="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">Submit</button> -->
                        <!-- <button class="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"  [disabled]="!userForm.form.valid"  ><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, '': !isLoading}" ></i> {{button}}</button> -->
                        <button class="btn-fill-lg btn-gradient-yellow btn-hover-bluedark" [disabled]="isLoading" [disabled]="!userForm.form.valid" (click)="onSubmit()"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, '': !isLoading}"></i> {{button}}</button>
                        <button type="reset" class="btn-fill-lg bg-blue-dark btn-hover-yellow">Reset</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <!-- Admit Form Area End Here -->
    <!-- <footer class="footer-wrap-layout1">
      <div class="copyright">© Copyrights <a href="#">KVBOARD</a> 2019. All rights reserved. Designed by <a href="#">PsdBosS</a></div>
  </footer> -->
</div>