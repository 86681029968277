
<div class="container" id="">
<!-- <section class="section" id="print"> -->
    <section class="section" id="contentToConvert">

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 logo-col">
        <button (click)="pdfpage()" id="btnprint">print</button> 
    </div>
        <!-- <button (click)="pdfpage()" id="btnprint">print</button>  -->
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 logo-col" style="margin: 0;text-align: center; margin-bottom: 9px;">
        <!-- <img src="/assets/images/cyber.png" class="logo-img" /> -->

        <br>
    
        <img src="../../assets/images/logo/logo.png" class="logo-img" />
    </div>
    <div class="row" style="margin: 0;text-align: center; display: block;">
        <!-- <div class="col-lg-4 col-md-2 col-sm-2 col-xs-12 logo-col">
        <img src="/assets/img/erp/logo.png" class="logo-img" />
        </div> -->
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 header-col">
            <p class="header" style="margin-top: 5px; margin-bottom: 10px; font-weight: 700;">SAHYADRI INSTITUTE OF VOCATIONAL TRAINING</p>
            <p class="header-title">AFFILIATED TO MAHARASHTRA STATE BOARD OF SKILL DEVELOPMENT EXAMINATION,</p>
            <p class="header-title">NATIONAL SKILL DEVELOPMENT CORPORATION GOVT. OF INDIA,</p>
            <p class="header-title">MSME - TECHNOLOGY DEVELOPMENT CENTER,</p>
            <p class="header-title">KAUSHALYA VIKAS BOARD PUNE</p>
            <!-- <p class="header-title">उ. प्र. सरकार एवं मानव संसाधन विकास मंत्रालय भारत सरकार मान्यता प्राप्त स्वायत्त संस्थान</p> -->
            <!-- <p class="header" style="margin-top: 5px; margin-bottom: 10px;">केन्द्रीय मुक्त विद्यालयी शिक्षा संस्थान</p>
            <p class="header-title">उ. प्र. सरकार एवं मानव संसाधन विकास मंत्रालय भारत सरकार मान्यता प्राप्त स्वायत्त संस्थान</p>
            <p class="header-title">महाराष्ट्र राज्य माध्यमिक व उच्च माध्यमिक शिक्षण मंडळ यांची समकक्षता प्राप्त परिक्षा बोर्ड</p>
            <p class="header-title">महाराष्ट्र प्रदेश विभागीय कार्यालय </p> -->
            <!-- <p class="header-title">मुक्त / दुरस्थ शिक्षा योजना</p> -->
            <p class="header" style="color:black;">Payment Receipt</p>
            <!-- <p>JS TODAY:: {{jstoday}}</p>  -->
        </div>
    </div>
    <div class="border">
        <br>
        <div class="row" style="margin: 0;">
            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                <div class="row" style="margin: 0;">
                    <!-- <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 label">
                        <label><i>Name :</i></label>
                    </div> -->
                    <div class="col-lg-2 col-md-2 col-sm-3 col-xs-3" style="padding: 0;">
                        <label style="color: #646464 !important;">Date :</label>
                    </div>
                    <div class="col-lg-10 col-md-10 col-sm-9 col-xs-9 input">
                        <input class="form-input" placeholder="" type="text" [(ngModel)]=this.jstoday style=" margin-left: 8px;" readonly/>
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-2 col-md-2 col-sm-2">
            </div> -->
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                <div class="row" style="margin: 0;">
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4" style="padding: 0;">
                        <label style="color: #646464 !important;">Trans. ID :</label>
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 input">
                        <!-- <input class="form-input" type="text" placeholder="" readonly/> -->
                        <input class="form-input" placeholder="" [(ngModel)]=this.getTransId type="text" style=" margin-left: 8px;" readonly/>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <div class="row" style="margin: 0;">
            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                <div class="row" style="margin: 0;">
                    <!-- <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 label">
                        <label><i>Name :</i></label>
                    </div> -->
                    <div class="col-lg-2 col-md-2 col-sm-3 col-xs-3" style="padding: 0;">
                        <label style="color: #646464 !important;">Name :</label>
                    </div>
                    <div class="col-lg-10 col-md-10 col-sm-9 col-xs-9 input">
                        <input class="form-input" placeholder="" [(ngModel)]=this.getName type="text" style=" margin-left: 8px;" readonly/>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                <!-- <div class="row" style="margin: 0;">
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4" style="padding: 0;">
                        <label style="color: #646464 !important;">Trans. ID :</label>
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 input">
                        <input class="form-input" type="text" placeholder="" readonly/>
                    </div>
                </div> -->
            </div>
        </div>
        <br>
        <div class="row" style="margin: 0;">
            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                <div class="row" style="margin: 0;">
                    <div class="col-lg-2 col-md-2 col-sm-3 col-xs-3" style="padding: 0;">
                        <label style="color: #646464 !important;">Course :</label>
                    </div>
                    <div class="col-lg-10 col-md-10 col-sm-9 col-xs-9 input">
                        <input class="form-input" type="text" placeholder="" [(ngModel)]=this.getCName style=" margin-left: 8px;" readonly/>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                <div class="row" style="margin: 0;">
                    <div class="col-lg-2 col-md-2 col-sm-3 col-xs-3" style="padding: 0;">
                        <label style="color: #646464 !important;">Year :</label>
                    </div>
                    <div class="col-lg-10 col-md-10 col-sm-9 col-xs-9 input">
                        <input class="form-input" type="text" placeholder="" [(ngModel)]=this.getCYear style=" margin-left: 8px;" readonly/>
                    </div>
                </div>
            </div>
   
        </div>

        <br>

        <div class="row" style="margin: 0;">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div class="row" style="margin: 0;">
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-3" style="padding: 0;">
                        <label style="color: #646464 !important;">Fees :</label>
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-9 input">
                        <input class="form-input" type="text" [(ngModel)]=this.getFees placeholder="" readonly/>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                <div class="row" style="margin: 0;">
                    <div class="col-lg-5 col-md-5 col-sm-5 col-xs-4" style="padding: 0;">
                        <label style="color: #646464 !important;">Mobile No. :</label>
                    </div>
                    <div class="col-lg-7 col-md-7 col-sm-7 col-xs-8 input">
                        <input class="form-input"  placeholder="" [(ngModel)]=this.getMobile style=" margin-left: 8px;" readonly/>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-5 col-sm-5 col-xs-12">
                <div class="row" style="margin: 0;">
                    <div class="col-lg-3 col-md-4 col-sm-4 col-xs-3" style="padding: 0;">
                        <label style="color: #646464 !important;">EMail :</label>
                    </div>
                    <div class="col-lg-9 col-md-8 col-sm-8 col-xs-9 input">
                        <input class="form-input" type="text" [(ngModel)]=this.getEMail placeholder="" readonly/>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="row" style="margin: 0;">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="row" style="margin: 0;">
                    <div class="col-lg-2 col-md-2 col-sm-4 col-xs-3 course-label" style="padding: 0;">
                        <label><i>Received From :</i></label>
                    </div>
                    <div class="col-lg-10 col-md-10 col-sm-8 col-xs-9 input">
                        <input class="form-input" type="text" [(ngModel)]=application_cum.receivedFrm placeholder="" />
                    </div>
                </div>
            </div>
        </div> -->
        <!-- <br>
        <div class="row" style="margin: 0;">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="row" style="margin: 0;">
                    <div class="col-lg-2 col-md-2 col-sm-4 col-xs-3 course-label" style="padding: 0;">
                        <label><i>Amount In words :</i></label>
                    </div>
                    <div class="col-lg-10 col-md-10 col-sm-8 col-xs-9 input">
                        <input class="form-input" [(ngModel)]=application_cum.ampuntWords type="text" placeholder="" />
                    </div>
                </div>
            </div>
        </div> -->


        <!-- <div class="col-xl-4 col-lg-6 col-12 form-group">
                        <label>Payment Mode :</label>
                        <select class=" form-control" name="Payment" [(ngModel)]=application_cum.PaymentMode #Payment="ngModel" [ngClass]="{'invalid-data': Payment.invalid && userForm.submitted, 'valid-data': Payment.valid && userForm.submitted}" required>
              <option *ngFor="let Payment of Payments"> {{Payment}} </option>
              </select>
                        <div *ngIf="Payment.invalid && userForm.submitted">
                            <div class="alert alert-danger">
                                Please Select Your Course Name.
                            </div>
                        </div>
                    </div> -->

        <br>

        <!-- <div class="row" style="margin: 0;">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="row" style="margin: 0;">
                    <div class="col-lg-2 col-md-2 col-sm-4 col-xs-3 course-label" style="padding: 0;">
                        <label><i>Payment For :</i></label>
                    </div>
                    <div class="col-lg-10 col-md-10 col-sm-8 col-xs-9 input">
                        <input class="form-input" type="text" [(ngModel)]=application_cum.paymentFor placeholder="" />
                    </div>
                </div>
            </div>
        </div> -->

        <br>
         
    </div>
    <div class="row" style="margin: 0;text-align: center;display: block;">
        <!-- <p style="margin-bottom: 0;">मुख्य कार्यालय: १०५ / २२४, फुलबाग, हुसैनगंज, लखनऊ, उ. प्र. फो. नं. ०५२२-४००६२४०</p>
        <p style="margin-bottom: 0;">महाराष्ट्र कार्यालय: तनिष्का हाईट्स अपार्टमेंट, फ्लॅट नं. २०१, रेणुका नगर, वारजे, पुणे - ४११०५८ फो. नं. ८१०४५४४७८८</p> -->
        <h6><b>Email: sivtpune@gmail.com</b></h6>
        <h6><b>Contact No.:  +91 9158035367</b></h6>

        <br>
        <br>
 <!-- <button (click)="printPage()" id="btnprint">print</button> -->
 <!-- <button (click)="pdfpage()" id="btnprint">print</button>  -->
    </div>
    <br>
    <br>


</section>
</div>