import { Component, OnInit } from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonserviceService } from '../commonservice.service';
import { NgForm } from '@angular/forms';
import { GlobalVariable } from 'src/app/Globals';
import { ActivatedRoute } from '@angular/router';
// import { AlertsService } from 'angular-alert-module';
//import { MbscTimerOptions } from '@mobiscroll/angular-lite';

declare let $: any;
declare var angular: any;

export class EnquiryForm {
    Name: string;
    Email: string;
    Course: string;
    Comment: string;
    Mobile: string;
  }

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {

    Name: any;
    Email: any;
    Course: any;
    Comment: any;
    Mobile: any;
    SelectedCourseid: number;
    enquirydetail: any;
    isValidFormSubmitted = false;
    validateEmail = true;
    emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
    MobileNumberPattern = '^((\\+91-?)|0)?[0-9]{10}$'
    numberPattern = '^((\\+91-?)|0)?[0-9]{10}$'
    isValidNoSubmitted = false;
    paymentType : string;
    displayModal : boolean; 

    httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json, text/plain',
        })
      };

  nameFormControl = new FormControl('', [
    Validators.required
  ]);

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  constructor(private service: CommonserviceService,  private http: HttpClient ,private route: ActivatedRoute) {

    this.displayModal = false;

    this.route.queryParams.subscribe(params => {
      this.paymentType= params['payment'];  
      if(params['payment'] != undefined || params['payment'] != null){
        this.displayModal = true;
      }
   });
   }

  ngOnInit() {
    var owl = $('.owl-banner');
    owl.owlCarousel({
        loop: true,
        nav: false,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,

        video: true,
        // margin:10,
        // stagePadding: 50,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            960: {
                items: 1
            },
            1200: {
                items: 1
            }
        }
    });

    var owl1 = $('.owl-courses');
    owl1.owlCarousel({
        loop: true,
        nav: false,
        autoplay: false,
        autoplayTimeout: 5000,
        autoplayHoverPause: false,
        stagePadding: 0,
        margin: 10,

        video: true,
        // margin:10,
        // stagePadding: 50,
        responsive: {
            0: {
                items : 1
            },
            600: {
                items : 1.8
            },
            960: {
                items : 3
            },
            1200: {
                items : 3
            }
        }
    });

    var owl2 = $('.owl-student');
    owl2.owlCarousel({
        loop: true,
        nav: false,
        autoplay: false,
        autoplayTimeout: 5000,
        autoplayHoverPause: false,
        stagePadding: 0,
        margin: 10,

        video: true,
        // margin:10,
        // stagePadding: 50,
        responsive: {
            0: {
                items : 1
            },
            600: {
                items : 1.5
            },
            960: {
                items : 2.6
            },
            1200: {
                items : 2.6
            }
        }
    });

    // this.alerts.setMessage('All the fields are required','error');
    // this.alerts.setMessage('Configurations saved successfully!','success');
    // this.alerts.setMessage('Please save all the changes before closing','warn');



    

  }

  closeModal(){
    this.displayModal = false;
  }

  onSubmit(enquiryform: NgForm ){
    try{
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json, text/plain',
            })
          };

          if(this.Name == null || this.Email == null  || this.Course == null || this.Comment == null || this.Mobile == null)
          {
            alert("All fields are mandatory.");
            return ;
          }
          this.isValidFormSubmitted = false;
          if (enquiryform.invalid) {
             return;
          }
          this.isValidFormSubmitted = true;
         // alert('start');
            this.enquirydetail = enquiryform.value;
            let enquiry = new EnquiryForm();
            enquiry.Name = this.enquirydetail.Name;
           // alert(enquiry.Name);
            enquiry.Email = this.enquirydetail.Email;
           // alert(enquiry.Email);
            enquiry.Course = this.enquirydetail.Course;
           // alert(enquiry.Course);
            enquiry.Comment = this.enquirydetail.Comment;
           // alert(enquiry.Comment);
           enquiry.Mobile = this.enquirydetail.Mobile;
            //alert(enquiry.Mobile);
          //  this.http.post<EnquiryForm>(GlobalVariable.BASE_API_URL + 'SMS/UniversitySendmail', enquiry , httpOptions )
          //   .subscribe( res => {
          //     alert('Successfully Send...');
          //   });
            this.http.post<EnquiryForm>(GlobalVariable.SERVICE_API_URL + 'StudentInfo/SendEnquiryEmail', enquiry , httpOptions )
            .subscribe( res => {
              // this.service.showToast( 'Send Successfully.' );
              alert('Thank you for contacting us! We will be in touch with you shortly.');
            });
    } catch (error) {
        alert('Sorry, something went wrong. Please try again.');
        }
  }
  

}
