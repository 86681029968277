import { Component, OnInit } from '@angular/core';
declare let $: any;
@Component({
  selector: 'app-affiliation-form',
  templateUrl: './affiliation-form.component.html',
  styleUrls: ['./affiliation-form.component.css']
})
export class AffiliationFormComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  
  var owl1 = $('.owl-courses');
  owl1.owlCarousel({
      loop: true,
      nav: false,
      autoplay: false,
      autowidth:true,
      autoplayTimeout: 5000,
      autoplayHoverPause: false,
      stagePadding: 0,
      
      margin: 10,

      video: true,
      // margin:10,
      // stagePadding: 50,
      responsive: {
          0: {
              items : 1
          },
          600: {
              items : 1.8
          },
          960: {
              items : 3
          },
          1200: {
              items : 3
          }
      }
  });
  }
}
