import { BrowserModule } from '@angular/platform-browser'
import { RouterModule, Routes } from '@angular/router'
import { NgModule } from '@angular/core'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { CoursesComponent } from './courses/courses.component'
import { FireAndSafetyInfoComponent } from './fire-and-safety-info/fire-and-safety-info.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MatTableModule } from '@angular/material'
import { CivilInfoComponent } from './civil-info/civil-info.component'
import { HotelManagementInfoComponent } from './hotel-management-info/hotel-management-info.component'
import { NaturopathyInfoComponent } from './naturopathy-info/naturopathy-info.component'
import { AboutUsComponent } from './about-us/about-us.component'
import { MatTabsModule } from '@angular/material/tabs'
import {
  MatIconModule,
  MatFormFieldModule,
  MatInputModule,
} from '@angular/material'
import { ContactUsComponent } from './contact-us/contact-us.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HomeComponent } from './home/home.component'
import { OwlModule } from 'ngx-owl-carousel'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { PlacementComponent } from './placement/placement.component'
import { FacilitiesComponent } from './facilities/facilities.component'
import { DownloadComponent } from './download/download.component'
import { MatMenuModule } from '@angular/material/menu'
import { HttpClientModule } from '@angular/common/http'
import { RegisterComponent } from './register/register.component'
import { AddressComponent } from './address/address.component'
// import { ApplicationCumComponent } from "./application-cum/application-cum.component";
import { ApplicationCumFormComponent } from './application-cum-form/application-cum-form.component'
// import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
// import { DropDownListModule } from "@syncfusion/ej2-ng-dropdowns";
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns'
import { HealthScienceComponent } from './health-science/health-science.component'
import { OnlineExamComponent } from './online-exam/online-exam.component'
import { QuizComponent } from './quiz/quiz.component'
import { CountdownModule } from 'ngx-countdown'
import { CountdownTimerModule } from 'ngx-countdown-timer';
import { ApplyQuizComponent } from './apply-quiz/apply-quiz.component';
import { ExamResultListComponent } from './exam-result-list/exam-result-list.component';
import { ApplicationListComponent } from './application-list/application-list.component'
// import { AlertsModule } from 'angular-alert-module';
import {SoftNotifyModule} from 'ng-softnotify';
import { PaymentReceiptComponent } from './payment-receipt/payment-receipt.component';
import { AffiliationFormComponent } from './affiliation-form/affiliation-form.component'
// import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { MbscModule } from '@mobiscroll/angular-lite';
//import { MbscModule } from '@mobiscroll/angular';
//import { CountdownModule } from 'ngx-countdown';

@NgModule({
  declarations: [
    AppComponent,
    CoursesComponent,
    FireAndSafetyInfoComponent,
    CivilInfoComponent,
    HotelManagementInfoComponent,
    NaturopathyInfoComponent,
    AboutUsComponent,
    ContactUsComponent,
    HomeComponent,
    PlacementComponent,
    FacilitiesComponent,
    DownloadComponent,
    RegisterComponent,
    AddressComponent,
    ApplicationCumFormComponent,
    HealthScienceComponent,
    OnlineExamComponent,
    QuizComponent,
    ApplyQuizComponent,
    ExamResultListComponent,
    ApplicationListComponent,
    PaymentReceiptComponent,
    AffiliationFormComponent,
  ],
  imports: [
    MbscModule,
    BrowserModule,
    AppRoutingModule,
    MatTableModule,
    MatTabsModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    OwlModule,
    NgbModule,
    HttpClientModule,
    MatMenuModule,
    DropDownListModule,
    CountdownModule,
    CountdownTimerModule.forRoot(),
    SoftNotifyModule ,
    // DropDownsModule,
    // AlertsModule.forRoot(),
    RouterModule.forRoot([
      {
        path: 'home',
        component: HomeComponent,
      },
      {
        path: 'courses',
        component: CoursesComponent,
      },
      {
        path: 'fire-and-safety-info',
        component: FireAndSafetyInfoComponent,
      },
      {
        path: 'civil-info',
        component: CivilInfoComponent,
      },
      {
        path: 'hotel-management-info',
        component: HotelManagementInfoComponent,
      },
      {
        path: 'naturopathy-info',
        component: NaturopathyInfoComponent,
      },
      {
        path: 'about-us',
        component: AboutUsComponent,
      },
      {
        path: 'contact-us',
        component: ContactUsComponent,
      },
      {
        path: 'placement',
        component: PlacementComponent,
      },
      {
        path: 'facilities',
        component: FacilitiesComponent,
      },
      {
        path: 'download',
        component: DownloadComponent,
      },
      {
        path: 'register',
        component: RegisterComponent,
      },
      {
        path: 'address',
        component: AddressComponent,
      },
      {
        path: 'application-cum-admission',
        component: ApplicationCumFormComponent,
      },
      {
        path: 'Online-Exam',
        component: OnlineExamComponent,
      },
      {
        path: 'quiz',
        component: QuizComponent,
      },
       {
        path: 'apply-quiz',
        component: ApplyQuizComponent,
      },
             {
        path: 'Exam-Result-List',
        component: ExamResultListComponent,
      },
      {
        path: 'Application-List',
        component: ApplicationListComponent,
      },
      {
        path: 'Application-List',
        component: ApplicationListComponent,
      },
      {
        path: 'Payment-Receipt',
        component: PaymentReceiptComponent,
      },
      {
        path: 'affiliation-form',
        component: AffiliationFormComponent,
      },
    ]),
    BrowserAnimationsModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
