<div class="container">
    <div class="header">
        <div class="row">
            <div class="col-lg-5 col-md-5 col-sm-4"></div>
            <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12" style="text-align: left;">
                <h2 style="color: #336699;">Facilities</h2>
                <hr class="title-hr">
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4"></div>
        </div>
    </div>
    <!-- <div class="panel">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <ul>
                    <li>Best Infrastructure</li>
                    <li>Well Maintained Library</li>
                    <li>Computer Lab with 24/7 Wifi</li>
                    <li>Full Equipped Workshop & Lab</li>
                </ul>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <ul>
                    <li>High Qualified Teachers & Trainers</li>
                    <li>Military Base Training </li>
                    <li>CCTV Surveillance</li>
                    <li>In Campus Hostel with Mess Facility</li>
                </ul>
            </div>
        </div>
    </div>
    <br> -->
    <div class="panel">
        <div class="row text-center">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div class="bg-primaary">
                    <div class="alignment">
                        <i class="fa fa-institution" style="font-size:60px;color:white;vertical-align: middle;"></i><br>
                        <h3>Best Infrastructure</h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div class="bg-default">
                    <div class="alignment">
                        <i class="fa fa-book" style="font-size:60px;color:white;vertical-align: middle;"></i><br>
                        <h3>Well Maintained Library</h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div class="bg-primaary">
                    <div class="alignment">
                        <i class="fa fa-desktop" style="font-size:60px;color:white;vertical-align: middle;"></i><br>
                        <h3>Computer Lab with 24/7 Wifi</h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div class="bg-default">
                    <div class="alignment">
                        <i class="fa fa-gears" style="font-size:60px;color:white;vertical-align: middle;"></i><br>
                        <h3>Full Equipped Workshop & Lab</h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div class="bg-default">
                    <div class="alignment">
                        <i class="fa fa-users" style="font-size:60px;color:white;vertical-align: middle;"></i><br>
                        <h3>High Qualified Teachers & Trainers</h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div class="bg-primaary">
                    <div class="alignment">
                        <i class="fa fa-fighter-jet" style="font-size:60px;color:white;vertical-align: middle;"></i><br>
                        <h3>Military Base Training</h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div class="bg-default">
                    <div class="alignment">
                        <i class="fa fa-eye" style="font-size:60px;color:white;vertical-align: middle;"></i><br>
                        <h3>CCTV Surveillance</h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div class="bg-primaary">
                    <div class="alignment">
                        <i class="fa fa-building-o" style="font-size:60px;color:white;vertical-align: middle;"></i><br>
                        <h3>In Campus Hostel with Mess Facility</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>