export class StudentForm {
  FName: string
  MName: string
  LName: string
  motherName: string
  marital: string
  nationality: string
  state: string
  tongue: string
  Gender: string
  Income: string
  Dob: number
  Roll: number
  Email: string
  MobileNumber: number
  fMobileNumber: number
  mMobileNumber: number
  Bloodgrp: string
  religion: string
  Category: string
  Cast: string
  Class: string
  Section: string
  AdmissionID: number
  Bio: string
}

export class Affiliation {
  OrgName: string
  year: string
  OrgType: string
  Address: string
  District: string
  City: string
  state: string
  Country: string
  pincode: number
  Email: string
  MobileNumber: number
  Telephone: number
  Fax: number
  premise: string
  operation: string
  CarpetArea: string
  SiteArea: string
  internet: string
  faculty: any
}
export class Faculty {}

export class Application_cum {
  Name: string
  Fees: string
  FatherName: string
  MotherName: string
  CourseName: string
  Aadhar: string
  Address: string
  City: string
  DOB: string
  District: string
  State: string
  PinCode: number
  Email: string
  STDCode: string
  Mobile: number
  Telephone: number
  Category: string
  Sex: string
  Nationality: string
  EducationDetails: Education[]
  AadharFile: string
  Class: string
  receiptNo: string
  Date: string
  ampuntWords: string
  mode: string
  checkNo: string
  moneyOrder: string
  receivedBy: string



}

export class Education {
  Examination: string
  Board: string
  Year: string
  Marks: string
  Subject: string
}

export class StudentPromo {
  currentSession: string
  country: string
  promoSession: string
  promotionClass: string
  promoToClass: string
  year: string
  createdBy: string
  updatedBy: string
}

export class AddTeacher {
  FName: string
  MName: string
  LName: string
  motherName: string
  Gender: string
  Dob: number
  Roll: number
  Email: string
  MobileNumber: number
  Bloodgrp: string
  religion: string
  Category: string
  Cast: string
  Class: string
  Address: string
  Section: string
  id: string
  Bio: string
}

export class AddParent {
  FName: string
  MName: string
  LName: string
  Gender: string
  Occupation: string
  Email: string
  MobileNumber: number
  Bloodgrp: string
  religion: string
  Address: string
  id: string
  Bio: string
}

export class AddBook {
  BName: string
  Subject: string
  WName: string
  Class: string
  id: string
  PDate: string
  UDate: string
}

export class AddExpense {
  Name: string
  id: string
  ExType: string
  Amount: string
  Email: string
  MobileNumber: number
  Status: string
  Date: string
  UDate: string
  createdBy: string
  updatedBy: string
  ApprovedBy: string
}
export class AddClasses {
  Name: string
  id: string
  Gender: string
  Class: string
  Subject: string
  Section: string
  Time: string
  Email: string
  MobileNumber: number
}
export class AllSubject {
  id: string
  Courseid: string
  Sectionid: string
  Title: string
  createdBy: string
  SubType: string
  Marks: string
}

export class ClassRoutine {
  Name: string
  SubType: string
  Class: string
  Code: string
}

export class Attendence {
  Class: string
  Section: string
  Month: string
  Session: string
}
export class ExamSchedule {
  EName: string
  SubType: string
  Class: string
  Section: string
}
export class ExamGrades {
  GName: string
  GradePiont: string
  PercantageFrom: string
  PercantageTo: string
  Comments: string
}
export class Transport {
  RouteName: string
  VehicleNo: string
  DriverName: string
  LicenseNo: string
  MobileNumber: number
}
export class Hostel {
  HostelName: string
  RoomNumber: string
  RoomType: string
  Bed: string
  Cost: number
}
export class OnlineExam {
  FName: string
  MName: string
  LName: string
  FatherName: string
  Aadhar: string
  Address: string
  City: string
  District: string
  State: string
  PinCode: number
  Email: string
  Mobile: number
  Sex: string
}

export class ExamResult {
  UserId: string
  correct: string
  Adhaar: string
  FirstName: string 
  MobileNumber: string
}

export class List {
  FName: string
  LName: string
  Email: string
  Mobile: string 
  Marks: string 
}


export class Receipt {
        name: string
        Email: string
        Mobile: string
        Course: string
        PaidFees: string
        TotalFees: string
}