import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-civil-info',
  templateUrl: './civil-info.component.html',
  styleUrls: ['./civil-info.component.css']
})
export class CivilInfoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  public isCollapsed1 = false;

}
