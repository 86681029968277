export const GlobalVariable = Object({
  // BASE_API_URL: 'http://localhost:58429/api/',
  // SERVICE_API_URL: "http://localhost:58429/api/",
  SERVICE_API_URL: "https://service.sivtpune.com/api/",

  //SERVICE_API_URL: "https://test.kvboard.ac.in/api/",

  UserName: "orgasatva@gmail.com",
  Mobile: "",
  Address: "", 
  CustomerId: 0,
  UserExamId: 0,
  FirstName: "",
  Adhaar: "",
  MobileNumber: "",
});
