<!-- <section class="top-section">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <h4>Course List Page</h4>
            </div>
        </div>
    </div>
</section> -->
<section class="middle-section">
    <div class="container">
        <div class="row row-bottom">
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                <div class="course-list">
                    <img src="./assets/images/fireAndsafety.jpg" width="294" height="206" style="width:100%">
                </div>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                <div class="course-description">
                    <h5>Department of Fire and Safety</h5>
                    <hr class="title-hr">
                    <p>Department of Fire & Safety Management is offering an outstanding military discipline in its Fire & Safety courses. With rich experience of making placements in the field of Fire & Safety, the Institute is providing courses that are
                        purposeful, lucrative and futuristic for aiding our students & clients to secure great future for them.</p>
                </div>
                <div>
                    <button class="btn btn-default" routerLink="/fire-and-safety-info">Learn More</button>
                </div>
            </div>
        </div>

        <div class="row row-bottom">
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                <div class="course-list">
                    <img src="./assets/images/civil.jpg" width="294" height="206" style="width:100%">
                </div>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                <div class="course-description">
                    <h5>Department of Civil</h5>
                    <hr class="title-hr">
                    <p>Department of Civil is the offering outstanding skill development in planning, organizing and controlling projects. With our rich experience of making placements in the field of Construction, the institute is providing courses that
                        are purposeful, lucrative and futuristic for aiding our students & clients to secure great future for them.</p>
                </div>
                <div>
                    <a class="btn btn-default" routerLink="/civil-info">Learn More</a>
                </div>
            </div>
        </div>

        <div class="row row-bottom">
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                <div class="course-list">
                    <img src="./assets/images/hotel.jpg" width="294" height="206" style="width:100%">
                </div>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                <div class="course-description">
                    <h5>Department of Hotel Management and Catering Technology</h5>
                    <hr class="title-hr">
                    <p>Department of Hotel Management and Catering Technology was established under the banner of the Sahyadri Institute of Vocational Training. The dept. offers a one-year Diploma and three year Bachelor programme (B.VOC) in Hotel management
                        & Catering Technology approved by the KV Board and UGC recognized University.</p>
                </div>
                <div>
                    <a class="btn btn-default" routerLink="/hotel-management-info">Learn More</a>
                </div>
            </div>
        </div>

        <div class="row row-bottom">
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                <div class="course-list">
                    <img src="./assets/images/yoga.jpg" width="294" height="206" style="width:100%">
                </div>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                <div class="course-description">
                    <h5>Department of Naturopathy and Yogic Science</h5>
                    <hr class="title-hr">
                    <p>Presently, all modern health care facilities have recognized the importance of Yoga and Naturopathy. The Department of naturopathy and yogic science start with the mission to propagate a healthy and active lifestyle of human personality.
                        The department has been developed in accordance with the kv board. The department involves modern infrastructure, comprising of a well-equipped naturopathy health centre with the whole range of Ayurveda, Panchakarma.</p>
                </div>
                <div>
                    <a class="btn btn-default" routerLink="/naturopathy-info">Learn More</a>
                </div>
            </div>
        </div>
        <div class="row row-bottom">
          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
              <div class="course-list">
                  <img src="./assets/images/Allied-Science4.jpg" width="294" height="206" style="width:100%">
              </div>
          </div>
          <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
            <div class="course-description">
                <h5>Department of Health & Allied Science</h5>
                <hr class="title-hr">
                <p>Healthcare industry is the largest growing industry. There is a large gap between need of competent and supply of good quality healthcare professionals. Necessiates the initialization of more and more educational institutions to meet local
                  national and global demand for health care providers. Considering this Sahyadri Institute of Vocational Training (SIVT) has established department of Health and Allied Sciences with the objective of productive skilled manpower in different areas of allied health sciences for better
                  healthcare delivery in the country.
                </p>
            </div>
            <div>
                <a class="btn btn-default" routerLink="/health-science">Learn More</a>
            </div>
          </div>
      </div>
    </div>
</section>


<router-outlet></router-outlet>
