import { Component, OnInit } from '@angular/core';


export interface Element {
  name: string;
  no: number;
}

const ELEMENT_DATA: Element[] = [
  {no : 1, name : 'Private Sectors'},
  {no : 2, name : 'Coal Fields & Mines'},
  {no : 3, name : 'Manufacturing Industries'},
  {no : 4, name : 'Construction Projects'},
  {no : 5, name : 'Refineries'},
  {no : 6, name : 'Dockyards'},
  {no : 7, name : 'Nuclear Plants'},
  {no : 8, name : 'Power Plants & Power Industries'},
];

const ELEMENT_DATA1: Element[] = [
  {no : 9, name : 'Chemical Industries'},
  {no : 10, name : 'General Industries'},
  {no : 11, name : 'Municipal Corporation'},
  {no : 12, name : 'Research Labs & Centers'},
  {no : 13, name : 'Automobile Industries'},
  {no : 14, name : 'Hotel Industries'},
  {no : 15, name : 'Hospitals'},
  {no : 16, name : 'National & International Airports'},
];

@Component({
  selector: 'app-fire-and-safety-info',
  templateUrl: './fire-and-safety-info.component.html',
  styleUrls: ['./fire-and-safety-info.component.css']
})

export class FireAndSafetyInfoComponent {

  displayedColumns: string[] = ['no', 'name'];
  dataSource = ELEMENT_DATA;

  displayedColumns1: string[] = ['no', 'name'];
  dataSource1 = ELEMENT_DATA1;

  public isCollapsed = false;

  public isCollapsed1 = false;

  public isCollapsed2 = false;
}
