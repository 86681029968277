import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'SIVT';

  navbarOpen = false;

  onActivate(event) {
    window.scroll(0 , 0);
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  scroll() {
    document.querySelector('#footer').scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  ngOnInit() {
 }

 constructor(private router: Router) {
  // override the route reuse strategy
  this.router.routeReuseStrategy.shouldReuseRoute = function() {
     return false;
  };

  this.router.events.subscribe((evt) => {
     if (evt instanceof NavigationEnd) {
        // trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
        // if you need to scroll back to top, here is the right place
        window.scrollTo(0, 0);
     }
 });

}
}

